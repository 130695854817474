import React from "react";
import PropTypes from "prop-types";
import SVGIcon from "cms/back-office/icons/SVGIcon";

const TimelineIcon = props => {
  const { backgroundColor } = props;

  return (
    <svg viewBox="0 0 51 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6.99609" cy="6" r="6" fill={backgroundColor} />
      <rect x="4.99609" y="10" width="4" height="14" fill={backgroundColor} />
      <rect x="20.0273" y="14" width="29.961" height="2" fill={backgroundColor} />
      <rect x="20.043" y="8" width="29.961" height="2" fill={backgroundColor} />
    </svg>
  );
};

TimelineIcon.propTypes = {
  backgroundColor: PropTypes.string.isRequired
};

export default SVGIcon(TimelineIcon);
