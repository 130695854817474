import contentsTypes from "cms/enums/contentsTypes";
import JobOfferTemplate from "templates/jobOffer/JobOfferTemplate";
import customTypes from "customComponents/customTypes";
import { Templates } from "types/templates.enum";
export default {
    key: Templates.JOB_OFFER,
    label: "Offre emploi",
    templateImage: "/newspaper-solid.svg",
    Component: JobOfferTemplate,
    useTags: true,
    initialContents: [
        {
            key: "dynamicPart",
            type: contentsTypes.DYNAMIC,
            value: ""
        },
        {
            key: "mailto",
            type: customTypes.MAILTO,
            value: "",
            children: [
                {
                    label: "Texte",
                    key: "text",
                    type: contentsTypes.INPUT,
                    value: "Envoyez-nous votre candidature"
                },
                {
                    label: "Email de destination",
                    key: "recipient",
                    type: contentsTypes.INPUT,
                    value: ""
                },
                {
                    label: "Objet",
                    key: "subject",
                    type: contentsTypes.INPUT,
                    value: ""
                },
                {
                    label: "Corps",
                    key: "body",
                    type: contentsTypes.INPUT,
                    value: ""
                }
            ]
        }
    ]
};
