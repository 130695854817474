var _a, _b, _c, _d, _e;
import { createTheme } from "@mui/material/styles";
import { overrideKey } from "cms/utils/makeOverridableStyles";
import { alpha } from "@mui/material";
export var colors = {
    bleu: "#26348B",
    bleu_fonce: "#0A176A",
    bleu_clair: "#5663B4",
    bleu_4: "#EBEEFF",
    bleu_5: "#F4F5FF",
    cyan: "#23CFCF",
    cyan_fonce: "#0C9F9F",
    cyan_clair: "#89F9F9",
    cyan_4: "#EDFFFF",
    recherche: "#594AD0",
    recherche_fonce: "#3D31A2",
    recherche_clair: "#C2BAEE",
    recherche_clair_2: "#EBE9F8",
    rouge_fonce: "#902822",
    rouge_clair: "#D42D2F",
    vert_fonce: "#38754B",
    vert_clair: "#4CA630",
    noir: "#000000",
    gris_1: "#333333",
    gris_2: "#555555",
    gris_3: "#808080",
    gris_4: "#CCCCCC",
    gris_5: "#EEEEEE",
    gris_bg: "#F7F7F7",
    blanc: "#FFFFFF",
    newsLetter: "#36B8C1"
};
var palette = {
    primary: {
        light: colors.bleu_clair,
        main: colors.bleu,
        dark: colors.bleu_fonce
    },
    secondary: {
        light: colors.cyan_clair,
        main: colors.cyan,
        dark: colors.cyan_fonce
    },
    warning: {
        light: colors.rouge_clair,
        main: colors.rouge_fonce,
        dark: colors.rouge_fonce
    },
    error: {
        light: colors.rouge_clair,
        main: colors.rouge_fonce,
        dark: colors.rouge_fonce
    },
    success: {
        light: colors.vert_clair,
        main: colors.vert_fonce,
        dark: colors.vert_fonce
    },
    colors: colors
};
var typography = {
    fontSize: 14,
    fontFamily: "Montserrat",
    h1: {
        color: colors.bleu,
        fontFamily: "Forum",
        fontSize: "3.53rem",
        fontWeight: 400,
        lineHeight: "3.897rem"
    },
    h2: {
        color: colors.bleu,
        fontFamily: "Forum",
        fontSize: "3rem",
        fontWeight: 400,
        lineHeight: "3.312rem"
    },
    h3: {
        color: colors.bleu,
        fontSize: "1.928rem",
        fontWeight: 600,
        lineHeight: "2.35rem",
        fontVariant: "small-caps"
    },
    h4: {
        color: colors.bleu,
        fontFamily: "Forum",
        fontSize: "2.571rem",
        fontWeight: 400,
        lineHeight: "2.838rem"
    },
    h5: {
        color: colors.bleu,
        fontSize: "1.134rem",
        fontWeight: 600,
        lineHeight: "1.382rem"
    },
    h6: {
        color: colors.bleu,
        fontSize: "0.964rem",
        fontWeight: 600,
        lineHeight: "1.175rem"
    },
    menu: {
        fontSize: "1.285rem",
        fontWeight: 600,
        lineHeight: "1.567rem"
    },
    slogan: {
        color: colors.bleu,
        fontFamily: "Forum",
        fontSize: "1.285rem",
        fontWeight: 400,
        lineHeight: "1.357rem"
    },
    telephone: {
        fontFamily: "Forum",
        fontSize: "2.228rem",
        fontWeight: 400,
        lineHeight: "2.429rem"
    },
    onglet: {
        fontFamily: "Forum",
        fontSize: "1.714rem",
        fontWeight: 400,
        lineHeight: "1.893rem"
    },
    subtitle1: {
        fontSize: "1.214rem",
        fontWeight: 600,
        lineHeight: "1.48rem"
    },
    body1: {
        fontSize: "1.142rem",
        fontWeight: 400,
        lineHeight: "1.571rem"
    },
    label: {
        fontSize: "1rem",
        fontWeight: 700,
        lineHeight: "1.219rem"
    },
    label_mini: {
        fontSize: "0.857rem",
        fontWeight: 400,
        lineHeight: "1.045rem"
    },
    donnee: {
        fontSize: "1.142rem",
        fontWeight: 600,
        lineHeight: "1.392rem"
    },
    etiquette: {
        fontSize: "1.143rem",
        fontWeight: 400,
        lineHeight: "1.429rem",
        color: colors.recherche_fonce
    },
    erreur: {
        fontSize: "0.857rem",
        fontWeight: 500,
        lineHeight: "1.045rem"
    },
    legend: {
        fontSize: "0.857rem",
        fontWeight: 400,
        lineHeight: "1.045rem",
        fontStyle: "italic"
    },
    // CTA Active, applied to MuiButton
    button: {
        fontSize: "1.142rem",
        fontWeight: 700,
        lineHeight: "1.392rem",
        textTransform: "none"
    }
};
var defaultTheme = createTheme();
export default createTheme((_a = {
        palette: palette,
        typography: typography
    },
    _a[overrideKey] = {
        Text: {
            text: {
                color: "inherit",
                "& h2": typography.h2,
                "& h3": typography.h3,
                "& h4": typography.h4,
                "& h5": typography.h5
                // "& a": theme.components?.MuiLink
            }
        }
    },
    _a.components = {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.bleu_4,
                    border: "1px solid",
                    borderColor: colors.bleu,
                    borderRadius: "5px",
                    boxShadow: "none",
                    padding: "15px 0 15px 7px",
                    "&.Mui-expanded": {
                        backgroundColor: colors.bleu_clair,
                        margin: 0
                    },
                    "&.Mui-disabled": {
                        backgroundColor: colors.bleu_clair
                    }
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    color: colors.bleu,
                    "&.Mui-expanded": {
                        color: colors.blanc
                    },
                    "&.Mui-disabled": {
                        opacity: 1
                    }
                },
                expandIconWrapper: {
                    fontSize: "2rem",
                    color: colors.bleu_4,
                    backgroundColor: colors.bleu,
                    border: "1px solid",
                    borderColor: colors.bleu,
                    borderRadius: 20,
                    "&.Mui-expanded": {
                        color: colors.bleu_clair,
                        backgroundColor: colors.blanc,
                        borderColor: colors.blanc,
                        margin: 0
                    }
                }
            }
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    color: colors.blanc
                }
            }
        },
        MuiButton: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    border: "1px solid",
                    borderRadius: 100,
                    boxShadow: "none",
                    padding: "7px 15px",
                    height: "36px",
                    "&:hover:not(.Mui-disabled), &:focus:not(.Mui-disabled)": {
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
                    },
                    textAlign: "center"
                },
                sizeSmall: {
                    fontSize: "1.142rem",
                    padding: "3px 11px",
                    height: "28px"
                },
                sizeLarge: {
                    fontSize: "1.142rem",
                    padding: "15px 31px",
                    height: "52px"
                },
                containedPrimary: {
                    color: colors.blanc,
                    borderColor: colors.bleu,
                    backgroundColor: colors.bleu,
                    "&:hover": {
                        borderColor: alpha(colors.bleu, 0.8),
                        backgroundColor: alpha(colors.bleu, 0.8)
                    },
                    "&:focus": {
                        borderColor: alpha(colors.bleu, 0.9),
                        backgroundColor: alpha(colors.bleu, 0.9)
                    },
                    "&.Mui-disabled": {
                        borderColor: alpha(colors.bleu, 0.4),
                        backgroundColor: alpha(colors.bleu, 0.4)
                    }
                },
                outlinedPrimary: {
                    border: "1px solid",
                    borderColor: colors.bleu,
                    color: colors.bleu,
                    backgroundColor: colors.blanc,
                    "&:hover": {
                        borderColor: alpha(colors.bleu, 0.8),
                        color: alpha(colors.bleu, 0.8)
                    },
                    "&:focus": {
                        borderWidth: "2px",
                        borderColor: colors.bleu,
                        color: colors.gris_1,
                        backgroundColor: colors.bleu_4,
                        paddingLeft: "6px",
                        paddingRight: "6px",
                        "&.MuiButton-sizeSmall": {
                            paddingLeft: "10px",
                            paddingRight: "10px"
                        },
                        "&.MuiButton-sizeLarge": {
                            paddingLeft: "30px",
                            paddingRight: "30px"
                        }
                    },
                    "&.Mui-disabled": {
                        borderColor: alpha(colors.bleu, 0.4),
                        color: alpha(colors.bleu, 0.4)
                    }
                },
                containedSecondary: {
                    color: colors.noir,
                    backgroundColor: colors.cyan,
                    border: "1px solid",
                    borderColor: colors.cyan,
                    "&:hover": {
                        backgroundColor: alpha(colors.cyan, 0.8)
                    },
                    "&:focus": {
                        backgroundColor: alpha(colors.cyan, 0.9),
                        borderColor: alpha(colors.cyan, 0.9)
                    },
                    "&.Mui-disabled": {
                        color: alpha(colors.noir, 0.4),
                        backgroundColor: alpha(colors.cyan, 0.4),
                        borderColor: alpha(colors.cyan, 0.4)
                    }
                },
                outlinedSecondary: {
                    border: "2px solid",
                    borderColor: colors.cyan,
                    backgroundColor: colors.blanc,
                    color: colors.noir,
                    "&:hover": {
                        backgroundColor: colors.cyan_4,
                        borderWidth: "2px"
                    },
                    "&:focus": {
                        backgroundColor: colors.blanc,
                        borderWidth: "2px"
                    },
                    "&.Mui-disabled": {
                        color: alpha(colors.noir, 0.4),
                        borderColor: alpha(colors.cyan, 0.4)
                    }
                }
            },
            variants: [
                {
                    props: { variant: "cta" },
                    style: {
                        color: colors.blanc,
                        backgroundColor: colors.recherche,
                        borderColor: colors.recherche,
                        height: 44,
                        "&:hover,&:focus": {
                            color: colors.blanc,
                            backgroundColor: colors.recherche_fonce,
                            borderColor: colors.recherche_fonce
                        },
                        "&.Mui-disabled": {
                            color: colors.blanc,
                            backgroundColor: colors.recherche_clair,
                            borderColor: colors.recherche_clair_2
                        },
                        "& i": {
                            fontSize: "1.143rem",
                            "&:nth-of-type(1)": {
                                fontSize: "1.143rem"
                            }
                        }
                    }
                },
                {
                    props: { variant: "cta", size: "large" },
                    style: {
                        height: 60
                    }
                },
                {
                    props: { variant: "label" },
                    style: (_b = {
                            width: "auto",
                            color: colors.noir,
                            backgroundColor: colors.blanc,
                            borderColor: colors.recherche_clair,
                            fontWeight: 500
                        },
                        _b[defaultTheme.breakpoints.down("md")] = {
                            width: "100%"
                        },
                        _b["&:hover:not(.Mui-disabled)"] = {
                            borderColor: colors.recherche_fonce,
                            boxShadow: "none"
                        },
                        _b["&:focus:not(.Mui-disabled)"] = {
                            backgroundColor: colors.recherche_clair,
                            borderColor: colors.recherche_fonce,
                            boxShadow: "none",
                            fontWeight: 700
                        },
                        _b["&.Mui-disabled"] = {
                            fontWeight: 500,
                            color: colors.gris_2,
                            borderColor: colors.recherche_clair
                        },
                        _b["& > span"] = {
                            color: colors.blanc,
                            backgroundColor: colors.recherche_fonce,
                            borderRadius: "50%",
                            paddingTop: "1px",
                            paddingLeft: "6px",
                            width: "20px",
                            fontWeight: 500
                        },
                        _b)
                }
            ]
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                separator: {
                    margin: "0 10px"
                }
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: (_c = {
                        padding: 0
                    },
                    _c[defaultTheme.breakpoints.up("sm")] = {
                        padding: 0
                    },
                    _c),
                maxWidthLg: (_d = {},
                    _d[defaultTheme.breakpoints.up("lg")] = {
                        maxWidth: "1920px"
                    },
                    _d)
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: colors.noir,
                    "&.Mui-checked": {
                        color: colors.noir
                    },
                    "&.Mui-error": {
                        color: colors.rouge_clair
                    }
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.cyan_fonce
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: colors.bleu,
                    "&.Mui-error": {
                        textAlign: "left",
                        color: colors.rouge_clair
                    }
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-focused": {
                        color: colors.noir
                    },
                    "&.Mui-error": {
                        color: colors.rouge_clair
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: colors.noir
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: colors.bleu_clair,
                    fontSize: "1.142rem",
                    fontWeight: 600,
                    lineHeight: "1.392rem"
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow: "0px 2px 4px 2px rgba(0, 0, 0, 0.15)"
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: colors.noir,
                    "&.Mui-checked": {
                        color: colors.cyan
                    }
                }
            }
        },
        MuiRating: {
            styleOverrides: {
                root: {
                    color: colors.cyan_fonce
                },
                icon: {
                    color: colors.cyan_fonce
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: "1rem"
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    color: colors.cyan_fonce
                },
                mark: {
                    display: "none"
                },
                markLabel: {
                    fontSize: "1rem",
                    color: colors.noir
                },
                valueLabel: {
                    fontSize: "1rem",
                    color: colors.noir,
                    backgroundColor: "unset"
                },
                thumb: {
                    color: colors.cyan,
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    '&[data-index="0"] .MuiSlider-valueLabel': {
                        right: "2px"
                    },
                    '&[data-index="1"] .MuiSlider-valueLabel': {
                        left: "2px"
                    }
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    "&.Mui-checked": {
                        "& .MuiSwitch-thumb": {
                            color: colors.cyan
                        },
                        "& + .MuiSwitch-track": {
                            backgroundColor: colors.cyan_fonce
                        }
                    },
                    "& + .MuiSwitch-track": {
                        backgroundColor: colors.cyan_4,
                        opacity: 1
                    },
                    "& .MuiSwitch-thumb": {
                        color: colors.cyan
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.bleu_4,
                    border: "1px solid",
                    borderColor: colors.bleu,
                    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                    borderRadius: "5px",
                    padding: "8px 16px",
                    color: colors.bleu,
                    margin: "4px 8px",
                    "&.Mui-selected": {
                        backgroundColor: colors.bleu,
                        color: colors.blanc
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    display: "none"
                }
            }
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: "h1",
                    h2: "h2",
                    h3: "h3",
                    h4: "h4",
                    h5: "h5",
                    h6: "h6",
                    subtitle1: "span",
                    subtitle2: "h6",
                    body1: "p",
                    body2: "p",
                    inherit: "p",
                    label: "label",
                    label_mini: "label"
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                html: (_e = {
                        fontSize: "14px"
                    },
                    _e[defaultTheme.breakpoints.down("md")] = {
                        fontSize: "12px"
                    },
                    _e),
                body: {
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100vh",
                    margin: 0
                },
                "#root": {
                    display: "flex",
                    flex: "1 1 auto",
                    flexDirection: "column"
                },
                pre: { margin: 0 },
                "*": { boxSizing: "border-box" },
                // Google map
                ".gm-style": {
                    ".gm-style-iw-c": {
                        padding: 0
                    },
                    ".gm-style-iw-d": {
                        overflow: "auto !important"
                    }
                }
            }
        }
    },
    _a));
