var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { SvgIcon } from "@mui/material";
var ExitIcon = function (props) {
    return (_jsx(SvgIcon, __assign({ viewBox: "-1.5 -1.5 20 20" }, props, { children: _jsx("path", { d: "M15 12.75V10.25H8.75V7.75H15V5.25L18.75 9L15 12.75ZM13.75 11.5V16.5H7.5V20.25L0 16.5V0.25H13.75V6.5H12.5V1.5H2.5L7.5 4V15.25H12.5V11.5H13.75Z", fill: "currentColor" }, void 0) }), void 0));
};
export default ExitIcon;
