var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Grid, Link, Paper, Typography } from "@mui/material";
import Label from "components/Label";
import InfosBlock from "routes/Search/InfosBlock";
var SearchStep = function (props) {
    var title = props.title, subtitle = props.subtitle, button = props.button, accordions = props.accordions, handleValidateStep = props.handleValidateStep, exitLink = props.exitLink, children = props.children;
    return (_jsx(Grid, __assign({ item: true, xs: 12, lg: 8 }, { children: _jsx(Paper, __assign({ sx: { p: "26px", height: "100%" } }, { children: _jsxs(Grid, __assign({ container: true, direction: "column", wrap: "nowrap", sx: { height: "100%" } }, { children: [_jsxs(Grid, __assign({ item: true, sx: { flex: 1 } }, { children: [_jsx(Typography, __assign({ variant: "h2" }, { children: title }), void 0), _jsx(Typography, __assign({ variant: "h3", sx: { mt: "5px", mb: "43px", color: "colors.noir" } }, { children: subtitle }), void 0), _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [children, _jsxs(Grid, __assign({ item: true, xs: 12, md: 6, container: true, direction: "column" }, { children: [_jsx(Box, __assign({ sx: { mt: { xs: "40px", md: 0 } } }, { children: _jsx(Label, { children: "On vous aide" }, void 0) }), void 0), _jsx(InfosBlock, { accordions: accordions }, void 0)] }), void 0)] }), void 0)] }), void 0), button && (_jsx(Grid, __assign({ item: true, container: true, alignItems: "center", justifyContent: "flex-end", spacing: 2, sx: { mt: "45px" } }, { children: _jsx(Grid, __assign({ item: true, xs: 12, md: "auto", onClick: exitLink ? function () { return null; } : handleValidateStep }, { children: _jsx(Button, __assign({ variant: "contained", color: "secondary", title: exitLink
                                    ? "En cliquant sur ce bouton, vous serez redirigé vers la pages des prestations de services et accompagnement à domicile d'ARPAVIE : ARPAVIE@Dom"
                                    : undefined, sx: { width: { xs: "100%", md: "inherit" } } }, { children: exitLink ? (_jsx(Link, __assign({ href: exitLink, underline: "none", sx: { color: "inherit" } }, { children: button }), void 0)) : (button) }), void 0) }), void 0) }), void 0))] }), void 0) }), void 0) }), void 0));
};
export default SearchStep;
