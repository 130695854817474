import uuidv4 from "uuid/v4";
import types from "cms/enums/contentsTypes";
import categories from "cms/enums/categoriesContents";
import severitiesTypes from "cms/enums/severitiesTypes";
import videosOrigins from "cms/enums/videosOrigins";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import AccordionIcon from "cms/back-office/icons/AccordionIcon";
import ContainerIcon from "cms/back-office/icons/ContainerIcon";
import ExpansionPanelIcon from "cms/back-office/icons/ExpansionPanelIcon";
import InfoBlockIcon from "cms/back-office/icons/InfoBlockIcon";
import TimelineIcon from "cms/back-office/icons/TimelineIcon";
import ButtonPreview from "cms/back-office/components/contentsPreview/ButtonPreview";
import ExpansionPanelPreview from "cms/back-office/components/contentsPreview/ExpansionPanelPreview";
import AccordionPreview from "cms/back-office/components/contentsPreview/AccordionPreview";
import ExpansionTextPreview from "cms/back-office/components/contentsPreview/ExpansionTextPreview";
import AttachmentPreview from "cms/back-office/components/contentsPreview/AttachmentPreview";
import GridPreview from "cms/back-office/components/contentsPreview/GridPreview";
import ContainerPreview from "cms/back-office/components/contentsPreview/ContainerPreview";
import InfoBlockPreview from "cms/back-office/components/contentsPreview/InfoBlockPreview";
import TimelinePreview from "cms/back-office/components/contentsPreview/TimelinePreview";
import { urlRegex } from "cms/utils/commonUtils";

const contents = [
  {
    label: "Zone de texte",
    type: types.TEXT,
    editionModalPosition: "top",
    icon: "text",
    value: ""
  },
  {
    label: "Bouton",
    type: types.BUTTON,
    icon: "hand-pointer",
    Preview: ButtonPreview,
    editionModal: true,
    editionModalPosition: "top",
    children: [
      {
        key: "text",
        type: types.INPUT,
        value: "Texte"
      },
      {
        label: "Couleur",
        key: "color",
        type: types.RADIO_GROUP,
        width: "50%",
        options: [
          { label: "Primaire", value: "primary" },
          { label: "Secondaire", value: "secondary" }
        ],
        value: "primary"
      },
      {
        label: "Style",
        key: "variant",
        type: types.RADIO_GROUP,
        width: "50%",
        options: [
          { label: "Principal", value: "contained", title: "Boutons d'actions principales (contained)" },
          { label: "Secondaire", value: "outlined", title: "Boutons d'actions secondaires (outlined)" }
        ],
        value: "contained"
      },
      {
        label: "Taille du bouton",
        key: "size",
        type: types.RADIO_GROUP,
        width: "50%",
        options: [
          { label: "Petit", value: "small" },
          { label: "Moyen", value: "medium" },
          { label: "Grand", value: "large" }
        ],
        value: "medium"
      },
      {
        label: "Prendre toute la largeur",
        key: "fullWidth",
        type: types.CHECKBOX,
        width: "50%",
        value: "false"
      },
      {
        key: "justifyContent",
        label: "Alignement",
        type: types.RADIO_GROUP,
        options: [
          { label: "À gauche", value: "flex-start" },
          { label: "Centré", value: "center" },
          { label: "À droite", value: "flex-end" }
        ],
        value: "flex-start"
      },
      {
        label: "Lien du bouton",
        key: "link",
        type: types.LINK,
        value: ""
      },
      {
        label: "Icone",
        key: "icon",
        type: types.ICON,
        value: ""
      }
    ]
  },
  {
    label: "Image",
    type: types.IMAGE_BLOCK,
    icon: "image",
    editionModal: true,
    editionModalPosition: "top",
    value: "",
    children: [
      {
        key: "image",
        type: types.IMAGE,
        requiredByKey: ["file", "title", "alt"]
      },
      {
        label: "Largeur de l'image (en %)",
        key: "width",
        type: types.SLIDER
      },
      {
        label: "Largeur de l'image (en %) sur mobile",
        key: "mobileWidth",
        type: types.SLIDER
      },
      {
        key: "justifyContent",
        label: "Alignement",
        type: types.SELECT,
        options: [
          { label: "À gauche", value: "flex-start" },
          { label: "Centré", value: "center" },
          { label: "À droite", value: "flex-end" }
        ],
        value: "flex-start"
      },
      {
        label: "Lien de l'image",
        key: "link",
        type: types.LINK
      }
    ]
  },
  {
    label: "Galerie d'images",
    type: types.IMAGES_GALLERY,
    icon: "images",
    editionModal: true,
    editionModalPosition: "top",
    children: [
      {
        key: "images",
        type: types.IMAGES,
        requiredByKey: ["file", "title", "alt"],
        children: [
          {
            key: "image",
            type: types.IMAGE_AS_FILE
          }
        ]
      },
      {
        label: "Hauteur des images",
        key: "height",
        type: types.INPUT_NUMBER,
        value: "200"
      },
      {
        label: "Boucle infinie",
        key: "infinite",
        type: types.CHECKBOX,
        value: "true"
      },
      {
        label: "Diaporama automatique",
        key: "autoPlay",
        type: types.CHECKBOX,
        value: "true"
      },
      {
        label: "Intervalle entre les images (en millisecondes)",
        key: "slideInterval",
        type: types.INPUT_NUMBER,
        value: "3000"
      },
      {
        label: "Afficher les vignettes",
        key: "showThumbnails",
        type: types.CHECKBOX,
        value: "true"
      },
      {
        label: "Position des vignettes",
        key: "thumbnailPosition",
        type: types.SELECT,
        options: [
          { label: "En haut", value: "top" },
          { label: "À droite", value: "right" },
          { label: "En bas", value: "bottom" },
          { label: "À gauche", value: "left" }
        ],
        value: "bottom"
      },
      {
        label: "Afficher les boutons de navigation",
        key: "showNav",
        type: types.CHECKBOX,
        value: "true"
      },
      {
        label: "Afficher le numéro d'image",
        key: "showIndex",
        type: types.CHECKBOX,
        value: "false"
      },
      {
        label: "Afficher les balles",
        key: "showBullets",
        type: types.CHECKBOX,
        value: "true"
      },
      {
        label: "Afficher le bouton de lecture",
        key: "showPlayButton",
        type: types.CHECKBOX,
        value: "false"
      },
      {
        label: "Afficher le bouton d'affichage en plein écran",
        key: "showFullscreenButton",
        type: types.CHECKBOX,
        value: "false"
      }
    ]
  },
  {
    label: "Image",
    type: types.IMAGE_AS_FILE,
    icon: "image",
    children: [
      {
        key: "file",
        type: types.FILE_IMAGE,
        value: ""
      },
      {
        label: "Texte alternatif",
        key: "alt",
        type: types.INPUT,
        value: ""
      },
      {
        label: "Titre (visible au survol)",
        key: "title",
        type: types.INPUT,
        value: ""
      }
    ]
  },
  {
    label: "Image",
    type: types.IMAGE,
    icon: "image",
    children: [
      {
        key: "file",
        type: types.FILE_IMAGE,
        value: ""
      },
      {
        label: "Texte alternatif",
        key: "alt",
        type: types.INPUT,
        value: ""
      },
      {
        label: "Titre (visible au survol)",
        key: "title",
        type: types.INPUT,
        value: ""
      },
      {
        label: "Position",
        key: "position",
        type: types.SELECT,
        options: [
          { label: "Haute", value: "top" },
          { label: "Centrée", value: "center" },
          { label: "Basse", value: "bottom" }
        ],
        value: "center"
      },
      {
        label: "Filtres",
        key: "filters",
        type: types.IMAGE_FILTERS,
        value: ""
      }
    ]
  },
  {
    label: "Filtres",
    type: types.IMAGE_FILTERS,
    children: [
      {
        label: "Ajouter des filtres",
        key: "activated",
        type: types.CHECKBOX,
        value: "false"
      },
      {
        label: "Opacité",
        key: "opacity",
        type: types.SLIDER,
        value: "1",
        min: "0",
        max: "1",
        step: "0.05"
      },
      {
        label: "Flou",
        key: "blur",
        type: types.SLIDER,
        value: "0",
        min: "0",
        max: "5",
        step: "0.25"
      },
      {
        label: "Échelle de gris",
        key: "grayscale",
        type: types.SLIDER,
        value: "0",
        min: "0",
        max: "1",
        step: "0.05"
      },
      {
        label: "Luminosité",
        key: "brightness",
        type: types.SLIDER,
        value: "1",
        min: "0",
        max: "3",
        step: "0.1"
      },
      {
        label: "Saturation",
        key: "saturate",
        type: types.SLIDER,
        value: "1",
        min: "0",
        max: "3",
        step: "0.2"
      },
      {
        label: "Sépia",
        key: "sepia",
        type: types.SLIDER,
        value: "0",
        min: "0",
        max: "1",
        step: "0.05"
      }
    ]
  },
  {
    type: types.VIDEO,
    editionModal: true,
    editionModalPosition: "top",
    label: "Vidéo",
    icon: "play-circle",
    requiredByKey: ["src"],
    children: [
      {
        key: "source",
        type: types.SELECT,
        value: videosOrigins.YOUTUBE,
        label: "Source",
        options: [
          { label: "Dailymotion", value: videosOrigins.DAILYMOTION },
          { label: "Vimeo", value: videosOrigins.VIMEO },
          { label: "Youtube", value: videosOrigins.YOUTUBE }
        ]
      },
      {
        type: types.URL,
        key: "src",
        value: ""
      }
    ]
  },
  {
    label: "Panneau dépliant",
    type: types.ACCORDION_PANEL,
    value: "",
    editionModal: true,
    editionModalPosition: "top",
    requiredByKey: ["summary"],
    children: [
      {
        label: "Titre du paneau",
        key: "summary",
        type: types.INPUT,
        value: ""
      },
      {
        key: "details",
        type: types.EXPANSION_PANEL_DETAILS,
        value: ""
      }
    ]
  },
  {
    label: "Panneau dépliant",
    type: types.EXPANSION_PANEL,
    IconComponent: ExpansionPanelIcon,
    Preview: ExpansionPanelPreview,
    editionModal: true,
    editionModalPosition: "top",
    requiredByKey: ["summary"],
    children: [
      {
        label: "Titre du paneau",
        key: "summary",
        type: types.INPUT,
        value: ""
      },
      {
        key: "details",
        type: types.EXPANSION_PANEL_DETAILS
      }
    ]
  },
  {
    type: types.EXPANSION_PANEL_DETAILS,
    dynamicChildKey: "detailsElements"
  },
  {
    type: types.ACCORDION,
    label: "Accordéon",
    IconComponent: AccordionIcon,
    Preview: AccordionPreview,
    editionModalPosition: "top",
    value: "",
    dynamicChildKey: "panels",
    children: [
      {
        key: "panels",
        type: types.ACCORDION_PANEL
      }
    ]
  },
  {
    label: "Texte dépliant",
    type: types.EXPANSION_TEXT,
    icon: "chevron-down",
    editionModal: true,
    editionModalPosition: "top",
    Preview: ExpansionTextPreview,
    children: [
      {
        label: "Libellé replié",
        key: "reducedLabel",
        type: types.INPUT,
        value: "Voir plus"
      },
      {
        label: "Libellé déplié",
        key: "expandedLabel",
        type: types.INPUT,
        value: "Voir moins"
      },
      {
        label: "Déplié par défaut",
        key: "defaultExpanded",
        type: types.CHECKBOX,
        value: "false"
      },
      {
        key: "text",
        type: types.TEXT
      }
    ]
  },
  {
    label: "Pièce jointe",
    type: types.ATTACHMENT,
    icon: "paperclip",
    editionModal: true,
    editionModalPosition: "top",
    Preview: AttachmentPreview,
    requiredByKey: ["file"],
    children: [
      {
        label: "Choisir un document",
        key: "file",
        type: types.FILE
      },
      {
        label: "Titre",
        key: "title",
        type: types.INPUT,
        value: "Pièce jointe"
      },
      {
        label: "Description",
        key: "description",
        type: types.INPUT,
        value: ""
      },
      {
        label: "Texte du lien",
        key: "linkText",
        type: types.INPUT,
        value: "Télécharger le fichier"
      }
    ]
  },
  {
    type: types.CHECKBOX
  },
  {
    label: "Couleur",
    type: types.COLOR
  },
  {
    type: types.CONTENTS_GROUPS_LIST,
    value: "",
    children: [
      {
        type: types.CONTENTS_GROUP,
        key: "groups"
      }
    ]
  },
  {
    type: types.CONTENTS_GROUP,
    value: "",
    children: [
      {
        type: types.INPUT,
        key: "name",
        value: ""
      },
      {
        key: "contents"
      }
    ]
  },
  {
    label: "Choisir un fichier",
    type: types.FILE
  },
  {
    label: "Choisir une image",
    type: types.FILE_IMAGE
  },
  {
    type: types.DYNAMIC,
    dynamicChildKey: "dynamicElements"
  },
  {
    type: types.GLOSSARY,
    dynamicChildKey: "items"
  },
  {
    label: "Définition",
    type: types.GLOSSARY_ITEM,
    // editionModal: true,
    children: [
      {
        key: "word",
        label: "Mot / Acronyme",
        type: types.INPUT,
        value: ""
      },
      {
        label: "Description",
        key: "description",
        type: types.SHORT_TEXT,
        value: ""
      }
    ]
  },
  {
    label: "Grille",
    type: types.GRID,
    category: categories.STRUCTURE,
    icon: "border-none",
    editionModal: true,
    editionModalPosition: "top",
    Preview: GridPreview,
    dynamicChildKey: "items",
    children: [
      {
        key: "size",
        label: "Nombre de colonnes",
        type: types.SELECT,
        options: [
          { label: "2 colonnes", value: "6" },
          { label: "3 colonnes", value: "4" },
          { label: "4 colonnes", value: "3" },
          { label: "6 colonnes", value: "2" }
        ],
        value: "6"
      },
      {
        key: "sizeXs",
        label: "Nombre de colonnes en mobile",
        type: types.SELECT,
        options: [
          { label: "1 colonne", value: "12" },
          { label: "2 colonnes", value: "6" },
          { label: "3 colonnes", value: "4" },
          { label: "4 colonnes", value: "3" }
        ],
        value: "12"
      },
      {
        key: "justify",
        label: "Alignement des colonnes",
        type: types.SELECT,
        options: [
          { label: "À gauche", value: "flex-start" },
          { label: "Centré", value: "center" },
          { label: "À droite", value: "flex-end" }
        ],
        value: "flex-start"
      }
    ]
  },
  {
    label: "Conteneur",
    type: types.CONTAINER,
    category: categories.STRUCTURE,
    IconComponent: ContainerIcon,
    editionModal: true,
    editionModalPosition: "top",
    Preview: ContainerPreview,
    dynamicChildKey: "containerElement",
    children: [
      {
        key: "justifyContent",
        label: "Alignement",
        type: types.SELECT,
        options: [
          { label: "À gauche", value: "flex-start" },
          { label: "Centré", value: "center" },
          { label: "À droite", value: "flex-end" }
        ],
        value: "center"
      },
      {
        label: "Largeur du contenu (en %)",
        key: "width",
        type: types.SLIDER,
        value: "50",
        min: "0",
        max: "100",
        step: "10"
      },
      {
        label: "Largeur du contenu (en %) sur mobile",
        key: "mobileWidth",
        type: types.SLIDER,
        min: "0",
        max: "100",
        step: "10"
      }
    ]
  },
  {
    label: "Espace vertical",
    type: types.MARGIN,
    category: categories.STRUCTURE,
    value: "",
    icon: "arrows-alt-v",
    editionModal: true,
    editionModalPosition: "top",
    children: [
      {
        label: "Hauteur (en px)",
        key: "height",
        type: types.SLIDER,
        value: "10",
        min: "0",
        max: "100",
        step: "5"
      },
      {
        label: "Hauteur (en px) sur mobile",
        key: "mobileHeight",
        type: types.SLIDER,
        value: "10",
        min: "0",
        max: "100",
        step: "5"
      }
    ]
  },
  {
    label: "Icône",
    type: types.ICON,
    value: "",
    children: [
      {
        label: "Nom",
        key: "icon",
        type: types.INPUT,
        value: ""
      },
      {
        label: "Type",
        key: "type",
        type: types.SELECT,
        options: [
          { label: "Solid", value: "fas" },
          { label: "Regular", value: "far" },
          { label: "Light", value: "fal" },
          { label: "Duotone", value: "fad" },
          { label: "Brands", value: "fab" }
        ],
        value: "fal"
      },
      {
        label: "Couleur",
        type: types.COLOR,
        key: "color",
        value: ""
      },
      {
        label: "Couleur de fond",
        type: types.COLOR,
        key: "background",
        value: ""
      }
    ]
  },
  {
    label: "Plage de valeurs",
    type: types.SLIDER,
    value: "100",
    min: "0",
    max: "100",
    step: "10"
  },
  {
    label: "Bloc d'information",
    type: types.INFO_BLOCK,
    IconComponent: InfoBlockIcon,
    editionModal: true,
    editionModalPosition: "top",
    Preview: InfoBlockPreview,
    children: [
      {
        key: "icon",
        type: types.ICON
      },
      {
        label: "Texte",
        key: "text",
        type: types.TEXT
      }
    ]
  },
  {
    type: types.INFO_BLOCK_LIST,
    dynamicChildKey: "infos"
  },
  {
    label: "Texte",
    type: types.INPUT,
    value: ""
  },
  {
    label: "Lien",
    type: types.LINK,
    children: [
      {
        label: "Url",
        key: "url",
        type: types.URL,
        value: ""
      },
      {
        label: "Page",
        key: "page",
        type: types.PAGE,
        value: ""
      },
      {
        key: "title",
        label: "Titre du lien",
        type: types.INPUT,
        value: ""
      },
      {
        label: "Ouvrir dans un nouvel onglet",
        key: "external",
        type: types.CHECKBOX,
        value: "false"
      }
    ]
  },

  {
    label: "Groupe de liens",
    editionModal: true,
    type: types.MENU_GROUP,
    children: [
      {
        key: "title",
        type: types.INPUT,
        value: ""
      },
      {
        key: "icon",
        type: types.ICON,
        value: ""
      }
    ]
  },
  {
    label: "Lien",
    editionModal: true,
    type: types.MENU_ITEM,
    children: [
      {
        key: "title",
        type: types.INPUT,
        value: ""
      },
      {
        key: "link",
        type: types.LINK,
        value: ""
      },
      {
        key: "icon",
        type: types.ICON,
        value: ""
      }
    ]
  },
  {
    label: "Résumé de page",
    type: types.PAGE
  },
  {
    type: types.SELECT
  },
  {
    type: types.SITE_SETTINGS,
    value: "",
    children: [
      {
        key: "preTitle",
        type: types.INPUT,
        value: ""
      },
      {
        key: "copyright",
        type: types.INPUT,
        value: ""
      },
      {
        key: "newsletterTitle",
        type: types.INPUT,
        value: ""
      },
      {
        key: "newsletterSubTitle",
        type: types.INPUT,
        value: ""
      },
      {
        key: "newsletterInputLabel",
        type: types.INPUT,
        value: ""
      },
      {
        key: "newsletterCheckboxLabel",
        type: types.TEXT,
        value: ""
      },
      {
        key: "newsletterCheckboxSubLabel",
        type: types.INPUT,
        value: ""
      },
      {
        key: "newsletterButtonLabel",
        type: types.INPUT,
        value: ""
      },
      {
        key: "newsletterDisplayModalDelay",
        type: types.INPUT_NUMBER,
        value: "15"
      },
      {
        key: "logoHeader",
        type: types.IMAGE,
        children: [
          {
            key: "file",
            type: types.FILE_IMAGE,
            value: ""
          },
          {
            label: "Texte alternatif",
            key: "alt",
            type: types.INPUT,
            value: ""
          },
          {
            label: "Titre (visible au survol)",
            key: "title",
            type: types.INPUT,
            value: ""
          }
        ]
      },
      {
        key: "logoFooter",
        type: types.IMAGE,
        children: [
          {
            key: "file",
            type: types.FILE_IMAGE,
            value: ""
          },
          {
            label: "Texte alternatif",
            key: "alt",
            type: types.INPUT,
            value: ""
          },
          {
            label: "Titre (visible au survol)",
            key: "title",
            type: types.INPUT,
            value: ""
          }
        ]
      },
      {
        key: "warningBanner",
        type: types.WARNING_BANNER,
        value: ""
      },
      {
        key: "analytics",
        type: types.INPUT,
        value: ""
      },
      {
        key: "tagManager",
        type: types.INPUT,
        value: ""
      },
      {
        key: "socialNetworks",
        type: types.SOCIAL_NETWORK,
        value: ""
      },
      {
        key: "newsletterImage",
        type: types.IMAGE,
        children: [
          {
            key: "file",
            type: types.FILE_IMAGE,
            value: ""
          },
          {
            label: "Texte alternatif",
            key: "alt",
            type: types.INPUT,
            value: ""
          },
          {
            label: "Titre (visible au survol)",
            key: "title",
            type: types.INPUT,
            value: ""
          }
        ]
      }
    ]
  },
  {
    type: types.TEMPLATES_SETTINGS,
    value: "",
    children: []
  },
  {
    type: types.TEMPLATE_SETTINGS,
    value: "",
    children: []
  },
  {
    type: types.SOCIAL_NETWORK,
    value: "",
    children: [
      {
        key: "url",
        type: types.URL,
        value: ""
      },
      {
        key: "title",
        label: "Titre du lien",
        type: types.INPUT,
        value: ""
      },
      {
        key: "icon",
        type: types.ICON,
        value: ""
      }
    ]
  },
  {
    label: "Étape",
    type: types.STEP,
    children: [
      {
        key: "title",
        type: types.TEXT,
        value: ""
      },
      {
        key: "content",
        type: types.TEXT,
        value: ""
      }
    ]
  },
  {
    label: "Étape",
    editionModalPosition: "top",
    type: types.STEP_V2,
    children: [
      {
        key: "title",
        type: types.TEXT,
        value: ""
      },
      {
        key: "content",
        type: types.STEP_V2_CONTENT,
        value: ""
      }
    ]
  },
  {
    type: types.STEP_V2_CONTENT,
    dynamicChildKey: "detailsElements"
  },
  {
    label: "Sous-page",
    type: types.SUBPAGE,
    editionModal: true,
    editionModalPosition: "top"
  },
  {
    label: "Liste de sous pages",
    type: types.SUBPAGES_LIST,
    value: "",
    dynamicChildKey: "pages",
    children: [
      {
        key: "pages",
        type: types.SUBPAGE
      }
    ]
  },

  {
    label: "Historique",
    type: types.TIMELINE,
    editionModalPosition: "top",
    IconComponent: TimelineIcon,
    Preview: TimelinePreview,
    dynamicChildKey: "steps",
    children: [
      {
        label: "Étape",
        type: types.STEP_V2,
        key: "steps"
      }
    ]
  },
  {
    label: "Onglets",
    type: types.TABS,
    children: [
      {
        key: "tabs",
        type: types.TAB
      }
    ]
  },
  {
    type: types.TAB,
    label: "Onglet",
    children: [
      {
        key: "title",
        label: "Titre",
        type: types.INPUT,
        value: ""
      },
      {
        key: "children",
        type: types.DYNAMIC
      }
    ]
  },
  {
    type: types.URL,
    label: "Url",
    regexValidation: urlRegex
  },
  {
    type: types.WARNING_BANNER,
    label: "Bandeau d'information",
    children: [
      {
        key: "enable",
        type: types.CHECKBOX,
        value: "false"
      },
      {
        key: "message",
        type: types.TEXT,
        value: ""
      },
      {
        key: "severity",
        type: types.SELECT,
        value: severitiesTypes.ERROR.key,
        label: "Type d'information",
        options: Object.keys(severitiesTypes).map(severity => ({
          label: severitiesTypes[severity].label,
          value: severitiesTypes[severity].key
        }))
      }
    ]
  },
  {
    label: "Bloc de code",
    type: types.CODE_BLOCK,
    icon: "code",
    editionModal: true,
    editionModalPosition: "top",
    children: [
      {
        label: "Code",
        key: "code",
        type: types.CODE_BLOCK_TEXT,
        value: ""
      },
      {
        label: "Langage",
        key: "language",
        type: types.SELECT,
        options: [
          { label: "C", value: "c" },
          { label: "C++", value: "cpp" },
          { label: "C#", value: "csharp" },
          { label: "CSS", value: "css" },
          { label: "Docker", value: "docker" },
          { label: "Git", value: "git" },
          { label: "HTML", value: "html" },
          { label: "Java", value: "java" },
          { label: "Javascript", value: "javascript" },
          { label: "Json", value: "json" },
          { label: "JSX", value: "jsx" },
          { label: "Less", value: "less" },
          { label: "Objective C", value: "objectivec" },
          { label: "PHP", value: "php" },
          { label: "Python", value: "python" },
          { label: "Regex", value: "regex" },
          { label: "Ruby", value: "ruby" },
          { label: "Sass", value: "sass" },
          { label: "SQL", value: "sql" },
          { label: "Typescript", value: "typescript" },
          { label: "Vim", value: "vim" }
        ],
        value: "javascript"
      },
      {
        label: "Mode sombre",
        key: "isDark",
        type: types.CHECKBOX,
        value: "true"
      }
    ]
  },
  {
    type: types.CODE_BLOCK_TEXT,
    value: ""
  }
];

const getAllContents = () => {
  const projectContents = (CmsHooks.getHook(HOOKS.adminContentsUtils_contents) || []).map(projectContent => ({
    ...projectContent,
    category: projectContent.category || categories.SPECIFIC
  }));
  const projectTypes = projectContents.map(c => c.type);
  const contentsFiltered = contents.filter(c => !projectTypes.includes(c.type));
  return [...projectContents, ...contentsFiltered];
};

// Get a random number for ids
export const generateContentId = (content, updatedIds = {}) => {
  const newId = uuidv4();
  if (content.id && typeof updatedIds === "object") {
    // eslint-disable-next-line no-param-reassign
    updatedIds[content.id] = newId;
  }
  const contentWithId = { ...content, id: newId };
  if (content && content.children) {
    contentWithId.children = content.children.map(child => generateContentId(child, updatedIds));
  }
  return contentWithId;
};

export const enhanceContent = (content = {}) => {
  const { type, children = [] } = content;
  const defaultContent = getAllContents().find(c => c.type === type);
  const { dynamicChildKey } = defaultContent || {};

  const enhancedContent = {
    ...defaultContent,
    ...content
  };

  // enchanced children
  const enhancedChildren = children.map(child => {
    const defaultChild = ((defaultContent && defaultContent.children) || []).find(c => c.key === child.key) || {};
    return enhanceContent({
      ...defaultChild,
      ...child
    });
  });

  // add defaults children not founds
  ((defaultContent && defaultContent.children) || []).forEach(defaultChild => {
    const defaultChildAlreadyExists = enhancedChildren.find(c => c.key === defaultChild.key);
    if ((defaultChild.value !== undefined || defaultChild.children) && !defaultChildAlreadyExists) {
      enhancedChildren.push(generateContentId(enhanceContent(defaultChild)));
    }
  });

  if (defaultContent && defaultContent.children) {
    enhancedContent.children = enhancedChildren.filter(
      child =>
        (dynamicChildKey && child.key === dynamicChildKey) ||
        ((defaultContent && defaultContent.children) || []).find(defaultChild => child.key === defaultChild.key)
    );
  } else {
    enhancedContent.children = enhancedChildren;
  }

  return {
    ...enhancedContent,
    value: enhancedContent.value || ""
  };
};

export const replacePagesByIds = (content, updatedIds = {}) => {
  const { type, value, children } = content;
  const newContent = { ...content };

  if ([types.PAGE, types.SUBPAGE].includes(type) && typeof value === "object") {
    newContent.value = value.page ? value.page.id : "";
  } else if (type === types.CONTENTS_GROUP) {
    newContent.children = children.map(child => {
      if (child.key === "contents") {
        return {
          ...child,
          value: updatedIds[child.value] || child.value,
          children: []
        };
      }
      return child;
    });
  } else if (children && children.length) {
    newContent.children = children.map(child => replacePagesByIds(child, updatedIds));
  }
  return newContent;
};

export const getDefaultContentByType = type => enhanceContent(getAllContents().find(content => content.type === type));

export const getAllDefaultContents = () => getAllContents().map(enhanceContent);

export const adminProperties = [
  "label",
  "options",
  "editionModal",
  "editionModalPosition",
  "isTemplateSettings",
  "min",
  "max",
  "step",
  "category",
  "icon",
  "requiredByKey",
  "regexValidation",
  "width",
  "tooltip",
  "limit",
  "canChangePosition",
  "dynamicChildKey"
];

export default {};
