var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { Box, FormControlLabel, Grid, Radio, RadioGroup, Switch, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FilterIcon from "@mui/icons-material/FilterAltOutlined";
import SearchLabel from "components/SearchLabel";
import SearchEstablishmentType from "components/SearchEstablishmentType";
import SearchSlider from "components/SearchSlider";
import SearchProximityElements from "components/SearchProximityElements";
import SearchEstablishmentPriceType from "components/SearchEstablishmentPriceType";
import getEnvironmentPromise from "cms/back-office/services/getEnvironment";
import { useFeatureList } from "services/feature.service";
import { FeatureCategory } from "types/feature/feature-category.enum";
import { EstablishmentType } from "types/establishment/establishment-type.enum";
import { SearchParamsAvailability } from "types/search-params-availability.enum";
import { getEstablishmentTypeLabel } from "utils/establishmentUtils";
import { getEstablishmentTypeFromSearchParams } from "utils/establishmentSearchUtils";
var AdvancedSearchFiltered = function (_a) {
    var form = _a.form, setForm = _a.setForm;
    var availability = form.availability, stayHome = form.stayHome, medicalSupport = form.medicalSupport, needAssist = form.needAssist, budgetMin = form.budgetMin, budgetMax = form.budgetMax, establishmentPrices = form.establishmentPrices, proximityElements = form.proximityElements, PMR = form.PMR;
    var allFeatures = useFeatureList({
        params: {
            per_page: 200
        }
    }).data;
    var _b = React.useState([]), allSearchFeatures = _b[0], setAllSearchFeatures = _b[1];
    React.useEffect(function () {
        getEnvironmentPromise().then(function (_a) {
            var search_features = _a.search_features;
            if (search_features) {
                setAllSearchFeatures(search_features.split(","));
            }
        });
    }, []);
    var searchFeatures = React.useMemo(function () {
        var _a;
        return ((_a = allFeatures === null || allFeatures === void 0 ? void 0 : allFeatures.data) === null || _a === void 0 ? void 0 : _a.filter(function (f) { return allSearchFeatures.includes(f.name); })) || [];
    }, [allFeatures, allSearchFeatures]);
    var searchEnvironmentFeatures = React.useMemo(function () {
        return searchFeatures.filter(function (f) { return f.category === FeatureCategory.ENVIRONMENT; });
    }, [searchFeatures]);
    var searchEveryDayFeatures = React.useMemo(function () {
        return searchFeatures.filter(function (f) { return f.category === FeatureCategory.EVERYDAY_LIFE; });
    }, [searchFeatures]);
    var _c = searchEveryDayFeatures[0] || {}, id = _c.id, name = _c.name;
    var _d = React.useState(false), displayFilters = _d[0], setDisplayFilters = _d[1];
    var type = getEstablishmentTypeFromSearchParams({
        stayHome: stayHome,
        medicalSupport: medicalSupport,
        needAssist: needAssist
    });
    var handleDisplayFilters = useCallback(function () {
        setDisplayFilters(!displayFilters);
    }, [displayFilters]);
    var handleChangeRadio = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setForm(__assign(__assign({}, form), (_a = {}, _a[name] = value, _a)));
    };
    var handleChangeSwitch = function (e) {
        var _a = e.target, checked = _a.checked, value = _a.value;
        if (checked) {
            setForm(__assign(__assign({}, form), { PMR: value }));
        }
        else {
            setForm(__assign(__assign({}, form), { PMR: "" }));
        }
    };
    var availabilityLabel;
    switch (availability) {
        case "IMMEDIAT":
            availabilityLabel = " immédiate";
            break;
        case "NOT_IMPORTANT":
            availabilityLabel = " sans importance";
            break;
    }
    return (_jsxs(Box, __assign({ sx: { mb: "35px" } }, { children: [_jsxs(Grid, __assign({ container: true, justifyContent: "center", alignItems: "center", sx: { display: { md: "none" }, cursor: "pointer", mb: "20px" }, onClick: handleDisplayFilters }, { children: [_jsx(FilterIcon, { fontSize: "large" }, void 0), _jsx(Typography, __assign({ variant: "telephone", sx: {
                            mx: "10px"
                        } }, { children: "Filtres" }), void 0), _jsx(CloseIcon, { sx: {
                            display: displayFilters ? "block" : "none",
                            color: "colors.recherche"
                        } }, void 0)] }), void 0), _jsx(Box, __assign({ sx: { display: { xs: displayFilters ? "block" : "none", md: "block" } } }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2, sx: { mb: "20px" } }, { children: [_jsx(SearchLabel, { label: "Disponibilit\u00E9" + (availability ? availabilityLabel : ""), value: availability, content: _jsx(RadioGroup, __assign({ row: true, name: "availability", value: availability, onChange: handleChangeRadio }, { children: _jsx(Grid, __assign({ container: true, direction: "column" }, { children: Object.entries(SearchParamsAvailability).map(function (_a) {
                                        var availabilityKey = _a[0], availabilityLabel = _a[1];
                                        return (_jsx(FormControlLabel, { value: availabilityKey, control: _jsx(Radio, {}, void 0), label: _jsx(Typography, __assign({ variant: "donnee" }, { children: availabilityLabel }), void 0) }, availabilityKey));
                                    }) }), void 0) }), void 0) }, void 0), _jsx(SearchLabel, { label: type ? getEstablishmentTypeLabel(type) : "Type d'hébergement", value: type ? type : "", content: _jsx(SearchEstablishmentType, { form: form, setForm: setForm, filter: true }, void 0) }, void 0), _jsx(SearchLabel, { label: budgetMin && budgetMax !== "0" ? budgetMin + " - " + budgetMax + " \u20AC" : "Tarif", value: budgetMax !== "0" ? budgetMin + " - " + budgetMax + " \u20AC" : "", content: _jsx(SearchSlider, { form: form, setForm: setForm, filter: true }, void 0), PopperProps: { sx: { width: 480 } } }, void 0), _jsx(SearchLabel, { label: "Nombre de pi\u00E8ces", value: establishmentPrices === null || establishmentPrices === void 0 ? void 0 : establishmentPrices.length, content: _jsx(SearchEstablishmentPriceType, { form: form, setForm: setForm }, void 0), disabled: type !== EstablishmentType.RA }, void 0), searchEnvironmentFeatures.length > 0 && (_jsx(SearchLabel, { label: "Environnement", value: proximityElements === null || proximityElements === void 0 ? void 0 : proximityElements.length, content: _jsx(SearchProximityElements, { form: form, setForm: setForm, searchEnvironmentFeatures: searchEnvironmentFeatures }, void 0) }, void 0)), name && (_jsxs(Grid, __assign({ item: true, xs: "auto", container: true, alignItems: "center" }, { children: [_jsx(Switch, { id: "advanced-search-filtered-pmr", name: "PMR", checked: !!PMR, value: id, onChange: handleChangeSwitch }, void 0), _jsx(Typography, __assign({ sx: { color: "colors.recherche_fonce" } }, { children: _jsx(Box, __assign({ component: "label", htmlFor: "advanced-search-filtered-pmr" }, { children: name }), void 0) }), void 0)] }), void 0))] }), void 0) }), void 0)] }), void 0));
};
export default AdvancedSearchFiltered;
