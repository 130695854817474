import axios from "axios";

const configs = {
  getMenu: siteId => ({
    url: "/menus",
    params: { siteId }
  }),
  getFooter: siteId => ({
    url: "/footer",
    params: { siteId }
  }),
  getHeader: siteId => ({
    url: "/header",
    params: { siteId }
  })
};

class SectionService {
  static getMenu = siteId => {
    return axios(configs.getMenu(siteId)).then(response => response.data);
  };

  static getFooter = siteId => {
    return axios(configs.getFooter(siteId)).then(response => response.data);
  };

  static getHeader = siteId => {
    return axios(configs.getHeader(siteId)).then(response => response.data);
  };

  static getConfig(methodName, options) {
    const getter = configs[methodName];
    if (typeof getter === "function") {
      return getter(options);
    }
    return {};
  }
}

export default SectionService;
