import axios from "axios";
import AdminSitesService from "cms/back-office/services/AdminSitesService";
export var entityName = "establishmentsResults";
export var resetArpavieSiteCache = function () {
    return axios.get("/environment.json").then(function (response) {
        var env = response.data;
        var arpavie_site_id = env.arpavie_site_id;
        if (arpavie_site_id) {
            AdminSitesService.get(arpavie_site_id).then(function (site) {
                AdminSitesService.resetSiteCache(site);
            });
        }
    });
};
