var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Grid, Typography, Button, Link, Box } from "@mui/material";
import SearchSlider from "components/SearchSlider";
import SearchStep from "routes/Search/SearchStep";
import { getEstablishmentTypeFromSearchParams } from "utils/establishmentSearchUtils";
import { EstablishmentType } from "types/establishment/establishment-type.enum";
export var summaryTitle = "Prix";
var PriceStep = function (props) {
    var form = props.form, setForm = props.setForm, handleValidateStep = props.handleValidateStep, nextStepButton = props.nextStepButton;
    var accordionsContent;
    var buttonLinkHelpPrice = (_jsx(Button, __assign({ color: "secondary", variant: "outlined", sx: { mt: 4 } }, { children: _jsx(Link, __assign({ href: "/aides-financieres", underline: "none", sx: { color: "inherit" } }, { children: "En savoir plus" }), void 0) }), void 0));
    if (getEstablishmentTypeFromSearchParams(form) === EstablishmentType.EHPAD) {
        accordionsContent = [
            {
                title: "Comment définir mon budget pour un accueil en maison de retraite médicalisée ?",
                text: (_jsxs(_Fragment, { children: ["En Ehpad, la tarification comporte 3 parties - Chaque mois, le r\u00E9sident doit payer une facture qui se d\u00E9compose en :", _jsxs("ol", { children: [_jsx("li", { children: "Un prix h\u00E9bergement, comprenant les prestations h\u00F4teli\u00E8res, les repas, l\u2019entretien et les animations" }, void 0), _jsx("li", { children: "Un tarif d\u00E9pendance, qui correspond aux d\u00E9penses li\u00E9es \u00E0 la prise en charge sp\u00E9cifique de la perte d\u2019autonomie, qui peut partiellement \u00EAtre pris en charge par le conseil d\u00E9partemental dans le cadre de l\u2019APA (allocation personnalis\u00E9e d\u2019autonomie) en \u00E9tablissement" }, void 0), _jsx("li", { children: "Les soins, qui sont directement pris en charge par l\u2019Assurance Maladie" }, void 0)] }, void 0), "Des aides financi\u00E8res peuvent venir diminuer le montant de votre facture en EHPAD : APA (allocation personnalis\u00E9e d'autonomie), aide au logement, aide sociale \u00E0 l'h\u00E9bergement. Les r\u00E9sidents imposables peuvent b\u00E9n\u00E9ficier d'une r\u00E9duction d'imp\u00F4t.", _jsx(Box, { children: buttonLinkHelpPrice }, void 0)] }, void 0))
            }
        ];
    }
    if (getEstablishmentTypeFromSearchParams(form) === EstablishmentType.RA) {
        accordionsContent = [
            {
                title: "Comment définir mon budget pour un accueil en résidence autonomie ?",
                text: (_jsxs(_Fragment, { children: ["En r\u00E9sidence autonomie, la tarification comporte 4 parties \u2013 Chaque mois, le r\u00E9sident doit payer une facture qui se d\u00E9compose en :", _jsxs("ol", { children: [_jsx("li", { children: "Le loyer" }, void 0), _jsx("li", { children: "Les charges locatives" }, void 0), _jsx("li", { children: "Les frais li\u00E9s au socle de prestation (restauration, blanchisserie, dispositif de s\u00E9curit\u00E9 24h/24 et 7j/7, animations, Wifi\u2026)" }, void 0), _jsx("li", { children: "Les frais li\u00E9s aux services compl\u00E9mentaires (conciergerie s\u00E9nior, retouche de v\u00EAtement, minibus..)" }, void 0)] }, void 0), "Des aides financi\u00E8res peuvent venir diminuer le montant de votre en r\u00E9sidence autonomie : APA (allocation personnalis\u00E9e d\u2019autonomie), APL (aide personnalis\u00E9e au logement), ALS (allocation de logement social), ASH (aide sociale \u00E0 l\u2019h\u00E9bergement).", _jsx(Box, { children: buttonLinkHelpPrice }, void 0)] }, void 0))
            }
        ];
    }
    return (_jsx(SearchStep, __assign({ title: summaryTitle, subtitle: "Quel est votre budget ?", button: nextStepButton, accordions: accordionsContent, handleValidateStep: handleValidateStep }, { children: _jsxs(Grid, __assign({ item: true, xs: 12, md: 6, container: true, direction: "column" }, { children: [_jsx(Grid, __assign({ item: true }, { children: _jsx(Typography, __assign({ variant: "label_mini", sx: { mb: "17px" } }, { children: "Budget par mois" }), void 0) }), void 0), _jsx(Grid, __assign({ item: true, sx: { pl: "5px" } }, { children: _jsx(SearchSlider, { form: form, setForm: setForm }, void 0) }), void 0)] }), void 0) }), void 0));
};
export default PriceStep;
