export default {
  ACCORDION: "ACCORDION",
  ACCORDION_PANEL: "ACCORDION_PANEL",
  ATTACHMENT: "ATTACHMENT",
  BUTTON: "BUTTON",
  CODE_BLOCK: "CODE_BLOCK",
  CODE_BLOCK_TEXT: "CODE_BLOCK_TEXT",
  COLOR: "COLOR",
  CONTAINER: "CONTAINER",
  CONTENTS_GROUP: "CONTENTS_GROUP",
  CONTENTS_GROUPS_LIST: "CONTENTS_GROUPS_LIST",
  CHECKBOX: "CHECKBOX",
  DYNAMIC: "DYNAMIC",
  EDITABLE_INPUT: "EDITABLE_INPUT",
  EXPANSION_PANEL: "EXPANSION_PANEL",
  EXPANSION_PANEL_DETAILS: "EXPANSION_PANEL_DETAILS",
  EXPANSION_TEXT: "EXPANSION_TEXT",
  FILE: "FILE",
  FILE_IMAGE: "FILE_IMAGE",
  GLOSSARY: "GLOSSARY",
  GLOSSARY_ITEM: "GLOSSARY_ITEM",
  GRID: "GRID",
  IMAGE: "IMAGE",
  IMAGE_AS_FILE: "IMAGE_AS_FILE",
  IMAGE_BLOCK: "IMAGE_BLOCK",
  IMAGES: "IMAGES",
  IMAGES_GALLERY: "IMAGES_GALLERY",
  IMAGE_FILTERS: "IMAGE_FILTERS",
  ICON: "ICON",
  INFO_BLOCK: "INFO_BLOCK",
  INFO_BLOCK_LIST: "INFO_BLOCK_LIST",
  INPUT: "INPUT",
  INPUT_NUMBER: "INPUT_NUMBER",
  LINK: "LINK",
  RADIO_GROUP: "RADIO_GROUP",
  MARGIN: "MARGIN",
  MENU_ITEM: "MENU_ITEM",
  MENU_GROUP: "MENU_GROUP",
  NUMERIC: "NUMERIC",
  PAGE: "PAGE",
  SELECT: "SELECT",
  SHORT_TEXT: "SHORT_TEXT",
  STEP: "STEP",
  STEP_V2: "STEP_V2",
  STEP_V2_CONTENT: "STEP_V2_CONTENT",
  TABS: "TABS",
  TAB: "TAB",
  TEXT: "TEXT",
  TIMELINE: "TIMELINE",
  TEMPLATES_SETTINGS: "TEMPLATES_SETTINGS",
  TEMPLATE_SETTINGS: "TEMPLATE_SETTINGS",
  SITE_SETTINGS: "SITE_SETTINGS",
  SLIDER: "SLIDER",
  SOCIAL_NETWORK: "SOCIAL_NETWORK",
  SUBPAGES_LIST: "SUBPAGES_LIST",
  SUBPAGE: "SUBPAGE",
  URL: "URL",
  VIDEO: "VIDEO",
  WARNING_BANNER: "WARNING_BANNER"
};
