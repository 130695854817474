import home from "templates/home/home";
import dynamic from "templates/dynamic/dynamic";
import establishment from "templates/establishment/establishment";
import arpavieDom from "projects/ARPAVIE/templates/arpavieDom/arpavieDom";
import article from "templates/article/article";
import articles from "templates/articles/articles";
import jobOffers from "templates/jobOffers/jobOffers";
import jobOffer from "templates/jobOffer/jobOffer";
import residences from "templates/residences/residences";

export default [home, arpavieDom, dynamic, articles, article, establishment, jobOffers, jobOffer, residences];
