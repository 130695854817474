export default {
  CUSTOM_CARD: "CUSTOM_CARD",
  PRESENTATION_BOX: "PRESENTATION_BOX",
  INFO_BLOCK: "INFO_BLOCK",
  SPEECH_CARD: "SPEECH_CARD",
  CUSTOM_TABS: "CUSTOM_TABS",
  PATH_OPTION: "PATH_OPTION",
  PATH_OPTIONS: "PATH_OPTIONS",
  MAILTO: "MAILTO",
  SERVICE_DESCRIPTION: "SERVICE_DESCRIPTION",
  FEATURES: "FEATURES",
  FEATURES_LIST: "FEATURES_LIST",
  FEATURE: "FEATURE"
};
