import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import Layout from "Layout/Layout";
import NotFound from "components/NotFound";
import templates from "templates/templates";
import routes from "routes/routes";
import theme from "theme";
import getDynamicComponent from "customComponents/getDynamicComponent";

export default () => {
  CmsHooks.registerHook(HOOKS.PageSwitcher_404, NotFound);
  CmsHooks.registerHook(HOOKS.App_Layout, Layout);
  CmsHooks.registerHook(HOOKS.THEME, theme);
  CmsHooks.registerHook(HOOKS.TEMPLATES, templates);
  CmsHooks.registerHook(HOOKS.App_routes, routes);
  CmsHooks.registerHook(HOOKS.templatePropsUtils_getDynamicComponent, getDynamicComponent);
};
