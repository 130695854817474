var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from "react";
import CookiesConsentService from "services/CookiesConsentService";
var CookiesContext = React.createContext({
    openCookiesModal: false,
    setOpenCookiesModal: function () { return null; },
    handleCookiesModal: function () { return null; },
    isDisabledBanner: true,
    disabledBanner: function () { return null; }
});
export default CookiesContext;
export var CookiesContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState(false), openCookiesModal = _b[0], setOpenCookiesModal = _b[1];
    var _c = useState(true), isDisabledBanner = _c[0], setIsDisabledBanner = _c[1];
    React.useEffect(function () {
        setIsDisabledBanner(CookiesConsentService.isDisableCookiesBanner());
    }, []);
    var handleCookiesModal = function () {
        setOpenCookiesModal(!openCookiesModal);
    };
    var disabledBanner = function () {
        CookiesConsentService.disableCookiesBanner();
        setIsDisabledBanner(true);
    };
    return (_jsx(CookiesContext.Provider, __assign({ value: {
            openCookiesModal: openCookiesModal,
            handleCookiesModal: handleCookiesModal,
            setOpenCookiesModal: setOpenCookiesModal,
            isDisabledBanner: isDisabledBanner,
            disabledBanner: disabledBanner
        } }, { children: children }), void 0));
};
