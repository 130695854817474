var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { makeStyles } from "@mui/styles";
export var overrideKey = "customization";
export default (function (componentKey, style) {
    return makeStyles(function (theme) {
        var outputStyle = style;
        if (typeof outputStyle === "function") {
            outputStyle = outputStyle(theme);
        }
        if (typeof outputStyle !== "object") {
            return {};
        }
        var addTheme = function (currentStyle, themePath) {
            if (themePath === void 0) { themePath = [overrideKey, componentKey]; }
            Object.keys(currentStyle).forEach(function (key) {
                var themePathFull = __spreadArray(__spreadArray([], themePath, true), [key], false);
                var value = currentStyle[key];
                if (typeof value === "object") {
                    addTheme(value, themePathFull);
                }
                else if (typeof value === "function") {
                    // eslint-disable-next-line no-param-reassign
                    currentStyle[key] = function (props) {
                        return addTheme(value(props), themePathFull);
                    };
                }
            });
            var themeValue = themePath.reduce(function (v, k) { return v[k] || {}; }, theme);
            Object.keys(themeValue).forEach(function (key) {
                if (["string", "number"].includes(typeof themeValue[key]) || typeof currentStyle[key] === "undefined") {
                    // eslint-disable-next-line no-param-reassign
                    currentStyle[key] = themeValue[key];
                }
            });
            return currentStyle;
        };
        addTheme(outputStyle);
        return outputStyle;
    });
});
