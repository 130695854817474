import contentsTypes from "cms/enums/contentsTypes";
import customTypes from "customComponents/customTypes";
import HomeTemplate from "templates/home/HomeTemplate";
import { Templates } from "types/templates.enum";
export default {
    key: Templates.HOME,
    label: "Accueil",
    templateImage: "/home-solid.svg",
    Component: HomeTemplate,
    initialContents: [
        {
            key: "subtitle",
            type: contentsTypes.EDITABLE_INPUT,
            value: "Quelle résidence vous convient ?"
        },
        {
            key: "firstCustomCard",
            type: customTypes.CUSTOM_CARD,
            value: "",
            children: [
                {
                    key: "type",
                    type: contentsTypes.RADIO_GROUP,
                    value: "medicalSupport"
                },
                {
                    key: "title",
                    type: contentsTypes.EDITABLE_INPUT,
                    value: "j’ai besoin d’un hébergement médicalisé"
                }
            ]
        },
        {
            key: "secondCustomCard",
            type: customTypes.CUSTOM_CARD,
            value: "",
            children: [
                {
                    key: "type",
                    type: contentsTypes.RADIO_GROUP,
                    value: "needAssist"
                },
                {
                    key: "title",
                    type: contentsTypes.EDITABLE_INPUT,
                    value: "je souhaite un logement dans une résidence avec des services"
                }
            ]
        },
        {
            key: "thirdCustomCard",
            type: customTypes.CUSTOM_CARD,
            value: "",
            children: [
                {
                    key: "type",
                    type: contentsTypes.RADIO_GROUP,
                    value: "stayHome"
                },
                {
                    key: "title",
                    type: contentsTypes.EDITABLE_INPUT,
                    value: "je recherche de l’accompagnement pour rester à mon domicile"
                }
            ]
        },
        {
            key: "presentationTitle",
            type: contentsTypes.EDITABLE_INPUT,
            value: "Présentation du site ARPAVIE"
        },
        {
            key: "presentationVideo",
            type: contentsTypes.VIDEO,
            value: ""
        },
        {
            key: "presentation",
            type: contentsTypes.TEXT,
            value: ""
        },
        {
            key: "speechCard",
            type: customTypes.SPEECH_CARD,
            value: "",
            children: [
                {
                    key: "title",
                    type: contentsTypes.EDITABLE_INPUT,
                    value: "Mot de la présidente"
                },
                {
                    key: "speech",
                    type: contentsTypes.TEXT,
                    value: ""
                },
                {
                    key: "photo",
                    type: contentsTypes.IMAGE,
                    value: ""
                },
                {
                    key: "name",
                    type: contentsTypes.EDITABLE_INPUT,
                    value: ""
                },
                {
                    key: "position",
                    type: contentsTypes.EDITABLE_INPUT,
                    value: ""
                }
            ]
        },
        {
            key: "testimony",
            type: customTypes.PRESENTATION_BOX,
            value: "",
            children: [
                {
                    key: "backgroundColor",
                    type: contentsTypes.COLOR,
                    value: "#F7F7F7"
                },
                {
                    label: "Couleur de titre",
                    key: "titleColor",
                    type: contentsTypes.COLOR,
                    value: "#26348B"
                },
                {
                    label: "Couleur de description",
                    key: "descriptionColor",
                    type: contentsTypes.COLOR,
                    value: "rgba(0, 0, 0, 0.87)"
                },
                {
                    key: "image",
                    type: contentsTypes.IMAGE,
                    value: ""
                },
                {
                    key: "title",
                    type: contentsTypes.EDITABLE_INPUT,
                    value: "Témoignages"
                },
                {
                    key: "dynamicPartBeforeDescription",
                    type: contentsTypes.DYNAMIC,
                    value: ""
                },
                {
                    key: "description",
                    type: contentsTypes.TEXT,
                    value: ""
                },
                {
                    key: "dynamicPartAfterDescription",
                    type: contentsTypes.DYNAMIC,
                    value: ""
                },
                {
                    key: "descriptionLarge",
                    type: contentsTypes.CHECKBOX,
                    value: "true"
                },
                {
                    key: "buttonComponent",
                    type: contentsTypes.BUTTON,
                    value: "",
                    children: [
                        {
                            key: "text",
                            type: contentsTypes.INPUT,
                            value: "Consulter la fiche de la résidence"
                        },
                        {
                            key: "variant",
                            type: contentsTypes.RADIO_GROUP,
                            value: "outlined"
                        },
                        {
                            key: "color",
                            type: contentsTypes.RADIO_GROUP,
                            value: "primary"
                        }
                    ]
                }
            ]
        },
        {
            key: "servicesTitle",
            type: contentsTypes.EDITABLE_INPUT,
            value: "Des services à la personne multiples et adaptés"
        },
        {
            key: "servicesSubtitle",
            type: contentsTypes.EDITABLE_INPUT,
            value: "Des services variés pour vous accompagner dans tous les aspects de votre vie quotidienne"
        },
        {
            key: "firstBlockFeatures",
            type: customTypes.FEATURES,
            value: "",
            children: [
                {
                    key: "category",
                    type: contentsTypes.SELECT,
                    value: "everyday life"
                },
                {
                    key: "included",
                    type: customTypes.FEATURES_LIST,
                    value: "",
                    children: []
                },
                {
                    key: "available",
                    type: customTypes.FEATURES_LIST,
                    value: "",
                    children: []
                },
                {
                    key: "buttonComponent",
                    type: contentsTypes.BUTTON,
                    value: "",
                    children: [
                        {
                            key: "text",
                            type: contentsTypes.INPUT,
                            value: "Voir tous les services"
                        },
                        {
                            key: "variant",
                            type: contentsTypes.RADIO_GROUP,
                            value: "outlined"
                        },
                        {
                            key: "color",
                            type: contentsTypes.RADIO_GROUP,
                            value: "primary"
                        },
                        {
                            key: "link",
                            type: contentsTypes.LINK,
                            value: "",
                            children: [
                                {
                                    key: "url",
                                    type: contentsTypes.URL,
                                    value: ""
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            key: "secondBlockFeatures",
            type: customTypes.FEATURES,
            value: "",
            children: [
                {
                    key: "category",
                    type: contentsTypes.SELECT,
                    value: "well being"
                },
                {
                    key: "included",
                    type: customTypes.FEATURES_LIST,
                    value: "",
                    children: []
                },
                {
                    key: "available",
                    type: customTypes.FEATURES_LIST,
                    value: "",
                    children: []
                },
                {
                    key: "buttonComponent",
                    type: contentsTypes.BUTTON,
                    value: "",
                    children: [
                        {
                            key: "text",
                            type: contentsTypes.INPUT,
                            value: "Voir tous les services"
                        },
                        {
                            key: "variant",
                            type: contentsTypes.RADIO_GROUP,
                            value: "outlined"
                        },
                        {
                            key: "color",
                            type: contentsTypes.RADIO_GROUP,
                            value: "primary"
                        },
                        {
                            key: "link",
                            type: contentsTypes.LINK,
                            value: "",
                            children: [
                                {
                                    key: "url",
                                    type: contentsTypes.URL,
                                    value: ""
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            key: "thirdBlockFeatures",
            type: customTypes.FEATURES,
            value: "",
            children: [
                {
                    key: "category",
                    type: contentsTypes.SELECT,
                    value: "activities"
                },
                {
                    key: "included",
                    type: customTypes.FEATURES_LIST,
                    value: "",
                    children: []
                },
                {
                    key: "available",
                    type: customTypes.FEATURES_LIST,
                    value: "",
                    children: []
                },
                {
                    key: "buttonComponent",
                    type: contentsTypes.BUTTON,
                    value: "",
                    children: [
                        {
                            key: "text",
                            type: contentsTypes.INPUT,
                            value: "Voir tous les services"
                        },
                        {
                            key: "variant",
                            type: contentsTypes.RADIO_GROUP,
                            value: "outlined"
                        },
                        {
                            key: "color",
                            type: contentsTypes.RADIO_GROUP,
                            value: "primary"
                        },
                        {
                            key: "link",
                            type: contentsTypes.LINK,
                            value: "",
                            children: [
                                {
                                    key: "url",
                                    type: contentsTypes.URL,
                                    value: ""
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            key: "forthBlockFeatures",
            type: customTypes.FEATURES,
            value: "",
            children: [
                {
                    key: "category",
                    type: contentsTypes.SELECT,
                    value: "therapeutic activities"
                },
                {
                    key: "included",
                    type: customTypes.FEATURES_LIST,
                    value: "",
                    children: []
                },
                {
                    key: "available",
                    type: customTypes.FEATURES_LIST,
                    value: "",
                    children: []
                },
                {
                    key: "buttonComponent",
                    type: contentsTypes.BUTTON,
                    value: "",
                    children: [
                        {
                            key: "text",
                            type: contentsTypes.INPUT,
                            value: "Voir tous les services"
                        },
                        {
                            key: "variant",
                            type: contentsTypes.RADIO_GROUP,
                            value: "outlined"
                        },
                        {
                            key: "color",
                            type: contentsTypes.RADIO_GROUP,
                            value: "primary"
                        },
                        {
                            key: "link",
                            type: contentsTypes.LINK,
                            value: "",
                            children: [
                                {
                                    key: "url",
                                    type: contentsTypes.URL,
                                    value: ""
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            key: "customTabs",
            type: customTypes.CUSTOM_TABS,
            value: "",
            children: [
                {
                    key: "title",
                    type: contentsTypes.EDITABLE_INPUT,
                    value: "Services innovants"
                },
                {
                    key: "backgroundColor",
                    type: contentsTypes.COLOR,
                    value: "#E9F7F7"
                },
                {
                    key: "tabsContent",
                    type: contentsTypes.TABS,
                    value: "",
                    children: [
                        {
                            key: "tabs",
                            type: contentsTypes.TAB,
                            value: "",
                            children: [
                                {
                                    key: "title",
                                    type: contentsTypes.INPUT,
                                    value: ""
                                },
                                {
                                    key: "children",
                                    type: contentsTypes.DYNAMIC,
                                    value: ""
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            key: "groupPresentation",
            type: customTypes.INFO_BLOCK,
            value: "",
            children: [
                {
                    key: "firstTitle",
                    type: contentsTypes.EDITABLE_INPUT,
                    value: "ARPAVIE, Notre philosophie"
                },
                {
                    key: "firstSubTitle",
                    type: contentsTypes.EDITABLE_INPUT,
                    value: ""
                },
                {
                    key: "firstDynamicPart",
                    type: contentsTypes.DYNAMIC,
                    value: ""
                },
                {
                    key: "firstPartBackgroundColor",
                    type: contentsTypes.COLOR,
                    value: "#EEEEEE"
                },
                {
                    key: "secondTitle",
                    type: contentsTypes.EDITABLE_INPUT,
                    value: "Actualité du groupe"
                },
                {
                    key: "secondSubTitle",
                    type: contentsTypes.EDITABLE_INPUT,
                    value: ""
                },
                {
                    key: "secondDynamicPart",
                    type: contentsTypes.DYNAMIC,
                    value: ""
                },
                {
                    key: "secondPartBackgroundColor",
                    type: contentsTypes.COLOR,
                    value: "#26348B"
                }
            ]
        },
        {
            key: "establishmentsTitle",
            type: contentsTypes.EDITABLE_INPUT,
            value: "Découvrez nos établissements"
        },
        {
            key: "establishmentsSubtitle",
            type: contentsTypes.EDITABLE_INPUT,
            value: "Quelques-unes de nos résidences, maisons de retraite, EPHAD, résidence autonomie, accueil de jour..."
        },
        {
            key: "financialHelp",
            type: customTypes.PRESENTATION_BOX,
            value: "",
            children: [
                {
                    key: "backgroundColor",
                    type: contentsTypes.COLOR,
                    value: "#F4F5FF"
                },
                {
                    label: "Couleur de titre",
                    key: "titleColor",
                    type: contentsTypes.COLOR,
                    value: "#26348B"
                },
                {
                    label: "Couleur de description",
                    key: "descriptionColor",
                    type: contentsTypes.COLOR,
                    value: "rgba(0, 0, 0, 0.87)"
                },
                {
                    key: "image",
                    type: contentsTypes.IMAGE,
                    value: ""
                },
                {
                    key: "title",
                    type: contentsTypes.EDITABLE_INPUT,
                    value: "Des aides financières"
                },
                {
                    key: "dynamicPartBeforeDescription",
                    type: contentsTypes.DYNAMIC,
                    value: ""
                },
                {
                    key: "description",
                    type: contentsTypes.TEXT,
                    value: ""
                },
                {
                    key: "dynamicPartAfterDescription",
                    type: contentsTypes.DYNAMIC,
                    value: ""
                },
                {
                    key: "descriptionLarge",
                    type: contentsTypes.CHECKBOX,
                    value: "false"
                },
                {
                    key: "buttonComponent",
                    type: contentsTypes.BUTTON,
                    value: "",
                    children: [
                        {
                            key: "text",
                            type: contentsTypes.INPUT,
                            value: "Consulter toutes les aides"
                        },
                        {
                            key: "variant",
                            type: contentsTypes.RADIO_GROUP,
                            value: "outlined"
                        },
                        {
                            key: "color",
                            type: contentsTypes.RADIO_GROUP,
                            value: "primary"
                        }
                    ]
                }
            ]
        },
        {
            key: "googleMapEstablishmentsTitle",
            type: contentsTypes.EDITABLE_INPUT,
            value: "Toutes nos résidences Arpavie"
        }
    ]
};
