var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import axios from "axios";
import { useQuery } from "react-query";
export var entityName = "establishmentsResults";
export var useSearchEstablishments = function (geoloc, options) {
    if (options === void 0) { options = {}; }
    var lat = geoloc.lat, lng = geoloc.lng, radius = geoloc.radius;
    var _a = options.enabled, enabled = _a === void 0 ? true : _a, otherOptions = __rest(options, ["enabled"]);
    return useQuery([entityName, geoloc], function () {
        return axios
            .get("/geoloc-establishments", {
            params: geoloc
        })
            .then(function (r) { var _a, _b; return (_b = (_a = r.data) === null || _a === void 0 ? void 0 : _a.hits) === null || _b === void 0 ? void 0 : _b.hits.map(function (h) { return h._source; }); });
    }, __assign(__assign({ 
        // staleTime: 1000 * 60 * 15,
        keepPreviousData: true }, otherOptions), { enabled: Boolean(lat && lng && radius) && enabled }));
};
