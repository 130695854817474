var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FeaturesBlock from "components/FeaturesBlock";
import { FeatureCategory } from "types/feature/feature-category.enum";
import { Grid } from "@mui/material";
var Features = function (_a) {
    var _b;
    var _c = _a.id, id = _c === void 0 ? "" : _c, _d = _a.category, category = _d === void 0 ? FeatureCategory.EVERYDAY_LIFE : _d, _e = _a.included, included = _e === void 0 ? null : _e, _f = _a.available, available = _f === void 0 ? null : _f, _g = _a.buttonComponent, buttonComponent = _g === void 0 ? null : _g, others = __rest(_a, ["id", "category", "included", "available", "buttonComponent"]);
    var includedFeatures = [];
    var availableFeatures = [];
    if (included) {
        includedFeatures = Array.isArray(included.feature) ? included.feature : [included.feature];
    }
    if (available) {
        availableFeatures = Array.isArray(available.feature) ? available.feature : [available.feature];
    }
    // @ts-ignore
    var _h = (buttonComponent || {}).props, buttonProps = _h === void 0 ? {} : _h;
    return (_jsxs(Grid, __assign({ container: true, direction: "column", sx: { height: "100%" }, justifyContent: "space-between" }, { children: [_jsx(Grid, __assign({ item: true }, { children: _jsx(FeaturesBlock, { category: category, includedFeatures: includedFeatures, complementaryFeatures: availableFeatures, complementaryItemProps: {
                        borderStyle: "dashed",
                        borderWidth: "1px"
                    } }, void 0) }), void 0), ((buttonProps === null || buttonProps === void 0 ? void 0 : buttonProps.url) !== "" || ((buttonProps === null || buttonProps === void 0 ? void 0 : buttonProps.page) !== "" && ((_b = buttonProps === null || buttonProps === void 0 ? void 0 : buttonProps.page) === null || _b === void 0 ? void 0 : _b.path) !== "")) && (_jsx(Grid, __assign({ sx: { pt: 2 } }, { children: buttonComponent }), void 0))] }), void 0));
};
export default Features;
