var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
var DynamicTemplate = function (_a) {
    var page = _a.page;
    var title = page.title, contents = page.contents;
    var dynamicPart = contents.dynamicPart;
    return (_jsxs(Box, __assign({ p: { xs: 2, md: 4 } }, { children: [_jsx(Typography, __assign({ variant: "h1", align: "center" }, { children: title }), void 0), dynamicPart] }), void 0));
};
export default DynamicTemplate;
