var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SvgIcon } from "@mui/material";
var CTANavigationLeftIcon = function (props) {
    return (_jsxs(SvgIcon, __assign({ viewBox: "0 0 36 36" }, props, { children: [_jsx("path", { d: "M0 18C0 8.05888 8.05888 0 18 0C27.9411 0 36 8.05888 36 18C36 27.9411 27.9411 36 18 36C8.05888 36 0 27.9411 0 18Z", fill: "currentColor" }, void 0), _jsx("path", { d: "M20.5 12.625L14.875 18.25L20.5 23.875V12.625Z", fill: "white" }, void 0)] }), void 0));
};
export default CTANavigationLeftIcon;
