var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/system/Box";
import Navigation from "Layout/Navigation";
import Footer from "Layout/Footer";
import CookiesBanner from "Layout/CookiesBanner";
import { CookiesContextProvider } from "components/CookiesContext";
import Breadcrumb from "Layout/Breadcrumb";
import { MatomoContextProvider } from "cms/components/MatomoContextProvider";
var Layout = function (props) {
    var children = props.children, bo = props.bo;
    var _a = useState(), scrollableElement = _a[0], setScrollableElement = _a[1];
    var scrollableElementRef = useRef();
    useEffect(function () {
        if (scrollableElementRef.current) {
            setScrollableElement(scrollableElementRef.current);
        }
    }, [scrollableElementRef]);
    return (_jsx(MatomoContextProvider, __assign({ disabled: bo }, { children: _jsxs(CookiesContextProvider, { children: [_jsx(CssBaseline, {}, void 0), _jsxs(Box, __assign({ sx: {
                        fontFamily: "Montserrat",
                        height: "100%",
                        bgcolor: "#FFFFFF",
                        display: "flex",
                        flex: "1 1 auto",
                        flexDirection: "column"
                    } }, { children: [_jsx(Navigation, { scrollableElement: scrollableElement, bo: bo }, void 0), _jsx(Box, __assign({ sx: { flex: "1 0 auto", position: "relative" } }, { children: _jsxs(Box, __assign({ ref: scrollableElementRef, sx: {
                                    display: "flex",
                                    flexDirection: "column",
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0,
                                    overflow: "auto"
                                } }, { children: [_jsxs(Container, __assign({ disableGutters: true, sx: { flex: "1 0 auto", pt: { xs: 2, lg: 4.5 } } }, { children: [_jsx(Breadcrumb, {}, void 0), children] }), void 0), _jsx(Footer, {}, void 0)] }), void 0) }), void 0)] }), void 0), _jsx(CookiesBanner, { bo: bo }, void 0)] }, void 0) }), void 0));
};
export default Layout;
