var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
var Modal = function (_a) {
    var title = _a.title, open = _a.open, content = _a.content, actions = _a.actions, closeModal = _a.closeModal, maxWidth = _a.maxWidth, variant = _a.variant;
    return (_jsxs(Dialog, __assign({ onClose: closeModal, open: open, maxWidth: maxWidth || "md" }, { children: [_jsx(Typography, __assign({ variant: variant, component: DialogTitle }, { children: title }), void 0), _jsx(DialogContent, { children: content }, void 0), actions && _jsx(DialogActions, { children: actions }, void 0), _jsx(CloseIcon, { sx: { color: "colors.cyan_fonce", cursor: "pointer", position: "absolute", top: 12, right: 12 }, onClick: closeModal }, void 0)] }), void 0));
};
export default Modal;
