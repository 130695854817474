var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { FormControlLabel, Grid, MenuItem, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import getEnvironmentPromise from "cms/back-office/services/getEnvironment";
export var summaryTitle = "Lieu de résidence";
var SearchRadius = function (props) {
    var form = props.form, setForm = props.setForm, closePopper = props.closePopper, _a = props.nav, nav = _a === void 0 ? false : _a;
    var _b = React.useState(["5", "15", "25", "50"]), radiusOptions = _b[0], setRadiusOptions = _b[1];
    React.useEffect(function () {
        getEnvironmentPromise().then(function (_a) {
            var search_geoloc_radius = _a.search_geoloc_radius;
            if (search_geoloc_radius) {
                setRadiusOptions(search_geoloc_radius.split(","));
            }
        });
    }, []);
    var handleChangeRadius = function (e) {
        var value = e.target.value;
        setForm(__assign(__assign({}, form), { radius: value }));
        closePopper && closePopper();
    };
    return (_jsx(Grid, __assign({ item: true, container: true, alignItems: "flex-end" }, { children: nav ? (_jsx(RadioGroup, __assign({ name: "radius", value: form === null || form === void 0 ? void 0 : form.radius, onChange: handleChangeRadius }, { children: radiusOptions.map(function (rad) { return (_jsx(FormControlLabel, { value: rad, control: _jsx(Radio, {}, void 0), label: _jsxs(Typography, __assign({ variant: "donnee" }, { children: [rad, " km"] }), void 0) }, rad)); }) }), void 0)) : (_jsxs(_Fragment, { children: [_jsx(TextField, __assign({ id: "rayon", label: "Rayon", variant: "standard", select: true, value: form === null || form === void 0 ? void 0 : form.radius, onChange: handleChangeRadius, sx: { width: "95px", pr: "20px" } }, { children: radiusOptions.map(function (rad) { return (_jsx(MenuItem, __assign({ value: rad }, { children: rad }), rad)); }) }), void 0), _jsx(Typography, { children: "kms" }, void 0)] }, void 0)) }), void 0));
};
export default SearchRadius;
