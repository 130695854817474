import React, { useContext } from "react";
import { Box, Container, Grid, Divider, Typography } from "@mui/material";
import { useQuery } from "react-query";
import generateTemplatePropsFromContents from "cms/utils/templatePropsUtils";
import Link from "cms/editableComponents/Link";
import SectionService from "cms/services/SectionService";
import SiteContext from "cms/components/SiteContext";
import CookiesContext from "components/CookiesContext";
import Social from "Layout/SocialFooter";

const Footer = () => {
  const site = useContext(SiteContext);
  const { openCookiesModal, setOpenCookiesModal, handleCookiesModal } = useContext(CookiesContext);
  const { data: footer } = useQuery("footer", () => SectionService.getFooter(site.id));

  const footerContents = (footer && generateTemplatePropsFromContents(footer.contents)) || {};
  const linkInternals = Object.keys(footerContents);
  const { settings: siteSettings } = site || {};
  let { copyright = null, logoFooter, socialNetworks = [] } = siteSettings || {};

  if (socialNetworks && !Array.isArray(socialNetworks)) {
    socialNetworks = [socialNetworks];
  }

  const onKeyPressShowCookiesDetails = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      setOpenCookiesModal(true);
    }
  };

  const LinkList =
    linkInternals.map((linkInternal, key) => (
      <Grid
        xs={12}
        sm={6}
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          "& a": {
            color: "colors.blanc",
            fontWeight: 700,
            marginBottom: 1,
            lineHeight: "1.429rem",
            "&:hover": {
              color: "colors.blanc"
            },
            "&:active": {
              color: "colors.blanc"
            }
          }
        }}
        key={linkInternal}
      >
        {[0, 1].includes(key) && (
          <Typography
            sx={{
              fontFamily: "Forum",
              fontSize: "2.571rem",
              lineHeight: "2.857rem",
              color: "colors.blanc",
              marginBottom: 1.12
            }}
          >
            {key === 0 ? "Arpavie" : "Le Groupe"}
          </Typography>
        )}
        {key === 0 && (
          <Link tabIndex={0} role="button" aria-pressed={openCookiesModal} onKeyDown={onKeyPressShowCookiesDetails}>
            <div style={{ cursor: "pointer" }} onClick={handleCookiesModal}>
              Gestion des cookies
            </div>
          </Link>
        )}
        {(Array.isArray(footerContents[linkInternal])
          ? footerContents[linkInternal]
          : [footerContents[linkInternal]]
        ).map(list1item => (
          <Link {...list1item.link} key={list1item.id}>
            {list1item.title}
          </Link>
        ))}
      </Grid>
    )) || null;

  return (
    <footer>
      <Box
        sx={{
          bgcolor: "colors.gris_2",
          width: "100%",
          mt: 6.25,
          px: { xs: 4, lg: 11 },
          pt: { xs: 4, lg: 6 },
          pb: { xs: 4, lg: 2.5 },
          textAlign: { xs: "center", md: "left" }
        }}
      >
        <Container disableGutters>
          <Grid container spacing={4} justifyContent="space-between">
            <Grid item xs={12} md={4}>
              {linkInternals.length > 0 && (
                <Grid
                  container
                  spacing={2}
                  sx={{
                    justifyContent: { xs: "center", sm: "left" }
                  }}
                >
                  {LinkList}
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={4} style={{ height: "150px", textAlign: "center" }}>
              {logoFooter && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    "& > * > img": {
                      objectFit: "contain"
                    }
                  }}
                >
                  {logoFooter}
                </Box>
              )}
            </Grid>
            {Array.isArray(socialNetworks) && socialNetworks.length > 0 && (
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  display: { sm: "flex" },
                  justifyContent: { sm: "center", md: "flex-end" }
                }}
              >
                <Box
                  sx={{
                    maxWidth: { sm: "75%", md: 254 }
                  }}
                >
                  <Typography component="div" variant="body1" sx={{ color: "colors.blanc" }}>
                    Pour suivre nos dernières actualités, échanger ou discuter avec nous, nous poster un mot :
                    retrouvez-nous sur nos réseaux !
                  </Typography>
                  <Social socialNetworks={socialNetworks} />
                </Box>
              </Grid>
            )}
          </Grid>
          {copyright && (
            <>
              <Divider
                sx={{
                  bgcolor: "colors.blanc",
                  mt: 2.5,
                  mb: 2.1,
                  borderWidth: 1
                }}
              />
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  fontStyle: "italic",
                  lineHeight: "1rem",
                  color: "colors.blanc"
                }}
              >
                {copyright}
              </Typography>
            </>
          )}
        </Container>
      </Box>
    </footer>
  );
};

export default Footer;
