import React from "react";
import customTypes from "customComponents/customTypes";
// import contentsTypes from "cms/enums/contentsTypes";
import CustomCard from "customComponents/editableComponents/CustomCard";
import PresentationBox from "customComponents/editableComponents/PresentationBox";
import SpeechCard from "customComponents/editableComponents/SpeechCard";
import InfoBlock from "customComponents/editableComponents/InfoBlock";
import CustomTabs from "customComponents/editableComponents/CustomTabs";
import PathOption from "projects/ARPAVIE/customComponents/editableComponents/PathOption";
import Mailto from "projects/ARPAVIE/customComponents/editableComponents/Mailto";
import ServiceDescription from "projects/ARPAVIE/customComponents/editableComponents/ServiceDescription";
import Features from "projects/ARPAVIE/customComponents/editableComponents/Features";

// eslint-disable-next-line react/prop-types
const getDynamicComponent = ({ type, value, id, index }) => {
  switch (type) {
    case customTypes.CUSTOM_CARD:
      return <CustomCard {...value} key={id} />;
    case customTypes.CUSTOM_TABS:
      return <CustomTabs {...value} key={id} />;
    case customTypes.PRESENTATION_BOX:
      return <PresentationBox {...value} key={id} />;
    case customTypes.INFO_BLOCK:
      return <InfoBlock {...value} key={id} />;
    case customTypes.SPEECH_CARD:
      return <SpeechCard {...value} key={id} />;
    case customTypes.PATH_OPTIONS:
      return value && value.pathOptions;
    case customTypes.PATH_OPTION:
      return <PathOption {...value} key={id} />;
    case customTypes.MAILTO:
      return <Mailto {...value} key={id} />;
    case customTypes.SERVICE_DESCRIPTION:
      return <ServiceDescription {...value} key={id} />;
    case customTypes.FEATURES:
      return <Features {...value} key={id} />;
    case customTypes.FEATURES_LIST:
      return value;
    case customTypes.FEATURE:
      return value;
    default:
      return null;
  }
};

export default getDynamicComponent;
