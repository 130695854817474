import React from "react";
import PropTypes from "prop-types";
import makeOverridableStyles from "cms/utils/makeOverridableStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";

const useImageStyles = makeOverridableStyles("Container", {
  container: props => ({
    width: `${props.width}%`
  })
});

const Container = props => {
  const { children, justifyContent, width, mobileWidth } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const classes = useImageStyles({ width: isMobile ? mobileWidth : width });

  return (
    <Box display="flex" flexDirection="row" justifyContent={justifyContent}>
      <div className={classes.container}>{children}</div>
    </Box>
  );
};

Container.propTypes = {
  children: PropTypes.node,
  width: PropTypes.string,
  mobileWidth: PropTypes.string,
  justifyContent: PropTypes.string.isRequired
};

Container.defaultProps = {
  children: null,
  width: null,
  mobileWidth: null
};

export default Container;
