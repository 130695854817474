var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { useMutation, useQuery } from "react-query";
import { GenericService } from "services/generic.service";
export var entityName = "features";
var FeatureService = /** @class */ (function (_super) {
    __extends(FeatureService, _super);
    function FeatureService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return FeatureService;
}(GenericService));
var service = new FeatureService(entityName);
export var useFeatureList = function (params, options) {
    if (params === void 0) { params = {}; }
    if (options === void 0) { options = {}; }
    return useQuery([entityName], function () { return service.findMany(params); }, options);
};
export var useFeatureCreate = function (options) {
    if (options === void 0) { options = {}; }
    return useMutation(function (payload) { return service.create(payload); }, options);
};
export var useFeatureUpdate = function (config) {
    if (config === void 0) { config = {}; }
    return useMutation(function (feature) { return service.update(feature); }, config);
};
export var useFeatureDelete = function (config) {
    if (config === void 0) { config = {}; }
    return useMutation(function (feature) { return service.delete(feature.id); }, config);
};
