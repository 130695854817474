import { FeatureCategory } from "types/feature/feature-category.enum";
export var getFeatureCategoryLabel = function (featureCategory) {
    switch (featureCategory) {
        case FeatureCategory.EVERYDAY_LIFE:
            return "Vie quotidienne";
        case FeatureCategory.ACTIVITIES:
            return "Animations";
        case FeatureCategory.WELL_BEING:
            return "Santé et bien être";
        case FeatureCategory.THERAPEUTIC_ACTIVITIES:
            return "Activités thérapeutiques";
        case FeatureCategory.ENVIRONMENT:
            return "Environnement";
        case FeatureCategory.MOST_HEALTH:
            return "Les + santé";
        case FeatureCategory.MOST_RESIDENCE:
            return "Les + de la résidence";
        default:
            return "";
    }
};
