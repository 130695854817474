import { Box } from "@mui/material";
import { styled } from "@mui/system";
export default styled(Box, {
    shouldForwardProp: function (prop) { return prop !== "limit"; }
})(function (_a) {
    var _b = _a.limit, limit = _b === void 0 ? 2 : _b;
    return ({
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: limit,
        WebkitBoxOrient: "vertical"
    });
});
