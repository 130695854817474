var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EstablishmentType } from "types/establishment/establishment-type.enum";
import { getValueAsArray } from "cms/utils/ArrayUtils";
export var getEstablishmentTypeFromSearchParams = function (_a) {
    var stayHome = _a.stayHome, medicalSupport = _a.medicalSupport, needAssist = _a.needAssist;
    if (needAssist)
        return EstablishmentType.RA;
    if (medicalSupport)
        return EstablishmentType.EHPAD;
    return;
};
export var getEstablishmentSearchParamsFromQueries = function (queryParams) {
    return {
        availability: queryParams.availability || "",
        medicalSupport: queryParams.medicalSupport === "1" ? true : false,
        advancedAvailability: queryParams.advancedAvailability || "",
        needAssist: queryParams.needAssist === "1" ? true : false,
        stayHome: queryParams.stayHome === "1" ? true : false,
        city: queryParams.city || "",
        lat: queryParams.lat || "",
        lng: queryParams.lng || "",
        radius: queryParams.radius || "15",
        budgetMin: queryParams.budgetMin || "0",
        budgetMax: queryParams.budgetMax || "0",
        establishmentPrices: getValueAsArray(queryParams.establishmentPrices),
        specificTypes: getValueAsArray(queryParams.specificTypes),
        proximityElements: getValueAsArray(queryParams.proximityElements),
        PMR: queryParams.PMR
    };
};
export var getEstablishmentFromEstablishmentResult = function (establishmentResult) {
    var address = establishmentResult.address, cmsPageId = establishmentResult.cmsPageId, id = establishmentResult.id, name = establishmentResult.name, prices = establishmentResult.prices, type = establishmentResult.type, location = establishmentResult.location, isAvailable = establishmentResult.isAvailable, availablesFinancialAids = establishmentResult.availablesFinancialAids;
    var _a = location.split(","), lat = _a[0], lng = _a[1];
    return {
        address: __assign({}, address),
        latitude: lat,
        longitude: lng,
        cmsPageId: cmsPageId,
        id: id,
        name: name,
        prices: prices,
        type: type,
        isAvailable: isAvailable,
        availablesFinancialAids: availablesFinancialAids
    };
};
