export const getFilterStyle = (filters = {}) => {
  const { activated, opacity, blur, grayscale, brightness, saturate, sepia } = filters;
  if (!activated) {
    return undefined;
  }

  return `opacity(${opacity}) blur(${blur}px) grayscale(${grayscale}) brightness(${brightness}) saturate(${saturate}) sepia(${sepia})`;
};

export const pageImageFiltersKey = "pageImageFilters";
