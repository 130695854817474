import React from "react";
import PropTypes from "prop-types";
import makeOverridableStyles from "cms/utils/makeOverridableStyles";

const useStyles = makeOverridableStyles("TimelineStep", theme => ({
  step: {
    marginLeft: theme.spacing(1),
    borderLeft: `4px solid ${theme.palette.secondary.dark}`,
    position: "relative",
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(4)
  },
  bubble: {
    position: "absolute",
    left: "-12px",
    top: "-1px",
    display: "block",
    height: "20px",
    width: "20px",
    backgroundColor: theme.palette.secondary.dark,
    color: "white",
    borderRadius: "50%"
  },
  title: {
    "& .MuiTypography-root": {
      fontWeight: theme.typography.fontWeightBold
    },
    paddingBottom: theme.spacing(0.5)
  }
}));

export const TimelineStep = props => {
  const { id, title, content } = props;
  const classes = useStyles();
  return (
    <div className={classes.step} key={id}>
      <span className={classes.bubble} />
      <div className={classes.title}>{title}</div>
      {content}
    </div>
  );
};

TimelineStep.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.node,
  content: PropTypes.node
};

TimelineStep.defaultProps = {
  title: null,
  content: null
};

const Timeline = props => {
  const { steps, ...others } = props;

  return <div {...others}>{steps}</div>;
};

Timeline.propTypes = {
  steps: PropTypes.node
};

Timeline.defaultProps = {
  steps: null
};

export default Timeline;
