import axios from "axios";
var ContactService = /** @class */ (function () {
    function ContactService() {
    }
    ContactService.postMail = function (sendForm) {
        return axios
            .post("/mail", sendForm)
            .then(function (response) {
            return response.data;
        })
            .catch(function (err) {
            var _a, _b, _c;
            return {
                status: (_a = err.response) === null || _a === void 0 ? void 0 : _a.status,
                message: (_c = (_b = err.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.error
            };
        });
    };
    return ContactService;
}());
export default ContactService;
