export const HOOKS = {
  /* ************************ */
  /* ----- FRONT HOOKS ----- */
  /* ************************ */
  /**
   * Description : Sets project Layout component
   * Type: React.Component
   */
  App_Layout: "App_Layout",
  /**
   * Description : Sets project Layout
   * Type: Array of Route props (react-router-dom)
   */
  App_routes: "App_routes",
  /**
   * Description : Sets project 404 component
   * Type: React.Component
   */
  PageSwitcher_404: "PageSwitcher_404",
  /**
   * Description : Used to render project custom contentTypes
   * Type: function({type,value,id,index}) => React.node (element|string|number...)
   */
  templatePropsUtils_getDynamicComponent: "templatePropsUtils_getDynamicComponent",
  /**
   * Description : Liste of project templates
   * Type: Array of Object
   * {
   *    key: template key (String)
   *    label: Displayed name in admin panel (String)
   *    Component: Template component (React.Component)
   *    initialContents: Initial template contents, use when create a new Page (Array)
   * }
   */
  TEMPLATES: "TEMPLATES",
  /**
   * Description : Project theme
   * Type: Material-ui theme
   */
  THEME: "THEME",
  /**
   * Description : Allow you to set distinct site themes, as priority on THEME hook
   * Type: function({site}) => Material-ui theme
   */
  GET_THEME: "GET_THEME",

  /* ************************ */
  /* ----- SERVER HOOKS ----- */
  /* ************************ */
  /**
   * Description : Allow you to add data into <head>
   * Type: String | (function({nonce,site}) => String)
   */
  Html_head: "Html_head",
  /**
   * Description : Allow you to add data into <body>
   * Type: String | (function({nonce,site}) => String)
   */
  Html_body: "Html_body",
  /**
   * Description : Allow you to add custom node routes
   * Type: function(app)
   */
  NODE_ROUTES: "NODE_ROUTES",
  /**
   * Description : Allow you to add custom node routes
   * Type: function(app)
   */
  enhanceContentsUtil_transformContent: "enhanceContentsUtil_transformContent",
  /**
   * Description : React-query client global options
   * Type: QueryClientConfig
   */
  clientFront_queryClientOptions: "clientFront_queryClientOptions",

  /* ************************ */
  /* ----- ADMIN HOOKS ----- */
  /* ************************ */
  /**
   * Description : Allow you to add routes to back office
   * Type: React.component
   */
  Bo_Routes: "Bo_Routes",
  /**
   * Description : Path to administrate website (default "/")
   * Type: String
   */
  Bo_AdminWebsitePath: "Bo_AdminWebsitePath",
  /**
   * Description : Path to administrate users (default "/users")
   * Type: String
   */
  Bo_AdminUsersPath: "Bo_AdminUsersPath",
  /**
   * Description : Project custom types definition
   * Type: Array of objects(see adminContentsUtils file)
   */
  adminContentsUtils_contents: "adminContentsUtils_contents",
  /**
   * Description : Used to render project custom contentTypes administration component
   * Type: function({content,value,index,handleValueChange,handleContentChange}) => React.node
   */
  templateAdminUtils_getEditorComponent: "templateAdminUtils_getEditorComponent",
  /**
   * Description :
   * Type: function({type}) => Array of contentTypes (String)
   */
  templateAdminUtils_getDynamicAdminContentsTypes: "templateAdminUtils_getDynamicAdminContentsTypes",
  /**
   * Description : List of template not available when creates a new Page
   * Type: Array of Templates (see TEMPLATES hook)
   */
  TEMPLATES_excludedTemplatesForPageCreation: "TEMPLATES_excludedTemplatesForPageCreation",
  /**
   * Description : List of not editable templates
   * Type: Array of Templates (see TEMPLATES hook)
   */
  TEMPLATES_excludedTemplatesForPageUpdate: "TEMPLATES_excludedTemplatesForPageUpdate",
  /**
   * Description : List of not deletable templates
   * Type: Array of Templates (see TEMPLATES hook)
   */
  TEMPLATES_excludedTemplatesForPageDelete: "TEMPLATES_excludedTemplatesForPageDelete",
  /**
   * Description : Used to render project custom contentTypes administration from component when in a modal
   * Type: function(type) => React.Component
   */
  ContentForm_getFormComponent: "ContentForm_getFormComponent",
  /**
   * Description : Used to redirect specific
   * Type: object (oldUrl : newUrl)
   */
  Redirect_301: "Redirect_301",
  /**
   * Description : Used to prefetch react query data
   * Type: function({queryClient,url}) => Promise
   */
  SSR_Prefetch: "SSR_Prefetch",
  /**
   * Description : List of forbidden pathes for a page
   * Type: Array of string
   */
  AdminPageForm_ProtectedPaths: "AdminPageForm_ProtectedPaths",
  /**
   * Description : React-query client global options
   * Type: QueryClientConfig
   */
  clientAdmin_queryClientOptions: "clientAdmin_queryClientOptions"
};

const hooks = {
  Bo_AdminWebsitePath: "/",
  Bo_AdminUsersPath: "/users"
};

export default {
  registerHook: (key, value) => {
    hooks[key] = value;
  },
  getHook: key => {
    return hooks[key];
  }
};
