var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import Icon from "cms/editableComponents/Icon";
import Tag from "components/Tag";
import { getEstablishmentTypeLabel, getEstablishmentIcon } from "utils/establishmentUtils";
import { EstablishmentFinancialAid } from "types/establishment/establishment-financial-aid.enum";
var EstablishmentTags = function (_a) {
    var establishment = _a.establishment, bgcolor = _a.bgcolor, borderColor = _a.borderColor;
    var type = establishment.type, availablesFinancialAids = establishment.availablesFinancialAids, isAvailable = establishment.isAvailable;
    var sx = {};
    if (bgcolor) {
        sx.bgcolor = bgcolor;
    }
    if (borderColor) {
        sx.borderColor = borderColor;
        sx.color = borderColor;
    }
    return (_jsxs(_Fragment, { children: [type && (_jsx(Box, __assign({ sx: { mb: 1 } }, { children: _jsx(Tag, __assign({ Icon: _jsx(Icon, { icon: getEstablishmentIcon(type) }, void 0), sx: sx }, { children: getEstablishmentTypeLabel(type) }), void 0) }), void 0)), availablesFinancialAids &&
                (availablesFinancialAids.includes(EstablishmentFinancialAid.ASD) ||
                    availablesFinancialAids.includes(EstablishmentFinancialAid.PARTIAL_ASD)) && (_jsx(Box, __assign({ sx: { ml: 2, mb: 1 } }, { children: _jsx(Tag, __assign({ Icon: _jsx(Icon, { icon: "check" }, void 0), sx: sx }, { children: "\u00C9ligible \u00E0 l'aide sociale" }), void 0) }), void 0)), isAvailable && (_jsx(Box, __assign({ sx: { ml: 2, mb: 1 } }, { children: _jsx(Tag, __assign({ Icon: _jsx(Icon, { icon: "user-check" }, void 0), sx: sx }, { children: "Places disponibles" }), void 0) }), void 0))] }, void 0));
};
export default EstablishmentTags;
