import React from "react";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles(() => ({
  loader: {
    "& .MuiCircularProgress-circle": {
      stroke: "#3787FF"
    }
  }
}));

const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.loader}>
      <CircularProgress size={36} />
    </div>
  );
};

export default Loader;
