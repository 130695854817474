var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import axios from "axios";
import { Button, Grid } from "@mui/material";
import { Templates } from "types/templates.enum";
import ContactForm from "components/ContactForm";
import ServicesContact from "components/ServicesContact";
import Modal from "components/Modal";
import SiteContext from "cms/components/SiteContext";
import { SubjectForm } from "types/subject-fom.enum";
var Contact = function (props) {
    var open = props.open, onClose = props.onClose;
    var _a = useState(""), subject = _a[0], setSubject = _a[1];
    var _b = useState(false), formSubmitted = _b[0], setFormSubmitted = _b[1];
    var _c = useState("/"), jobOffersPathname = _c[0], setJobOffersPathname = _c[1];
    var site = useContext(SiteContext);
    useEffect(function () {
        axios
            .get("/findPagesByTemplates", {
            params: {
                templates: Templates.JOB_OFFERS,
                siteId: site === null || site === void 0 ? void 0 : site.id,
                sortBy: "publishedDate",
                sortOrder: "DESC"
            }
        })
            .then(function (response) {
            var _a;
            var data = response.data.data;
            if (data) {
                var newPathname = (_a = data.find(function (elt) { return elt.fullPath; })) === null || _a === void 0 ? void 0 : _a.fullPath;
                if (newPathname) {
                    setJobOffersPathname(newPathname);
                }
            }
        });
    }, [site.id]);
    var handleSubjectChange = function (value) {
        setSubject(value);
    };
    var closeModal = function () {
        onClose();
    };
    var modalContact = function () {
        if (!subject) {
            return (_jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(Button, __assign({ variant: "contained", sx: { width: "100%" }, onClick: function () { return handleSubjectChange(SubjectForm.ESTABLISHMENT); } }, { children: SubjectForm.ESTABLISHMENT }), void 0) }), void 0), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(Button, __assign({ variant: "contained", sx: { width: "100%" }, onClick: function () { return handleSubjectChange(SubjectForm.ARPAVIE_DOM_SERVICES); } }, { children: SubjectForm.HOME_SERVICES }), void 0) }), void 0), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(Button, __assign({ variant: "contained", sx: { width: "100%" }, component: HashLink, to: jobOffersPathname, onClick: closeModal }, { children: SubjectForm.JOB_OFFER }), void 0) }), void 0), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(Button, __assign({ variant: "contained", sx: { width: "100%" }, onClick: function () { return handleSubjectChange(SubjectForm.PARTNERSHIP); } }, { children: SubjectForm.PARTNERSHIP }), void 0) }), void 0)] }), void 0));
        }
        else {
            if (subject !== SubjectForm.ARPAVIE_DOM_SERVICES) {
                return (_jsx(ContactForm, { overrideDefaultForm: { subject: subject }, buttonLabel: "Fermer la fen\u00EAtre", handleButtonAction: closeModal, formSubmitted: formSubmitted, handleFormSubmitted: function (bool) { return setFormSubmitted(bool); }, handleReturn: function () { return handleSubjectChange(""); }, subjectForm: subject }, void 0));
            }
            else {
                return _jsx(ServicesContact, { onClose: onClose, handleReturn: function () { return handleSubjectChange(""); } }, void 0);
            }
        }
    };
    return (_jsx(_Fragment, { children: _jsx(Modal, { title: _jsx(Grid, __assign({ container: true, justifyContent: "center", alignItems: "center" }, { children: subject ? subject : "Votre demande concerne un(e):" }), void 0), variant: subject ? "h3" : "h6", open: open, content: modalContact(), closeModal: closeModal }, void 0) }, void 0));
};
export default Contact;
