import React from "react";
import Timeline, { TimelineStep } from "cms/editableComponents/Timeline";
import Text from "cms/editableComponents/Text";

const steps = new Array(3)
  .fill("")
  .map((p, i) => (
    <TimelineStep
      key={i}
      id={i}
      title={<Text>`Titre ${i + 1}`</Text>}
      content={<Text>{`Zone de texte libre ${i + 1}`}</Text>}
    />
  ));

const TimelinePreview = () => {
  return <Timeline steps={steps} />;
};

export default TimelinePreview;
