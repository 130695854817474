import React from "react";
import PropTypes from "prop-types";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism, okaidia } from "react-syntax-highlighter/dist/esm/styles/prism";
import { decodeCodeBlockText } from "cms/utils/commonUtils";

const CodeBlock = props => {
  const { code, language, isDark, ...others } = props;

  return (
    <div {...others}>
      <SyntaxHighlighter showLineNumbers language={language} style={isDark ? okaidia : prism}>
        {decodeCodeBlockText(code)}
      </SyntaxHighlighter>
    </div>
  );
};

CodeBlock.propTypes = {
  code: PropTypes.string,
  language: PropTypes.string,
  isDark: PropTypes.bool
};

CodeBlock.defaultProps = {
  code: "",
  language: "",
  isDark: true
};

export default CodeBlock;
