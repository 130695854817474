import React, { Fragment, createRef, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import makeOverridableStyles from "cms/utils/makeOverridableStyles";
import classnames from "classnames";
import Typography from "@mui/material/Typography";
import Block from "cms/components/Block";
import Link from "cms/editableComponents/Link";
import wrappable from "cms/utils/wrappable";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeOverridableStyles("Glossary", theme => ({
  letters: {
    display: "flex",
    fontSize: "1.42rem",
    fontWeight: 600,
    position: "sticky",
    background: "#fff",
    top: 0,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down("lg")]: {
      position: "fixed",
      fontSize: "1.2rem",
      fontWeight: 400,
      flexDirection: "column",
      right: "0",
      margin: 0,
      padding: theme.spacing(1, 0),
      backgroundColor: theme.palette.primary.main,
      alignItems: "center",
      top: props => (props.lettersTop ? props.lettersTop : "initial"),
      display: props => (props.lettersTop ? "flex" : "none")
    }
  },
  letterLink: {
    textTransform: "uppercase",
    flex: 1,
    maxWidth: "50px",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.secondary.main
    },
    [theme.breakpoints.down("lg")]: {
      color: theme.palette.common.white,
      padding: theme.spacing(0.25, 0.5)
    },
    [theme.breakpoints.up("md")]: {
      lineHeight: "2rem"
    }
  },
  letterSection: {
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(1, 0, 0, 0)
    }
  },
  stickySafari: {
    [theme.breakpoints.up("md")]: {
      position: " -webkit-sticky"
    }
  },
  content: {
    [theme.breakpoints.down("lg")]: {
      paddingTop: theme.spacing(1)
    }
  },
  letterTitle: {
    color: theme.palette.primary[70],
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    [theme.breakpoints.down("lg")]: {
      paddingBottom: theme.spacing(2)
    },
    "& h2": {
      margin: 0,
      [theme.breakpoints.down("lg")]: {
        padding: theme.spacing(0, 2, 0, 2)
      }
    }
  },
  active: {
    color: theme.palette.secondary[50],
    [theme.breakpoints.up("md")]: {
      fontSize: "2rem"
    }
  },

  word: {
    margin: 0
  },
  description: {
    marginTop: theme.spacing(0.5)
  }
}));

const GlossaryItem = wrappable(props => {
  const { word, description } = props;

  const classes = useStyles();

  return (
    <Block>
      <Typography variant="h3" className={classes.word}>
        {word}
      </Typography>
      <div className={classes.description}>{description}</div>
    </Block>
  );
});

const Glossary = props => {
  const { location } = props;
  let { items } = props;
  const [lettersTop, setLettersTop] = useState(null);
  const [letters, setLetters] = useState([]);
  const { hash } = location;

  if (items && !Array.isArray(items)) {
    items = [items];
  }

  const classes = useStyles({ lettersTop });

  const filteredItems = items.filter(item => item && item.word);

  filteredItems.sort((a, b) => a.word.localeCompare(b.word));

  const groupedItems = {};

  filteredItems.forEach(item => {
    const { word } = item;
    const firstLetter = word.toUpperCase().split("")[0];
    groupedItems[firstLetter] = groupedItems[firstLetter] || [];
    groupedItems[firstLetter].push(item);
  });

  const lettersRef = createRef();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (isMobile) {
      setLettersTop((window.innerHeight - lettersRef.current.getBoundingClientRect().height) / 2);
    }
  }, [lettersRef, isMobile]);

  useEffect(() => {
    // Si trop de lettres, alors on remplace l'excedent par "..." au milieu de la liste
    // qui pointera vers la première lettre retirée
    const allLetters = Object.keys(groupedItems).map(item => ({ letter: item, hash: item }));
    if (isMobile) {
      const maxLetters = 15;
      const nbLetters = Object.keys(groupedItems).length;
      if (nbLetters > maxLetters) {
        const nbSubstract = nbLetters - maxLetters;
        const indexStartSubstract = Math.round(maxLetters / 2);
        allLetters.splice(indexStartSubstract, nbSubstract, {
          letter: "...",
          hash: allLetters[indexStartSubstract].hash
        });
      }
    }
    setLetters(allLetters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, isMobile]);

  return (
    <Fragment>
      <div className={classnames(classes.letters, classes.stickySafari)} ref={lettersRef}>
        {letters.map(item => (
          <Link
            key={item.letter}
            smooth
            className={classnames(classes.letterLink, hash === `#${item.hash}` && classes.active)}
            url={`${location.pathname}#${item.hash}`}
          >
            {item.letter}
          </Link>
        ))}
      </div>
      <div className={classes.content}>
        {Object.keys(groupedItems).map(letter => (
          <Block className={classes.letterSection} id={letter} key={letter}>
            <div className={classes.letterTitle}>
              <Typography variant="h2">{letter}</Typography>
            </div>
            {groupedItems[letter].map(item => (
              <GlossaryItem key={item.id} {...item} />
            ))}
          </Block>
        ))}
      </div>
    </Fragment>
  );
};

Glossary.propTypes = {
  location: PropTypes.shape().isRequired,
  items: PropTypes.arrayOf(PropTypes.shape())
};

Glossary.defaultProps = {
  items: []
};

export default withRouter(Glossary);
