import { EstablishmentType } from "types/establishment/establishment-type.enum";
import { EstablishmentPriceTimeUnit } from "../types/establishment/establishment-price-time-unit.enum";
export var getEstablishmentMarkerInfo = function (establishment) {
    var name = establishment.name, address = establishment.address;
    var _a = address || {}, streetNb = _a.streetNb, street = _a.street, zipCode = _a.zipCode, city = _a.city;
    return "\n      <div style=\"color: #2A4161;\">\n        " + (name ? "<h3>" + name + "</h3>" : "") + "\n        <p>" + (streetNb ? streetNb + " " : "") + (street || "") + "</p>\n        <p>" + (zipCode ? zipCode + " " : "") + (city || "") + "</p>\n      </div>\n    ";
};
export var getEstablishmentIconMarker = function (establishment) {
    var type = establishment.type;
    switch (type) {
        case EstablishmentType.EHPAD:
            return "/EHPAD.png";
        case EstablishmentType.RA:
            return "/RA.png";
        case EstablishmentType.RSS:
            return "/RSS.png";
        default:
            return "/EHPAD.png";
    }
};
export var getEstablishmentTypeLabel = function (establishmentType) {
    switch (establishmentType) {
        case EstablishmentType.EHPAD:
            return "EHPAD";
        case EstablishmentType.RA:
            return "Résidence Autonomie";
        case EstablishmentType.RSS:
            return "Résidence Services Sénior";
        default:
            return "";
    }
};
export var getEstablishmentIcon = function (establishmentType) {
    switch (establishmentType) {
        case EstablishmentType.EHPAD:
            return "hotel";
        case EstablishmentType.RA:
            return "home";
        case EstablishmentType.RSS:
            return "home";
        default:
            return "";
    }
};
export var getPriceTimeUnitLabel = function (priceTimeUnit) {
    switch (priceTimeUnit) {
        case EstablishmentPriceTimeUnit.DAILY:
            return "Par jour";
        case EstablishmentPriceTimeUnit.MONTHLY:
            return "Par mois";
        default:
            return "";
    }
};
