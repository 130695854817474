var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImageContainer } from "cms/editableComponents/Image";
import { Paper, Box, Typography } from "@mui/material";
var ServiceDescription = function (_a) {
    var _b = _a.id, id = _b === void 0 ? "" : _b, _c = _a.image, image = _c === void 0 ? null : _c, _d = _a.title, title = _d === void 0 ? "" : _d, _e = _a.subtitle, subtitle = _e === void 0 ? "" : _e, _f = _a.dynamic, dynamic = _f === void 0 ? "" : _f, others = __rest(_a, ["id", "image", "title", "subtitle", "dynamic"]);
    return (_jsxs(Paper, __assign({ square: true, sx: { borderRadius: "5px 0px 23px 23px", height: "100%" } }, others, { children: [_jsxs(Box, __assign({ sx: {
                    position: "relative",
                    height: { xs: 410, md: 490, xl: 590 },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                } }, { children: [_jsx(Box, __assign({ sx: { position: "absolute", left: 0, top: 0, bottom: 0, right: 0 } }, { children: _jsx(ImageContainer, __assign({ ratio: "" }, { children: image }), void 0) }), void 0), _jsx(Box, __assign({ sx: {
                            background: "rgba(35, 207, 207, 0.72)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            height: 248,
                            width: "65%",
                            minWidth: "min-content",
                            zIndex: 1,
                            py: 9,
                            px: { xs: 2, sm: 4.5 }
                        } }, { children: _jsx(Typography, __assign({ variant: "h3", sx: { color: "rgba(0, 0, 0, 0.87)" } }, { children: title }), void 0) }), void 0)] }), void 0), _jsxs(Box, __assign({ sx: { pt: 2.625, pb: 2.375, px: { xs: 0, sm: 4 }, textAlign: "center" } }, { children: [_jsx(Typography, __assign({ variant: "h5", component: "h4", sx: { xs: 2, sm: 0 } }, { children: subtitle }), void 0), _jsx(Box, __assign({ sx: { "& ul": { textAlign: "left", display: "inline-block", width: { xs: "80%", sm: "60%", md: "auto" } } } }, { children: dynamic }), void 0)] }), void 0)] }), id));
};
export default ServiceDescription;
