export var Templates;
(function (Templates) {
    Templates["ARPAVIE_DOM"] = "ARPAVIE_DOM";
    Templates["ARTICLE"] = "ARTICLE";
    Templates["ARTICLES"] = "ARTICLES";
    Templates["DYNAMIC"] = "DYNAMIC";
    Templates["ESTABLISHMENT"] = "ESTABLISHMENT";
    Templates["HOME"] = "HOME";
    Templates["JOB_OFFER"] = "JOB_OFFER";
    Templates["JOB_OFFERS"] = "JOB_OFFERS";
    Templates["RESIDENCES"] = "RESIDENCES";
})(Templates || (Templates = {}));
