var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import EstablishmentCard from "components/EstablishmentCard";
import AgencyEstablishmentCard from "components/AgencyEstablishmentCard";
import GoogleMap from "components/GoogleMap";
import GoogleMapMarker from "components/GoogleMapMarker";
import { getEstablishmentIconMarker } from "utils/establishmentUtils";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import theme from "theme";
var GoogleMapEstablishments = function (_a) {
    var _b = _a.establishments, establishments = _b === void 0 ? [] : _b, agencies = _a.agencies, _c = _a.height, height = _c === void 0 ? "700px" : _c, centerProps = _a.center, _d = _a.zoom, zoom = _d === void 0 ? 13 : _d, _e = _a.establishmentCardProps, establishmentCardProps = _e === void 0 ? {} : _e;
    var currentEstablishments = agencies || establishments;
    var center = React.useMemo(function () {
        return centerProps ||
            currentEstablishments.reduce(function (center, _a) {
                var latitude = _a.latitude, longitude = _a.longitude;
                return {
                    lat: center.lat + Number(latitude) / currentEstablishments.length,
                    lng: center.lng + Number(longitude) / currentEstablishments.length
                };
            }, { lat: 0, lng: 0 });
    }, [centerProps, currentEstablishments]);
    return (_jsx(GoogleMap, __assign({ center: center, zoom: zoom, style: { height: height, width: "100%" } }, { children: currentEstablishments.map(function (establishment, index) {
            var id = establishment.id, latitude = establishment.latitude, longitude = establishment.longitude;
            var cache = createCache({ key: "googlemapmarkercontent" });
            var cssText = "";
            cache.sheet.insert = function (rule) {
                cssText += rule;
            };
            var infoWindowContent = ReactDOMServer.renderToStaticMarkup(_jsx(CacheProvider, __assign({ value: cache }, { children: _jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(Box, __assign({ width: { xs: "100%", sm: 400 } }, { children: agencies ? (_jsx(AgencyEstablishmentCard, { establishment: establishment }, void 0)) : (_jsx(EstablishmentCard
                        // @ts-ignore
                        , __assign({ 
                            // @ts-ignore
                            establishment: establishment, disableHover: true }, establishmentCardProps, { disableInnerNav: true }), void 0)) }), void 0) }), void 0) }), id));
            if (index === 0 && typeof window !== "undefined") {
                var style = document.createElement("style");
                style.appendChild(document.createTextNode(cssText));
                document.getElementsByTagName("head")[0].appendChild(style);
            }
            return (_jsx(GoogleMapMarker, { position: { lat: Number(latitude), lng: Number(longitude) }, infoWindow: {
                    content: infoWindowContent,
                    defaultOpen: currentEstablishments.length === 1
                }, 
                // @ts-ignore
                icon: agencies ? "/EHPAD.png" : getEstablishmentIconMarker(establishment) }, latitude + "," + longitude));
        }) }), void 0));
};
export default GoogleMapEstablishments;
