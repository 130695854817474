var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState, useEffect } from "react";
import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, OutlinedInput, Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/CancelOutlined";
import useTheme from "@mui/material/styles/useTheme";
import SiteContext from "cms/components/SiteContext";
import { getLogoPath } from "cms/utils/commonUtils";
import { addNewsletterContact } from "services/newsletter.service";
import HoneyPot from "components/HoneyPot";
import getEnvironmentPromise from "cms/back-office/services/getEnvironment";
import Button from "cms/editableComponents/Button";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
var NewsLetter = function (props) {
    var _a, _b;
    var _c = props.isBanner, isBanner = _c === void 0 ? false : _c, onClose = props.onClose;
    var _d = useState(false), checked = _d[0], setChecked = _d[1];
    var _e = useState(""), email = _e[0], setEmail = _e[1];
    var _f = useState(false), isSubmited = _f[0], setIsSubmited = _f[1];
    var _g = useState(""), errorMessage = _g[0], setErrorMessage = _g[1];
    var _h = useState(""), confirmationMessage = _h[0], setConfirmationMessage = _h[1];
    var _j = useState(false), isBrevoConfigured = _j[0], setIsBrevoConfigured = _j[1];
    var _k = useState("ip"), honeyKey = _k[0], setHoneyKey = _k[1];
    var theme = useTheme();
    var site = useContext(SiteContext);
    var siteSettings = site.settings;
    var _l = siteSettings || {}, _m = _l.newsletterTitle, newsletterTitle = _m === void 0 ? "ARPAVIE" : _m, _o = _l.newsletterSubTitle, newsletterSubTitle = _o === void 0 ? "La Newsletter" : _o, newsletterImage = _l.newsletterImage, newsletterInputLabel = _l.newsletterInputLabel, _p = _l.newsletterCheckboxLabel, newsletterCheckboxLabel = _p === void 0 ? "J’accepte de recevoir vos e-mails et confirme avoir pris de connaissance de votre politique de confidentialité et mentions légales." : _p, newsletterCheckboxSubLabel = _l.newsletterCheckboxSubLabel, _q = _l.newsletterButtonLabel, newsletterButtonLabel = _q === void 0 ? "S'inscrire" : _q;
    var logoPath = getLogoPath(newsletterImage);
    var isDownMd = useMediaQuery(theme.breakpoints.down("md"));
    useEffect(function () {
        getEnvironmentPromise().then(function (_a) {
            var brevo_key = _a.brevo_key, api_brevo_url = _a.api_brevo_url, honey_key = _a.honey_key;
            if (honey_key) {
                setHoneyKey(honey_key);
            }
            if (brevo_key && api_brevo_url && brevo_key !== "" && api_brevo_url !== "") {
                setIsBrevoConfigured(true);
            }
        });
    }, []);
    useEffect(function () {
        var _a;
        var checkboxLabel = document.getElementById(isBanner ? "banner_checkbox_control_label" : "checkbox_control_label");
        if (checkboxLabel && newsletterCheckboxLabel) {
            var newsletterCheckboxLabelValue = typeof newsletterCheckboxLabel === "string" ? newsletterCheckboxLabel : (_a = newsletterCheckboxLabel.props) === null || _a === void 0 ? void 0 : _a.children;
            if (newsletterCheckboxLabelValue) {
                checkboxLabel.innerHTML = decodeURIComponent(newsletterCheckboxLabelValue);
            }
        }
    }, [newsletterCheckboxLabel, isBanner]);
    var isNotValidEmail = function () {
        var regex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/g;
        return email !== "" && !regex.test(email);
    };
    var handleSubmit = function () {
        setErrorMessage("");
        if (isNotValidEmail()) {
            setIsSubmited(true);
        }
        else {
            setIsSubmited(false);
            var formData = new FormData();
            formData.append("email", email);
            formData.append(honeyKey, document.getElementById(honeyKey).value);
            addNewsletterContact(formData)
                .then(function () {
                setConfirmationMessage("Votre inscription à notre newsletter est confirmée. Vous avez été ajouté à notre liste. Vous allez recevoir un mail de confirmation.");
                typeof localStorage !== "undefined" && localStorage.setItem("isSubscribedToNewsletter", "true");
            })
                .catch(function (error) {
                var _a, _b, _c;
                if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 400 && ((_c = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.code) === "duplicate_parameter") {
                    setErrorMessage("Cet email est déjà inscrit à la newsletter.");
                }
                else {
                    setErrorMessage("Une erreur est survenue, veuillez réessayer plus tard.");
                }
            });
        }
    };
    var handleClose = function () {
        typeof onClose === "function" && onClose();
    };
    var isEmailError = isSubmited && isNotValidEmail();
    return (_jsxs(Paper, __assign({ sx: {
            backgroundColor: "colors.newsLetter",
            p: { xs: 1.75, sm: 5 },
            borderRadius: isBanner ? 3.25 : 0,
            pb: { xs: 4.25, sm: 5 }
        } }, { children: [!isBanner && (_jsx(Stack, __assign({ alignItems: "center" }, { children: _jsx(CloseIcon, { sx: {
                        color: "colors.blanc",
                        cursor: "pointer",
                        mb: 0.5,
                        height: 33,
                        width: 33,
                        position: { md: "absolute" },
                        top: 15,
                        right: 15
                    }, onClick: onClose }, void 0) }), void 0)), _jsxs(Stack, __assign({ direction: { xs: "column", md: "row" }, spacing: 4, divider: _jsx(Divider, { flexItem: true, orientation: isDownMd ? "horizontal" : "vertical", sx: { bgcolor: "colors.blanc", borderWidth: { md: 1 } } }, void 0) }, { children: [_jsxs(Stack, __assign({ direction: { xs: "row", md: "column" }, justifyContent: "space-between", pt: 1.5, sx: {
                            maxWidth: { xs: "100%", md: "40%" }
                        } }, { children: [_jsxs(Stack, { children: [_jsx(Typography, __assign({ sx: {
                                            fontFamily: "livvic",
                                            fontSize: { xs: "2.33rem", sm: "5.33rem", md: "4.57rem" },
                                            color: "colors.bleu",
                                            fontWeight: "bold",
                                            lineHeight: 1,
                                            textTransform: "uppercase"
                                        } }, { children: newsletterTitle }), void 0), _jsx(Typography, __assign({ sx: {
                                            fontFamily: "livvic",
                                            fontSize: { xs: "2.33rem", sm: "5.33rem", md: "4.57rem" },
                                            color: "colors.blanc",
                                            fontWeight: 900,
                                            mb: { xs: 0, sm: 1 },
                                            mt: 1,
                                            lineHeight: 1
                                        } }, { children: newsletterSubTitle }), void 0)] }, void 0), _jsx(Stack, __assign({ sx: { width: { xs: "inherit", sm: "50%", md: "inherit" } }, direction: "row", alignItems: "center" }, { children: _jsx(Box, { component: "img", sx: {
                                        width: { xs: "127px", sm: "100%" },
                                        display: { xs: "flex", md: "block" },
                                        justifyContent: "flex-end"
                                    }, src: logoPath, alt: ((_a = newsletterImage === null || newsletterImage === void 0 ? void 0 : newsletterImage.props) === null || _a === void 0 ? void 0 : _a.alt) || "Newsletter", title: ((_b = newsletterImage === null || newsletterImage === void 0 ? void 0 : newsletterImage.props) === null || _b === void 0 ? void 0 : _b.title) || "Newsletter" }, void 0) }), void 0)] }), void 0), _jsxs(Stack, { children: [_jsx(Typography, __assign({ sx: {
                                    fontFamily: "montserrat",
                                    fontSize: { xs: "1.33rem", sm: "3rem", md: "2.57rem" },
                                    color: "colors.bleu",
                                    fontWeight: "bold",
                                    lineHeight: 1
                                } }, { children: newsletterInputLabel }), void 0), _jsxs("form", { children: [confirmationMessage ? (_jsxs(Typography, __assign({ sx: {
                                            color: "colors.blanc",
                                            fontSize: "1.25rem",
                                            fontWeight: 600,
                                            mt: { xs: 6.25, sm: 7.5, md: 11.25 },
                                            mb: { xs: 7, sm: 8, md: 10 },
                                            display: "flex",
                                            alignItems: "center"
                                        } }, { children: [_jsx(CheckCircleOutlineOutlinedIcon, { sx: { fontSize: "2rem", mr: 1 } }, void 0), confirmationMessage] }), void 0)) : (_jsxs(_Fragment, { children: [_jsx(HoneyPot, { name: honeyKey }, void 0), _jsxs(FormControl, __assign({ error: isEmailError, sx: { mb: { xs: 2, sm: 4.625 }, mt: { xs: 2, sm: 4.125 } } }, { children: [_jsx(OutlinedInput, { name: "email", placeholder: "Votre e-mail", type: "email", required: true, value: email, onChange: function (e) { return setEmail(e.target.value); }, sx: {
                                                            background: "#FFFFFF",
                                                            borderRadius: "9px",
                                                            width: { xs: 146, sm: 389 },
                                                            height: { xs: 26, sm: 51 }
                                                        }, "aria-describedby": "invalid_mail" }, void 0), _jsx(FormHelperText, __assign({ id: "invalid_mail", sx: { "&.Mui-error": { fontSize: "0.9rem" } } }, { children: isEmailError ? "Veuillez saisir un mail valide" : "" }), void 0)] }), void 0), _jsx(FormGroup, { children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { sx: {
                                                            color: "colors.blanc",
                                                            "&.Mui-checked": { color: "colors.blanc" }
                                                        }, checked: checked, onChange: function () { return setChecked(!checked); } }, void 0), sx: {
                                                        color: "colors.blanc",
                                                        fontFamily: "montserrat",
                                                        "& .MuiFormControlLabel-label": { fontSize: { xs: "1rem", sm: "1.17rem" } }
                                                    }, label: _jsx("span", { id: isBanner ? "banner_checkbox_control_label" : "checkbox_control_label" }, void 0) }, void 0) }, void 0), _jsx(Typography, __assign({ sx: {
                                                    color: "colors.bleu",
                                                    fontSize: { xs: "0.83rem", sm: "1rem" },
                                                    fontFamily: "montserrat",
                                                    ml: 3.3,
                                                    mt: 1.625
                                                } }, { children: newsletterCheckboxSubLabel }), void 0), errorMessage && (_jsxs(Typography, __assign({ sx: { color: "error.light", display: "flex", alignItems: "center", mt: 2.25, mb: -1.25 } }, { children: [_jsx(WarningAmberOutlinedIcon, { sx: { color: "error.light", fontSize: "2rem", mr: 1 } }, void 0), errorMessage] }), void 0)), _jsx(Button, __assign({ variant: "cta", sx: {
                                                    mt: { xs: 1.25, sm: 4.375 },
                                                    py: 0.75,
                                                    px: 2,
                                                    "&.Mui-disabled": {
                                                        backgroundColor: "colors.gris_1",
                                                        color: "colors.gris_4",
                                                        opacity: 0.7,
                                                        border: "none"
                                                    }
                                                }, onClick: handleSubmit, disabled: !checked || !email || !isBrevoConfigured }, { children: newsletterButtonLabel }), void 0)] }, void 0)), !isBanner && confirmationMessage && (_jsx(_Fragment, { children: _jsx(Button, __assign({ variant: "cta", sx: {
                                                mt: { xs: 1.25, sm: 4.375 },
                                                py: 0.75,
                                                px: 2
                                            }, onClick: handleClose }, { children: "Fermer" }), void 0) }, void 0))] }, void 0)] }, void 0)] }), void 0)] }), void 0));
};
export default NewsLetter;
