var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from "@mui/material";
import Label from "components/Label";
import SearchAutocompleteCity from "components/SearchAutocompleteCity";
import SearchRadius from "components/SearchRadius";
import SearchStep from "routes/Search/SearchStep";
export var summaryTitle = "Lieu de résidence";
var LocationStep = function (props) {
    var form = props.form, setForm = props.setForm, handleValidateStep = props.handleValidateStep, nextStepButton = props.nextStepButton;
    return (_jsx(SearchStep, __assign({ title: summaryTitle, subtitle: "Dans quelles zones g\u00E9ographiques souhaitez-vous trouver une r\u00E9sidence ?", button: nextStepButton, accordions: [
            {
                title: "Comment choisir la localisation de ma maison de retraite médicalisée ?",
                text: "Le moteur de recherche vous propose des résidences dans la localité choisie mais aussi sur un rayon plus large. Si le nombre de réponses retournées vous semble trop faible, élargissez votre rayon et obtenez ainsi plus de choix."
            }
        ], handleValidateStep: handleValidateStep }, { children: _jsxs(Grid, __assign({ item: true, xs: 12, md: 6, container: true, direction: "column" }, { children: [_jsx(Label, { children: "S\u00E9lectionnez votre pr\u00E9f\u00E9rence de r\u00E9sidence" }, void 0), _jsx(SearchAutocompleteCity, { form: form, setForm: setForm }, void 0), _jsx(SearchRadius, { form: form, setForm: setForm }, void 0)] }), void 0) }), void 0));
};
export default LocationStep;
