import React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import makeOverridableStyles from "cms/utils/makeOverridableStyles";
import MuiModal from "@mui/material/Modal";
import SimpleBar from "simplebar-react";

const useActionsStyles = makeOverridableStyles("ModalActions", theme => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    "&> *": {
      marginLeft: theme.spacing(1)
    }
  }
}));

export const ModalActions = props => {
  const classes = useActionsStyles();

  return <div className={classes.actions} {...props} />;
};

const useStyles = makeOverridableStyles("Modal", theme => ({
  wrapper: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  main: props => ({
    width: `${
      props.size && theme.breakpoints.values[props.size] ? `${theme.breakpoints.values[props.size]}px` : "auto"
    }`,
    maxWidth: "100%",
    maxHeight: "100%",
    overflow: "auto",
    borderRadius: 7,
    "& .simplebar-track.simplebar-vertical .simplebar-scrollbar:before ": {
      backgroundColor: theme.palette.layout && theme.palette.layout.scrollbar
    }
  })
}));

const Modal = props => {
  const { children, onClose, size, title, ...others } = props;

  const classes = useStyles({ size });

  return (
    <MuiModal onClose={onClose} {...others}>
      <div className={classes.wrapper} onClick={onClose}>
        <Paper onClick={e => e.stopPropagation()} className={classes.main}>
          <SimpleBar style={{ maxHeight: "90vh" }}>
            {title && (
              <>
                <Box paddingLeft={2}>
                  <h2>{title}</h2>
                </Box>
                <Divider />
              </>
            )}
            <Box p={2}>{children}</Box>
          </SimpleBar>
        </Paper>
      </div>
    </MuiModal>
  );
};

Modal.propTypes = {
  ...MuiModal.propTypes,
  title: PropTypes.string
};

Modal.defaultProps = {
  title: null,
  className: "",
  children: null,
  size: "md"
};

export default Modal;
