var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef, useCallback, useEffect } from "react";
import { Typography, Grid } from "@mui/material";
import Box from "@mui/system/Box";
import useTheme from "@mui/material/styles/useTheme";
var InfoBlock = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var _h, _j;
    var _k = _a.id, id = _k === void 0 ? "" : _k, _l = _a.firstTitle, firstTitle = _l === void 0 ? null : _l, _m = _a.firstSubTitle, firstSubTitle = _m === void 0 ? null : _m, _o = _a.firstDynamicPart, firstDynamicPart = _o === void 0 ? null : _o, _p = _a.firstPartTitleColor, firstPartTitleColor = _p === void 0 ? "colors.noir" : _p, _q = _a.firstPartColor, firstPartColor = _q === void 0 ? "rgba(0, 0, 0, 0.8)" : _q, _r = _a.firstPartBackgroundColor, firstPartBackgroundColor = _r === void 0 ? "colors.gris_5" : _r, _s = _a.secondTitle, secondTitle = _s === void 0 ? null : _s, _t = _a.secondSubTitle, secondSubTitle = _t === void 0 ? null : _t, _u = _a.secondDynamicPart, secondDynamicPart = _u === void 0 ? null : _u, _v = _a.secondPartColor, secondPartColor = _v === void 0 ? "#FFFFFF" : _v, _w = _a.secondPartBackgroundColor, secondPartBackgroundColor = _w === void 0 ? "colors.bleu" : _w, _x = _a.customized, customized = _x === void 0 ? false : _x, others = __rest(_a, ["id", "firstTitle", "firstSubTitle", "firstDynamicPart", "firstPartTitleColor", "firstPartColor", "firstPartBackgroundColor", "secondTitle", "secondSubTitle", "secondDynamicPart", "secondPartColor", "secondPartBackgroundColor", "customized"]);
    var theme = useTheme();
    var angle = Math.PI / 10;
    var spacing = 32;
    var containerRef = useRef(null);
    var _y = useState(null), refWidth = _y[0], setRefWidth = _y[1];
    var _z = useState(null), refHeight = _z[0], setRefHeight = _z[1];
    var handleResize = useCallback(function () {
        var _a, _b;
        setRefWidth(((_a = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) || null);
        setRefHeight(((_b = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _b === void 0 ? void 0 : _b.offsetHeight) || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(_h = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _h === void 0 ? void 0 : _h.offsetWidth, (_j = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _j === void 0 ? void 0 : _j.offsetHeight]);
    useEffect(function () {
        window.addEventListener("resize", handleResize);
        handleResize();
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    }, [handleResize]);
    var getShift = function (width) {
        return (width * Math.sin(angle)) / (Math.sin(Math.PI / 2 - angle) * 2);
    };
    var getShiftPercent = function (width) {
        return refWidth ? (100 * (getShift(width) - spacing / 2)) / refWidth : 0;
    };
    return (_jsx("div", __assign({ onLoad: handleResize }, { children: _jsxs(Grid, __assign({ container: true, sx: { overflow: "hidden" }, ref: containerRef }, others, { children: [_jsx(Grid, __assign({ item: true, sx: (_b = {},
                        _b[theme.breakpoints.up("lg")] = {
                            overflow: "hidden",
                            mr: -(getShift(refHeight) - spacing / 2) / 8
                        },
                        _b.width = { xs: "100%", lg: 50 + getShiftPercent(refHeight) + "%" },
                        _b) }, { children: _jsx(Box, __assign({ sx: (_c = {
                                bgcolor: firstPartBackgroundColor
                            },
                            _c[theme.breakpoints.up("lg")] = {
                                transform: "skew(-" + angle + "rad)",
                                width: "100%",
                                height: "100%",
                                ml: -getShift(refHeight) / 8
                            },
                            _c) }, { children: _jsxs(Box, __assign({ sx: (_d = {},
                                _d[theme.breakpoints.up("lg")] = {
                                    transform: "skew(" + angle + "rad)",
                                    ml: getShift(refHeight) / 8,
                                    height: "100%"
                                },
                                _d.pl = { xs: 4, md: 11, lg: 16.75 },
                                _d.pt = { xs: 4, md: 11, lg: 5.75 },
                                _d.pr = { xs: 4, md: 11, lg: 14 },
                                _d.pb = { xs: 4, md: 11, lg: 5.75 },
                                _d.color = firstPartColor,
                                _d["& h3, & h4, & h5, & h6"] = customized
                                    ? {}
                                    : {
                                        color: firstPartColor + " !important"
                                    },
                                _d["& h2"] = {
                                    color: firstPartTitleColor
                                },
                                _d) }, { children: [_jsx(Typography, __assign({ variant: "h2", sx: { mb: 2, mt: { lg: 5.25 } } }, { children: firstTitle }), void 0), _jsx(Typography, __assign({ component: "h3", variant: "subtitle1", sx: { color: firstPartColor } }, { children: firstSubTitle }), void 0), firstDynamicPart] }), void 0) }), void 0) }), void 0), _jsx(Grid, __assign({ item: true, sx: (_e = {},
                        _e[theme.breakpoints.up("lg")] = {
                            ml: -(getShift(refHeight) - spacing / 2 + 1) / 8,
                            overflow: "hidden"
                        },
                        _e.width = { xs: "100%", lg: 50 + getShiftPercent(refHeight) + "%" },
                        _e) }, { children: _jsx(Box, __assign({ sx: (_f = {
                                bgcolor: secondPartBackgroundColor
                            },
                            _f[theme.breakpoints.up("lg")] = {
                                transform: "skew(-" + angle + "rad)",
                                width: "100%",
                                height: "100%",
                                ml: getShift(refHeight) / 8
                            },
                            _f) }, { children: _jsxs(Box, __assign({ sx: (_g = {},
                                _g[theme.breakpoints.up("lg")] = {
                                    transform: "skew(" + angle + "rad)",
                                    ml: getShift(refHeight) / 8,
                                    height: "100%"
                                },
                                _g.pl = { xs: 4, md: 11, lg: 3 },
                                _g.pt = { xs: 4, md: 11, lg: 5.75 },
                                _g.pr = { xs: 4, md: 11, lg: 27.5 },
                                _g.pb = { xs: 4, md: 11, lg: 5.75 },
                                _g.color = secondPartColor,
                                _g["& h2, & h3, & h4, & h5, & h6"] = customized
                                    ? {}
                                    : {
                                        color: secondPartColor + " !important"
                                    },
                                _g) }, { children: [_jsx(Typography, __assign({ variant: "h2", sx: { mb: 2, mt: { lg: 5.25 } } }, { children: secondTitle }), void 0), _jsx(Typography, __assign({ component: "h3", variant: "subtitle1", sx: { color: secondPartColor } }, { children: secondSubTitle }), void 0), secondDynamicPart] }), void 0) }), void 0) }), void 0)] }), id) }), void 0));
};
export default InfoBlock;
