import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const Margin = props => {
  const { height, mobileHeight } = props;

  return (
    <Box
      sx={{
        height: { xs: `${mobileHeight}px`, lg: `${height}px` },
        width: 1
      }}
    />
  );
};

Margin.propTypes = {
  height: PropTypes.string,
  mobileHeight: PropTypes.string
};

Margin.defaultProps = {
  height: null,
  mobileHeight: null
};

export default Margin;
