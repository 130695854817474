import React from "react";
import uuidv4 from "uuid/v4";
import Accordion from "cms/editableComponents/Accordion";
import Text from "cms/editableComponents/Text";

const details = <Text>Dans cette zone vous serez libre d&apos;ajouter différents contenus</Text>;

const panels = {
  summary: "Panneau dépliant",
  details,
  id: uuidv4()
};

const ExpansionPanelPreview = () => {
  return <Accordion panels={panels} />;
};

export default ExpansionPanelPreview;
