export var FeatureCategory;
(function (FeatureCategory) {
    FeatureCategory["EVERYDAY_LIFE"] = "everyday life";
    FeatureCategory["ACTIVITIES"] = "activities";
    FeatureCategory["WELL_BEING"] = "well being";
    FeatureCategory["THERAPEUTIC_ACTIVITIES"] = "therapeutic activities";
    FeatureCategory["USEFUL_INFORMATION"] = "useful information";
    FeatureCategory["ENVIRONMENT"] = "environment";
    FeatureCategory["MOST_HEALTH"] = "most health";
    FeatureCategory["MOST_RESIDENCE"] = "most residence"; // Les plus de la résidence
})(FeatureCategory || (FeatureCategory = {}));
