var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "components/TabPanel";
import { ImageContainer } from "cms/editableComponents/Image";
var CustomTabs = function (_a) {
    var _b = _a.id, id = _b === void 0 ? "" : _b, _c = _a.tabsContent, tabsContent = _c === void 0 ? {} : _c, _d = _a.title, title = _d === void 0 ? "" : _d, _e = _a.backgroundColor, backgroundColor = _e === void 0 ? "#E9F7F7" : _e, others = __rest(_a, ["id", "tabsContent", "title", "backgroundColor"]);
    var _f = (tabsContent || {}).tabs, tabs = _f === void 0 ? [] : _f;
    if (!Array.isArray(tabs)) {
        tabs = [tabs];
    }
    var _g = useState(0), tabsIndex = _g[0], setTabsIndex = _g[1];
    var _h = useState(tabs[0]), currentTab = _h[0], setCurrentTab = _h[1];
    useEffect(function () {
        setCurrentTab(tabs[tabsIndex]);
    }, [tabs, tabsIndex]);
    var handleChangeTabsIndex = function (e, newValue) {
        setTabsIndex(newValue);
    };
    return (_jsxs(Grid, __assign({ container: true, sx: { px: { xs: 3, md: 11 } }, alignItems: "strech" }, others, { children: [_jsx(Grid, __assign({ item: true, xs: 12, md: 4, lg: 3, sx: { maxHeight: "400px" } }, { children: _jsx(Box, __assign({ sx: { height: "100%" } }, { children: _jsx(ImageContainer, __assign({ ratio: "" }, { children: currentTab.image }), void 0) }), void 0) }), void 0), _jsxs(Grid, __assign({ item: true, xs: 12, md: 8, lg: 9, sx: { pt: 4, pr: { xs: 4, md: 6.625 }, pb: 2.625, pl: { xs: 4, md: 9 }, bgcolor: backgroundColor } }, { children: [_jsx(Typography, __assign({ variant: "h2" }, { children: title }), void 0), _jsx(Tabs, __assign({ "aria-labelledby": "custom tabs", sx: { py: 1.5 }, variant: "scrollable", allowScrollButtonsMobile: true, value: tabsIndex, onChange: handleChangeTabsIndex }, { children: tabs.map(function (tab) { return (_jsx(Tab, { label: tab.title, id: "tab-" + tab.title, "aria-labelledby": "tab-" + tab.title, "aria-controls": "tabpanel-" + tab.title }, tab.title)); }) }), void 0), tabs.map(function (tab, index) { return (_jsx(TabPanel, __assign({ value: tabsIndex, index: index, "aria-labelledby": "tabpanel-" + tab.title, label: tab.title }, { children: tab.children }), tab.title)); })] }), void 0)] }), id));
};
export default CustomTabs;
