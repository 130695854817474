import contentsTypes from "cms/enums/contentsTypes";
import customTypes from "customComponents/customTypes";
import { Templates } from "types/templates.enum";
import ArpavieDomTemplate from "templates/arpavieDom/ArpavieDomTemplate";
export default {
    key: Templates.ARPAVIE_DOM,
    label: "ARPAVIE@Dom",
    Component: ArpavieDomTemplate,
    initialContents: [
        {
            key: "titleImage",
            type: contentsTypes.DYNAMIC,
            value: ""
        },
        {
            key: "image",
            type: contentsTypes.DYNAMIC,
            value: ""
        },
        {
            key: "description",
            type: contentsTypes.TEXT,
            value: ""
        },
        {
            key: "forWhoTitle",
            type: contentsTypes.EDITABLE_INPUT,
            value: "Pour qui ?"
        },
        {
            key: "whatServicesTitle",
            type: contentsTypes.EDITABLE_INPUT,
            value: "Quels sont nos services ?"
        },
        {
            key: "firstService",
            type: customTypes.SERVICE_DESCRIPTION,
            value: "",
            children: [
                {
                    key: "title",
                    type: contentsTypes.SELECT,
                    value: "Aide à l’autonomie"
                },
                {
                    key: "subtitle",
                    type: contentsTypes.EDITABLE_INPUT,
                    value: "Aide aux gestes de la vie quotidienne"
                },
                {
                    key: "image",
                    type: contentsTypes.IMAGE,
                    value: ""
                },
                {
                    key: "dynamic",
                    type: contentsTypes.DYNAMIC,
                    value: ""
                }
            ]
        },
        {
            key: "secondService",
            type: customTypes.SERVICE_DESCRIPTION,
            value: "",
            children: [
                {
                    key: "title",
                    type: contentsTypes.SELECT,
                    value: "Aide à l’entretien du domicile"
                },
                {
                    key: "subtitle",
                    type: contentsTypes.EDITABLE_INPUT,
                    value: "Aide à l’entretien de votre domicile au quotidien"
                },
                {
                    key: "image",
                    type: contentsTypes.IMAGE,
                    value: ""
                },
                {
                    key: "dynamic",
                    type: contentsTypes.DYNAMIC,
                    value: ""
                }
            ]
        },
        {
            key: "thirdService",
            type: customTypes.SERVICE_DESCRIPTION,
            value: "",
            children: [
                {
                    key: "title",
                    type: contentsTypes.SELECT,
                    value: "Assistance sortie d’hospitalisation"
                },
                {
                    key: "image",
                    type: contentsTypes.IMAGE,
                    value: ""
                },
                {
                    key: "dynamic",
                    type: contentsTypes.DYNAMIC,
                    value: ""
                }
            ]
        },
        {
            key: "forthService",
            type: customTypes.SERVICE_DESCRIPTION,
            value: "",
            children: [
                {
                    key: "title",
                    type: contentsTypes.SELECT,
                    value: "Services +"
                },
                {
                    key: "image",
                    type: contentsTypes.IMAGE,
                    value: ""
                },
                {
                    key: "dynamic",
                    type: contentsTypes.DYNAMIC,
                    value: ""
                }
            ]
        },
        {
            key: "howDoesItWorkTitle",
            type: contentsTypes.EDITABLE_INPUT,
            value: "Comment ça marche ? "
        },
        {
            key: "howMuchDoesItCostTitle",
            type: contentsTypes.EDITABLE_INPUT,
            value: "Combien ça coûte ?"
        },
        {
            key: "knowMoreButton",
            type: contentsTypes.BUTTON,
            value: "",
            children: [
                {
                    key: "text",
                    type: contentsTypes.INPUT,
                    value: "En savoir plus sur les aides financières"
                },
                {
                    key: "size",
                    type: contentsTypes.RADIO_GROUP,
                    value: "large"
                },
                {
                    key: "color",
                    type: contentsTypes.RADIO_GROUP,
                    value: "secondary"
                },
                {
                    key: "variant",
                    type: contentsTypes.RADIO_GROUP,
                    value: "outlined"
                },
                {
                    key: "link",
                    type: contentsTypes.LINK,
                    value: "",
                    children: [
                        {
                            key: "url",
                            type: contentsTypes.URL,
                            value: ""
                        }
                    ]
                }
            ]
        },
        {
            key: "agenciesTitle",
            type: contentsTypes.EDITABLE_INPUT,
            value: "Nos implantations - Nos agences"
        }
    ]
};
