import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import makeOverridableStyles from "cms/utils/makeOverridableStyles";
import Box from "@mui/material/Box";

const useStyles = makeOverridableStyles("Block", theme => ({
  block: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 0)
    }
  }
}));

const Block = props => {
  const { className, ...others } = props;
  const classes = useStyles();
  return <Box className={classnames(classes.block, className)} {...others} />;
};

Block.propTypes = {
  className: PropTypes.string
};

Block.defaultProps = {
  className: ""
};

export default Block;
