var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Paper, Typography } from "@mui/material";
import contentsTypes from "cms/enums/contentsTypes";
import Image, { ImageContainer } from "cms/editableComponents/Image";
import Link from "cms/editableComponents/Link";
import EstablishmentAddressInline from "components/EstablishmentAddressInline";
import EstablishmentTags from "components/EstablishmentTags";
import { getEstablishmentTypeLabel } from "utils/establishmentUtils";
import { EstablishmentPriceTimeUnit } from "types/establishment/establishment-price-time-unit.enum";
var defaultImage = "/default-image-establishment.jpg";
var EstablishmentCard = function (_a) {
    var establishment = _a.establishment, _b = _a.imageRatio, imageRatio = _b === void 0 ? 90 : _b, disableHover = _a.disableHover, disableImage = _a.disableImage, disableInnerNav = _a.disableInnerNav, disableLink = _a.disableLink, sx = _a.sx, others = __rest(_a, ["establishment", "imageRatio", "disableHover", "disableImage", "disableInnerNav", "disableLink", "sx"]);
    var history = useHistory();
    var name = establishment.name, type = establishment.type, address = establishment.address, prices = establishment.prices, pageVersion = establishment.pageVersion;
    var fullPath = (pageVersion || {}).fullPath;
    var city = address.city;
    var image = React.useMemo(function () {
        var _a, _b, _c;
        if (pageVersion) {
            var _d = pageVersion.contents, contents = _d === void 0 ? [] : _d;
            var imagesGalleryContent = contents.find(function (content) { return content.type === contentsTypes.IMAGES_GALLERY; });
            if (imagesGalleryContent) {
                var imagesContent = (_a = imagesGalleryContent.children) === null || _a === void 0 ? void 0 : _a.find(function (content) { return content.type === contentsTypes.IMAGES; });
                if (imagesContent) {
                    var imageContent = (_b = imagesContent.children) === null || _b === void 0 ? void 0 : _b.find(function (content) { return content.type === contentsTypes.IMAGE_AS_FILE; });
                    if (imageContent) {
                        var fileContent = (_c = imageContent.children) === null || _c === void 0 ? void 0 : _c.find(function (content) { return content.type === contentsTypes.FILE_IMAGE; });
                        if (fileContent) {
                            try {
                                var file = JSON.parse(fileContent.value);
                                return file;
                            }
                            catch (e) { }
                        }
                    }
                }
            }
        }
        return defaultImage;
    }, [pageVersion]);
    var minPrice = (prices &&
        prices.length > 0 &&
        prices.reduce(function (min, price) {
            if (!min || price.price < min.price) {
                return price;
            }
            return min;
        })) ||
        null;
    var buttonProps = {};
    if (fullPath) {
        if (disableInnerNav) {
            buttonProps = {
                href: fullPath
            };
        }
        else {
            buttonProps = {
                page: pageVersion,
                component: Link
            };
        }
    }
    var onKeyDown = function (e) {
        if (e.key === "Enter" && fullPath) {
            e.preventDefault();
            history.push(fullPath);
        }
    };
    return (_jsxs(Paper, __assign({ sx: __assign({ borderRadius: 1, position: "relative", height: "100%" }, sx), tabIndex: 0, onKeyDown: onKeyDown, "aria-label": establishment.name }, others, { children: [!disableHover && (_jsxs(Box, __assign({ sx: {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    display: { xs: "none", xl: "flex" },
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    textAlign: "center",
                    bgcolor: "colors.bleu",
                    color: "#fff",
                    zIndex: 20,
                    opacity: 0,
                    transition: "opacity ease-in 250ms",
                    "&:hover": {
                        opacity: 1
                    }
                } }, { children: [_jsxs(Box, { children: [_jsx(Typography, __assign({ color: "inherit", variant: "h2", sx: { mb: 2 } }, { children: name }), void 0), _jsx(Typography, __assign({ color: "inherit", variant: "h3" }, { children: "Maison de retraite" }), void 0), _jsx(Typography, __assign({ color: "inherit", variant: "h3" }, { children: getEstablishmentTypeLabel(type) }), void 0), _jsx(Typography, __assign({ color: "inherit", variant: "h4", sx: { mt: 2 } }, { children: city }), void 0)] }, void 0), _jsx(Box, { children: _jsx(Button, __assign({}, buttonProps, { tabIndex: -1, "aria-label": establishment.name, variant: "outlined", sx: {
                                "&:hover, &:focus": {
                                    color: "colors.bleu",
                                    bgcolor: "colors.blanc",
                                    borderColor: "colors.bleu"
                                }
                            } }, { children: "Acc\u00E9der \u00E0 la fiche" }), void 0) }, void 0)] }), void 0)), !disableImage && (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ sx: {
                            position: "absolute",
                            top: 16,
                            right: 16,
                            left: 16,
                            display: "flex",
                            zIndex: 10,
                            justifyContent: "flex-end",
                            flexWrap: "wrap"
                        } }, { children: _jsx(EstablishmentTags, { establishment: establishment }, void 0) }), void 0), _jsx(ImageContainer, __assign({ ratio: imageRatio }, { children: _jsx(Image, { file: { url: image === null || image === void 0 ? void 0 : image.url }, alt: (image === null || image === void 0 ? void 0 : image.alt) || "", title: image === null || image === void 0 ? void 0 : image.title, disableLazy: true }, void 0) }), void 0)] }, void 0)), _jsxs(Box, __assign({ px: 2, pt: 2, pb: disableHover && !disableLink ? 7 : { xs: 8, xl: 3.5 }, sx: { minHeight: disableHover && !disableLink ? "none" : 210 } }, { children: [_jsx(Box, __assign({ sx: __assign({ mb: 2.5 }, ((!disableImage && {
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical"
                        }) ||
                            {})) }, { children: _jsx(Typography, __assign({ component: "span", variant: "h4", title: name }, { children: name }), void 0) }), void 0), _jsx(Typography, __assign({ variant: "body1" }, { children: _jsx(EstablishmentAddressInline, { address: address }, void 0) }), void 0), minPrice && (_jsxs(Typography, __assign({ variant: "caption" }, { children: ["\u00E0 partir de ", minPrice.price, " \u20AC /", " ", minPrice.timeUnit === EstablishmentPriceTimeUnit.DAILY ? "jour / personne" : "mois"] }), void 0)), !disableLink && (_jsx(Button, __assign({}, buttonProps, { tabIndex: -1, "aria-label": establishment.name, variant: "outlined", sx: {
                            position: "absolute",
                            bottom: 14,
                            right: 8,
                            display: { xs: "block", xl: disableHover ? "block" : "none" }
                        } }, { children: "Acc\u00E9der \u00E0 la fiche" }), void 0))] }), void 0)] }), void 0));
};
export default EstablishmentCard;
