var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
var GoogleMapMarker = function (_a) {
    var infoWindowProps = _a.infoWindow, options = __rest(_a, ["infoWindow"]);
    var _b = React.useState(), marker = _b[0], setMarker = _b[1];
    var _c = React.useState(), infoWindow = _c[0], setInfoWindow = _c[1];
    var _d = infoWindowProps || {}, defaultOpen = _d.defaultOpen, infoWindowOptions = __rest(_d, ["defaultOpen"]);
    //   const { content: infoWindowContent } = infoWindowOptions || {};
    React.useEffect(function () {
        if (!marker) {
            var newMarker_1 = new google.maps.Marker();
            setMarker(newMarker_1);
            if (!infoWindow) {
                var info_1 = new google.maps.InfoWindow();
                if (defaultOpen) {
                    info_1.open({
                        anchor: newMarker_1,
                        shouldFocus: false
                    });
                }
                newMarker_1.addListener("click", function () {
                    info_1.open({
                        anchor: newMarker_1,
                        shouldFocus: false
                    });
                });
                setInfoWindow(info_1);
            }
        }
        // remove marker from map on unmount
        return function () {
            if (marker) {
                marker.setMap(null);
            }
            if (infoWindow) {
                infoWindow.close();
            }
        };
    }, [marker, infoWindow, defaultOpen]);
    React.useEffect(function () {
        if (marker) {
            marker.setOptions(options);
            var map = marker.getMap();
            if (map && infoWindow) {
                map.addListener("click", function () {
                    infoWindow.close();
                });
            }
        }
    }, [marker, infoWindow, options]);
    React.useEffect(function () {
        if (infoWindow) {
            infoWindow.setOptions(infoWindowOptions);
        }
    }, [infoWindow, infoWindowOptions]);
    return null;
};
export default GoogleMapMarker;
