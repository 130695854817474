import generateCRUD from "cms/back-office/services/generateCRUD";
import { generateContentId } from "cms/back-office/utils/adminContentsUtils";
import contentsTypes from "cms/enums/contentsTypes";

const SitesCRUD = generateCRUD("sites");

const childCacheKey = "updated";

export default class AdminSitesService extends SitesCRUD {
  static resetSiteCache = site => {
    const { id, contents } = site;
    const cacheChild = contents.find(c => c.key === childCacheKey);
    let newContents = [];
    if (cacheChild) {
      newContents = contents.map(c => (c.id === cacheChild.id ? { ...cacheChild, value: `${new Date()}` } : c));
    } else {
      newContents = [
        ...contents,
        generateContentId({
          type: contentsTypes.INPUT,
          key: childCacheKey,
          value: `${new Date()}`
        })
      ];
    }
    SitesCRUD.update({ id, contents: newContents });
  };
}
