var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import Helmet from "react-helmet";
import { HashLink } from "react-router-hash-link";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import { Box, Button, Container, Grid, Link, Paper, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExitIcon from "components/ExitIcon";
import CriteriaStep from "routes/Search/Search-CriteriaStep";
import LocationStep from "routes/Search/Search-LocationStep";
import PriceStep from "routes/Search/Search-PriceStep";
import AdditionalCriteriaStep from "routes/Search/Search-AdditionalCriteriaStep";
import Summary from "routes/Search/Search-Summary";
import { Templates } from "types/templates.enum";
import { useEnhanceListWithPageVersions } from "services/pageversion.service";
import { useSearchEstablishments } from "services/search.service";
import { getQueryParams, createQueryParams } from "cms/utils/urlUtils";
import { getEstablishmentFromEstablishmentResult, getEstablishmentSearchParamsFromQueries, getEstablishmentTypeFromSearchParams } from "utils/establishmentSearchUtils";
import { scrollSent } from "utils/stepUtils";
import SiteContext from "cms/components/SiteContext";
import { useMatomo } from "@datapunt/matomo-tracker-react";
var TitleBlock = function () { return (_jsx(Grid, __assign({ item: true, xs: 12, lg: "auto" }, { children: _jsxs(Paper, __assign({ sx: {
            height: { lg: "187px" },
            p: { xs: "15px 70px 15px 15px", lg: "35px 70px 35px 15px" },
            position: "relative"
        } }, { children: [_jsx(Typography, __assign({ variant: "h1" }, { children: "Recherche d\u00E9taill\u00E9e" }), void 0), _jsx(Typography, __assign({ variant: "subtitle1" }, { children: "Arpavie vous guide dans cette d\u00E9marche" }), void 0), _jsx(Link, __assign({ component: HashLink, to: "/", sx: {
                    position: "absolute",
                    top: "13px",
                    right: "16px"
                }, "aria-label": "Quitter la recherche d\u00E9taill\u00E9e" }, { children: _jsx(ExitIcon, { sx: {
                        fontSize: "3rem",
                        color: "colors.recherche_fonce",
                        bgcolor: "colors.recherche_clair",
                        padding: "10px",
                        borderRadius: "20px"
                    } }, void 0) }), void 0)] }), void 0) }), void 0)); };
var Search = function (_a) {
    var location = _a.location, history = _a.history;
    var trackPageView = useMatomo().trackPageView;
    var pathname = location.pathname, search = location.search;
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down("lg"));
    var queryParams = getQueryParams(search);
    var site = React.useContext(SiteContext);
    var title = "Rechercher une résidence";
    var description = "ARPAVIE vous guide étape par étape afin de trouver la résidence adaptée à votre besoin.";
    useEffect(function () {
        trackPageView({ documentTitle: title });
    }, [pathname, trackPageView]);
    var arpavieDomPages = useQuery(["arpavie_dom_pages", site === null || site === void 0 ? void 0 : site.id], function () {
        return axios
            .get("/findPagesByTemplates", {
            params: {
                templates: Templates.ARPAVIE_DOM,
                siteId: site === null || site === void 0 ? void 0 : site.id,
                sortBy: "publishedDate",
                sortOrder: "DESC"
            }
        })
            .then(function (r) { var _a; return (_a = r.data) === null || _a === void 0 ? void 0 : _a.data; });
    }).data;
    var arpavieDomPathName = React.useMemo(function () {
        var _a;
        return ((_a = arpavieDomPages === null || arpavieDomPages === void 0 ? void 0 : arpavieDomPages.find(function (elt) { return elt.fullPath; })) === null || _a === void 0 ? void 0 : _a.fullPath) || "/";
    }, [arpavieDomPages]);
    var form = getEstablishmentSearchParamsFromQueries(queryParams);
    var _b = useState(false), formSubmitted = _b[0], setFormSubmitted = _b[1];
    var errors = useMemo(function () {
        var availability = form.availability, stayHome = form.stayHome, medicalSupport = form.medicalSupport, needAssist = form.needAssist;
        return {
            availability: !availability,
            type: !medicalSupport && !needAssist && !stayHome
        };
    }, [form]);
    var showError = function (key) { return formSubmitted && errors[key]; };
    var isFormValid = Object.values(errors).reduce(function (a, b) { return a && !b; }, true);
    var stepNumber = (queryParams.stepNumber && Number(queryParams.stepNumber)) || 1;
    var setStepNumber = function (number) {
        history.push({
            pathname: pathname,
            search: "?" + createQueryParams(__assign(__assign({}, queryParams), { stepNumber: number }))
        });
    };
    var handleValidateStep = function () {
        scrollSent("stepTitle");
        setFormSubmitted(true);
        if (stepNumber !== 1 || isFormValid) {
            setStepNumber(stepNumber + 1);
        }
    };
    var stayHome = form.stayHome, medicalSupport = form.medicalSupport, needAssist = form.needAssist, advancedAvailability = form.advancedAvailability, lat = form.lat, lng = form.lng, radius = form.radius, budgetMin = form.budgetMin, budgetMax = form.budgetMax, specificTypes = form.specificTypes, establishmentPrices = form.establishmentPrices, proximityElements = form.proximityElements, PMR = form.PMR;
    var type = getEstablishmentTypeFromSearchParams({
        medicalSupport: medicalSupport,
        needAssist: needAssist
    });
    var _c = useSearchEstablishments({
        type: type,
        advancedAvailability: advancedAvailability,
        lat: lat,
        lng: lng,
        radius: radius,
        budgetMin: budgetMin,
        budgetMax: budgetMax,
        specificTypes: specificTypes,
        establishmentPrices: establishmentPrices,
        proximityElements: proximityElements,
        PMR: PMR,
        from: 0,
        size: 200
    }), _d = _c.data, establishmentsResults = _d === void 0 ? [] : _d, isFetchingEstablishments = _c.isFetching, errorEstablishments = _c.error;
    var _e = useEnhanceListWithPageVersions(establishmentsResults.map(getEstablishmentFromEstablishmentResult)), _f = _e.data, establishments = _f === void 0 ? [] : _f, isFetchingVersions = _e.isFetching, errorVersions = _e.error;
    var isFetching = React.useMemo(function () { return isFetchingEstablishments || isFetchingVersions; }, [
        isFetchingEstablishments,
        isFetchingVersions
    ]);
    var error = React.useMemo(function () { return errorEstablishments || errorVersions; }, [errorEstablishments, errorVersions]);
    var handleChangeForm = function (newForm) {
        history.push({
            pathname: pathname,
            search: "?" + createQueryParams(__assign(__assign({}, queryParams), Object.entries(newForm).reduce(function (acc, _a) {
                var _b, _c;
                var key = _a[0], value = _a[1];
                if (typeof value === "boolean")
                    return __assign(__assign({}, acc), (_b = {}, _b[key] = value ? "1" : "0", _b));
                return __assign(__assign({}, acc), (_c = {}, _c[key] = value, _c));
            }, {})))
        });
    };
    var handleClickEditStep = function (stepIndex) { return function () {
        scrollSent("stepTitle");
        setStepNumber(stepIndex);
    }; };
    var seeResultsButtonText = React.useMemo(function () {
        if (!establishments || isFetching) {
            return "Voir les -- résidences";
        }
        if (establishments.length === 0) {
            return "Aucune résidence trouvée";
        }
        if (establishments.length === 1) {
            return "Voir la résidence";
        }
        return "Voir les " + establishments.length + " r\u00E9sidences";
    }, [establishments, isFetching]);
    return (_jsxs(_Fragment, { children: [_jsxs(Helmet, { children: [_jsx("title", { children: title }, void 0), _jsx("meta", { name: "description", content: description }, void 0), _jsx("meta", { property: "og:url", content: pathname }, void 0), _jsx("meta", { property: "og:title", content: title }, void 0), _jsx("meta", { property: "og:description", content: description }, void 0), _jsx("meta", { property: "og:type", content: "website" }, void 0), _jsx("link", { rel: "canonical", href: pathname }, void 0)] }, void 0), _jsx(Container, { children: _jsxs(Grid, __assign({ container: true, alignItems: "stretch", spacing: 2, sx: { mt: "32px", minHeight: "820px" }, id: "stepTitle" }, { children: [isMobile && _jsx(TitleBlock, {}, void 0), stepNumber === 1 && (_jsx(CriteriaStep, { form: form, setForm: handleChangeForm, handleValidateStep: handleValidateStep, nextStepButton: stayHome ? "Accéder à Arpavie@Dom" : "Continuer et choisir un lieu de résidence", exitLink: stayHome ? arpavieDomPathName : undefined, showError: showError }, void 0)), stepNumber === 2 && (_jsx(LocationStep, { form: form, setForm: handleChangeForm, handleValidateStep: handleValidateStep, nextStepButton: "Continuer et choisir des services compl\u00E9mentaires" }, void 0)), stepNumber === 3 && (_jsx(AdditionalCriteriaStep, { form: form, setForm: handleChangeForm, handleValidateStep: handleValidateStep, nextStepButton: "Continuer et d\u00E9finir un budget" }, void 0)), stepNumber === 4 && (_jsx(PriceStep, { form: form, setForm: handleChangeForm, handleValidateStep: handleValidateStep }, void 0)), _jsxs(Grid, __assign({ item: true, xs: 12, lg: 4, container: true, direction: "column", spacing: 2 }, { children: [!isMobile && _jsx(TitleBlock, {}, void 0), _jsx(Grid, __assign({ item: true, sx: { flex: 1 } }, { children: _jsxs(Paper, __assign({ sx: {
                                            p: { xs: "0 12px 25px", md: "0 20px 25px" },
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column"
                                        } }, { children: [_jsx(Grid, __assign({ container: true, direction: "column", sx: { flex: 1 } }, { children: _jsx(Summary, { form: form, stepIndex: stepNumber, onClickEditStep: handleClickEditStep }, void 0) }), void 0), _jsx(Box, __assign({ sx: { mt: "20px", textAlign: "center" } }, { children: !error ? (_jsx(Button, __assign({ variant: "cta", disabled: !establishments || establishments.length === 0 || isFetching, component: HashLink, to: "/residence" + location.search, endIcon: _jsx(SearchIcon, { sx: {
                                                            color: "colors.noir",
                                                            bgcolor: "colors.blanc",
                                                            border: "1px solid",
                                                            borderColor: "colors.blanc",
                                                            borderRadius: 20
                                                        } }, void 0) }, { children: seeResultsButtonText }), void 0)) : (_jsxs(Typography, __assign({ variant: "erreur", sx: { color: "colors.rouge_clair" } }, { children: ["Le service de recherche est actuellement indisponible.", _jsx("br", {}, void 0), "Veuillez nous excuser pour la g\u00EAne occasionn\u00E9e."] }), void 0)) }), void 0)] }), void 0) }), void 0)] }), void 0)] }), void 0) }, void 0)] }, void 0));
};
export default Search;
