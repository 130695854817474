import React from "react";
import PropTypes from "prop-types";
import MuiGrid from "@mui/material/Grid";

const Grid = props => {
  const { size, sizeXs, justify, items, spacing } = props;

  if (!items || items.length === 0) {
    return null;
  }

  let arrayItems = items;
  if (!Array.isArray(arrayItems)) {
    arrayItems = [items];
  }

  return (
    <MuiGrid container spacing={Number(spacing)} justifyContent={justify}>
      {arrayItems.map(item => (
        <MuiGrid key={item.key} item xs={parseInt(sizeXs, 10)} md={parseInt(size, 10)}>
          {item}
        </MuiGrid>
      ))}
    </MuiGrid>
  );
};

Grid.propTypes = {
  size: PropTypes.string,
  sizeXs: PropTypes.string,
  justify: PropTypes.string,
  spacing: PropTypes.string,
  items: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
};

Grid.defaultProps = {
  size: "6",
  sizeXs: "12",
  justify: "flex-start",
  spacing: "2",
  items: []
};

export default Grid;
