import React from "react";
import PropTypes from "prop-types";
import Icon from "cms/editableComponents/Icon";
import Link from "cms/editableComponents/Link";
import { alpha } from "@mui/material";

const SocialFooter = props => {
  const { socialNetworks } = props;

  return (
    <>
      {socialNetworks.map(social => {
        const { id, ...others } = social.icon;
        return (
          <Link
            url={social.url}
            external
            key={social.id}
            aria-label={social.title || social.url}
            sx={{
              bgcolor: alpha("#C4C4C4", 0.4),
              padding: 1.5,
              borderRadius: "50%",
              mr: 2.5,
              "& > i": { mt: 4.5, width: 20, height: 20, textAlign: "center" }
            }}
          >
            <Icon {...others} />
          </Link>
        );
      })}
    </>
  );
};

SocialFooter.propTypes = {
  socialNetworks: PropTypes.arrayOf(PropTypes.object)
};

SocialFooter.defaultProps = {
  socialNetworks: []
};

export default SocialFooter;
