import React from "react";
import PropTypes from "prop-types";
import makeOverridableStyles from "cms/utils/makeOverridableStyles";
import Typography from "@mui/material/Typography";
import Link from "cms/editableComponents/Link";
import Icon from "cms/editableComponents/Icon";

const useStyles = makeOverridableStyles("Attachment", theme => ({
  container: {
    position: "relative",
    padding: theme.spacing(3),
    backgroundColor: theme.palette.secondary.light,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main
    }
  },
  icon: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    "&> *": {
      color: theme.palette.primary.main,
      fontSize: "1.71rem"
    }
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: "600",
    display: "inline-block",
    paddingTop: theme.spacing(1)
  },
  title: props => ({
    marginBottom: props.description ? theme.spacing(1) : theme.spacing(2),
    marginTop: 0
  }),
  description: {
    marginBottom: theme.spacing(2.5)
  }
}));
const Attachment = props => {
  const { title, description, linkText, file, ...others } = props;
  const { name, url } = file || {};
  const { id, ...othersProps } = others;

  const classes = useStyles({ description });

  return (
    <div className={classes.container} {...othersProps}>
      {title && (
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
      )}
      {description && <Typography className={classes.description}>{description}</Typography>}
      <div>
        {url ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={url}
            style={{ textDecoration: "none" }}
            download={name || true}
            className={classes.link}
          >
            {linkText}
          </a>
        ) : (
          <div className={classes.link}>{linkText}</div>
        )}
      </div>
      <Link url={url} className={classes.icon} external>
        <Icon icon="eye" title="Voir le document" />
      </Link>
    </div>
  );
};

Attachment.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  linkText: PropTypes.string,
  file: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string
  })
};

Attachment.defaultProps = {
  title: null,
  linkText: null,
  description: null,
  file: {
    name: "",
    url: null
  }
};

export default Attachment;
