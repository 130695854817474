var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Paper, Typography } from "@mui/material";
import EstablishmentAddressInline from "components/EstablishmentAddressInline";
var AgencyEstablishmentCard = function (_a) {
    var establishment = _a.establishment;
    var name = establishment.name, address = establishment.address;
    return (_jsx(Paper, __assign({ sx: { borderRadius: 1, position: "relative" }, tabIndex: 0 }, { children: _jsxs(Box, __assign({ px: 2, pt: 2, pb: 3.5, sx: { minHeight: 115, position: "relative" } }, { children: [_jsx(Typography, __assign({ variant: "h5", sx: {
                        mb: 2.5,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }, title: name }, { children: name }), void 0), _jsx(Typography, __assign({ variant: "body1" }, { children: _jsx(EstablishmentAddressInline, { address: address }, void 0) }), void 0)] }), void 0) }), void 0));
};
export default AgencyEstablishmentCard;
