import contentsTypes from "cms/enums/contentsTypes";
import DynamicTemplate from "templates/dynamic/DynamicTemplate";
import { Templates } from "types/templates.enum";

export default {
  key: Templates.DYNAMIC,
  label: "Standard",
  templateImage: "/file-alt-solid.svg",
  Component: DynamicTemplate,
  initialContents: [
    {
      key: "dynamicPart",
      type: contentsTypes.DYNAMIC,
      value: ""
    }
  ]
};
