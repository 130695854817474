import React from "react";
import Attachment from "cms/editableComponents/Attachment";
import Block from "cms/components/Block";
import Button from "cms/editableComponents/Button";
import ExpansionPanel from "cms/editableComponents/ExpansionPanel";
import Image from "cms/editableComponents/Image";
import ImagesGallery from "cms/editableComponents/ImagesGallery";
import ImageBlock from "cms/editableComponents/ImageBlock";
import Container from "cms/editableComponents/Container";
import Link from "cms/editableComponents/Link";
import Numeric from "cms/editableComponents/Numeric";
import Text from "cms/editableComponents/Text";
import Timeline, { TimelineStep } from "cms/editableComponents/Timeline";
import contentsTypes from "cms/enums/contentsTypes";
import Accordion from "cms/editableComponents/Accordion";
import CodeBlock from "cms/editableComponents/CodeBlock";
import Video from "cms/editableComponents/Video";
import Grid from "cms/editableComponents/Grid";
import Margin from "cms/editableComponents/Margin";
import SubPage from "cms/editableComponents/SubPage";
import Glossary from "cms/editableComponents/Glossary";
import InfoBlock from "cms/editableComponents/InfoBlock";
import ExpansionText from "cms/editableComponents/ExpansionText";
import Box from "@mui/material/Box";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import { pageImageFiltersKey } from "cms/utils/imageFiltersUtil";

const sortFn = (item1, item2) => {
  return parseInt(item1.order, 10) - parseInt(item2.order, 10);
};
export const sortContentsProps = contents => {
  const newContents = contents.sort(sortFn);
  if (contents.children) {
    newContents.children = sortContentsProps(contents.children);
  }
  return newContents;
};

export const getDynamicComponent = (type, value, id, index) => {
  const getDynamicComponentFromProject = CmsHooks.getHook(HOOKS.templatePropsUtils_getDynamicComponent);

  if (type && value !== undefined) {
    if (typeof getDynamicComponentFromProject === "function") {
      const component = getDynamicComponentFromProject({
        type,
        value,
        id,
        index
      });
      if (component) {
        return component;
      }
    }
    switch (type) {
      case contentsTypes.TEXT:
        return <Text key={id}>{value}</Text>;
      case contentsTypes.EDITABLE_INPUT:
        return <pre style={{ fontFamily: "inherit", whiteSpace: "pre-wrap" }}>{value}</pre>;
      case contentsTypes.CHECKBOX:
        return value === "true";
      case contentsTypes.NUMERIC:
        return <Numeric number={value} />;
      case contentsTypes.BUTTON:
        if (typeof value === "object" && !Array.isArray(value)) {
          const { text, link, primary, color, btnIsOutlined, variant, ...others } = value;
          return (
            <Button
              {...others}
              key={id}
              color={color || (primary && "primary") || (primary === false && "secondary")}
              variant={variant || (btnIsOutlined && "outlined") || (btnIsOutlined === false && "contained")}
              component={Link}
              {...link}
            >
              {text}
            </Button>
          );
        }
        break;
      case contentsTypes.FILE:
      case contentsTypes.FILE_IMAGE:
        // eslint-disable-next-line no-case-declarations
        let file;
        try {
          file = JSON.parse(value);
        } catch (e) {
          file = typeof value === "object" ? value : {};
        }

        return file;
      case contentsTypes.PAGE:
        if (typeof value === "object") {
          const { image } = value;
          const { title, alt, url } = image || {};
          return {
            ...value,
            image: image ? getDynamicComponent(contentsTypes.IMAGE, { title, alt, file: { url } }) : null
          };
        }
        break;
      case contentsTypes.MENU_ITEM:
      case contentsTypes.MENU_GROUP:
      case contentsTypes.ACCORDION_PANEL:
      case contentsTypes.LINK:
      case contentsTypes.ICON:
      case contentsTypes.INPUT:
      case contentsTypes.INPUT_NUMBER:
      case contentsTypes.URL:
      case contentsTypes.SELECT:
      case contentsTypes.RADIO_GROUP:
      case contentsTypes.SHORT_TEXT:
      case contentsTypes.GLOSSARY_ITEM:
      case contentsTypes.TABS:
      case contentsTypes.TAB:
      case contentsTypes.CONTENTS_GROUP:
      case contentsTypes.CONTENTS_GROUPS_LIST:
      case contentsTypes.WARNING_BANNER:
      case contentsTypes.SOCIAL_NETWORK:
      case contentsTypes.SITE_SETTINGS:
      case contentsTypes.COLOR:
      case contentsTypes.SLIDER:
      case contentsTypes.CODE_BLOCK_TEXT:
      case contentsTypes.IMAGE_FILTERS:
      case contentsTypes.IMAGES:
      case contentsTypes.IMAGE_AS_FILE:
        return value;
      case contentsTypes.CODE_BLOCK:
        return <CodeBlock {...value} key={id} />;
      case contentsTypes.DYNAMIC:
        return (
          value &&
          React.Children.map(value.dynamicElements, child => child && <Block key={child.props.id}>{child}</Block>)
        );
      case contentsTypes.IMAGE: {
        return value && value.file && value.file.url && <Image {...value} />;
      }
      case contentsTypes.IMAGE_BLOCK: {
        return value && <ImageBlock {...value} key={id} />;
      }
      case contentsTypes.IMAGES_GALLERY: {
        return <ImagesGallery key={id} {...value} />;
      }
      case contentsTypes.TIMELINE:
        return <Timeline {...value} />;
      case contentsTypes.STEP_V2:
        return <TimelineStep key={id} {...value} />;
      case contentsTypes.STEP_V2_CONTENT:
        return (
          value &&
          React.Children.map(
            value.detailsElements,
            child =>
              child && (
                <Box mb={1} key={child.props.id}>
                  {child}
                </Box>
              )
          )
        );
      case contentsTypes.EXPANSION_PANEL:
        return <ExpansionPanel key={id} {...value} />;
      case contentsTypes.ATTACHMENT:
        return <Attachment key={id} {...value} />;
      case contentsTypes.ACCORDION:
        return <Accordion {...value} />;
      case contentsTypes.EXPANSION_PANEL_DETAILS:
        return (
          value &&
          React.Children.map(
            value.detailsElements,
            child =>
              child && (
                <Box py={1} key={child.props.id} id={child.props.id}>
                  {child}
                </Box>
              )
          )
        );
      case contentsTypes.SUBPAGE:
        // eslint-disable-next-line no-use-before-define
        return <SubPage key={id} page={formatPageData(value)} pageVersion={value} index={index} />;
      case contentsTypes.SUBPAGES_LIST: {
        return value && value.pages;
      }
      case contentsTypes.VIDEO:
        return <Video {...value} />;
      case contentsTypes.GRID:
        return <Grid key={id} {...value} />;
      case contentsTypes.CONTAINER:
        return (
          value && (
            <Container key={id} display="flex" flexDirection="row" {...value}>
              {value.containerElement}
            </Container>
          )
        );
      case contentsTypes.MARGIN:
        return <Margin {...value} />;
      case contentsTypes.GLOSSARY:
        return <Glossary {...value} />;
      case contentsTypes.INFO_BLOCK:
        return <InfoBlock {...value} />;
      case contentsTypes.INFO_BLOCK_LIST:
        return value && value.infos;
      case contentsTypes.EXPANSION_TEXT:
        return <ExpansionText key={id} {...value} />;
      default:
        return null;
    }
  }
  return value;
};

const generateTemplatePropsFromContents = contents => {
  const props = {};
  if (Array.isArray(contents)) {
    contents.forEach(content => {
      let { value } = content;
      const { key, children, type, id } = content;

      if (children && children.length) {
        value = generateTemplatePropsFromContents(children);
        value.id = id;
      }
      value = getDynamicComponent(type, value, id, contents.filter(c => c.key === key).indexOf(content));

      // if key already exists, then it will be an array
      if (typeof props[key] !== "undefined") {
        if (!Array.isArray(props[key])) {
          props[key] = [props[key]];
        }
        props[key].push(value);
      } else {
        props[key] = value;
      }
    });
  }
  return props;
};

export const formatPageData = (page = {}) => {
  const imageFiltersChild = page?.contents?.find(child => child.key === pageImageFiltersKey);
  return {
    ...page,
    title: <pre style={{ fontFamily: "inherit", whiteSpace: "pre-wrap" }}>{page.title}</pre>,
    image:
      page.image &&
      getDynamicComponent(contentsTypes.IMAGE, {
        file: { url: page.image.url },
        alt: page.image.alt,
        title: page.image.title,
        filters: imageFiltersChild ? generateTemplatePropsFromContents(imageFiltersChild.children) : undefined
      }),
    shortDescription:
      page.shortDescription &&
      getDynamicComponent(contentsTypes.TEXT, page.shortDescription, `shortdescription-${page.id}`),
    contents: generateTemplatePropsFromContents(page.contents)
  };
};

export default generateTemplatePropsFromContents;
