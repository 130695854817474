var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Grid, Box, Typography } from "@mui/material";
import { Templates } from "types/templates.enum";
import axios from "axios";
import SiteContext from "cms/components/SiteContext";
import ItemsCard from "components/ItemsCard";
import ItemCardComponent from "components/ItemCard";
import InfiniteScroll from "react-infinite-scroller";
import Loader from "cms/back-office/components/Loader";
import { createQueryParams, getQueryParams } from "cms/utils/urlUtils";
import { v4 as uuidV4 } from "uuid";
var JobOffersTemplate = function () {
    var site = useContext(SiteContext);
    var _a = useState([]), jobOffers = _a[0], setJobOffers = _a[1];
    var _b = useState(0), jobOffersNumber = _b[0], setJobOffersNumber = _b[1];
    var _c = useState([]), tags = _c[0], setTags = _c[1];
    var _d = useState(true), hasMoreResults = _d[0], setHasMoreResults = _d[1];
    var _e = useState(true), isLoading = _e[0], setIsLoading = _e[1];
    var _f = useLocation(), pathname = _f.pathname, search = _f.search;
    var history = useHistory();
    var size = 25;
    var queryParams = getQueryParams(search);
    useEffect(function () {
        axios
            .get("/getTemplatesTags", {
            params: {
                templates: Templates.JOB_OFFER,
                siteId: site === null || site === void 0 ? void 0 : site.id
            }
        })
            .then(function (response) {
            setTags(response.data || []);
        });
    }, [site.id]);
    var loadResults = useCallback(function (page) {
        setIsLoading(true);
        axios
            .get("/findPagesByTemplates", {
            params: {
                filter: queryParams.tag ? ["tags||cont||" + queryParams.tag] : [],
                templates: Templates.JOB_OFFER,
                siteId: site === null || site === void 0 ? void 0 : site.id,
                sortBy: "publishedDate",
                per_page: size,
                offset: (page - 1) * size,
                sortOrder: "DESC"
            }
        })
            .then(function (response) {
            var _a = response.data, _b = _a.data, data = _b === void 0 ? [] : _b, _c = _a.total, total = _c === void 0 ? 0 : _c;
            if (page === 1) {
                setJobOffers(data);
                setJobOffersNumber(total);
            }
            else {
                setJobOffers(__spreadArray(__spreadArray([], jobOffers, true), data, true));
            }
            setIsLoading(false);
            setHasMoreResults(data.length === size);
        });
    }, [jobOffers, queryParams.tag, site.id]);
    useEffect(function () {
        loadResults(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams.tag]);
    var itemsText = React.useMemo(function () {
        if (!jobOffersNumber || jobOffersNumber === 0) {
            return "";
        }
        if (jobOffersNumber === 1) {
            return "1 offre";
        }
        return jobOffersNumber + " offres";
    }, [jobOffersNumber]);
    var filterTags = function (tag) {
        if (tag === queryParams.tag) {
            history.push(pathname);
        }
        else {
            history.push(pathname + "?" + createQueryParams({
                tag: tag
            }));
        }
    };
    return (_jsx(Box, __assign({ px: { xs: 1, md: 3, lg: 5 }, py: 5 }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, lg: 8, order: { xs: 2, lg: 1 } }, { children: _jsx(Box, __assign({ sx: {
                            height: 825,
                            overflow: "auto",
                            textAlign: "center"
                        } }, { children: _jsx(InfiniteScroll, __assign({ loadMore: loadResults, hasMore: hasMoreResults, useWindow: false, loader: _jsx(Box, __assign({ mt: 6 }, { children: _jsx(Loader, {}, 0) }), void 0), pageStart: 1, initialLoad: false }, { children: _jsxs(Grid, __assign({ container: true, spacing: 1.5, sx: { pr: { xs: 0.5, lg: 2.5 }, pl: 0.5, pb: 1 } }, { children: [jobOffers === null || jobOffers === void 0 ? void 0 : jobOffers.map(function (jobOfferToDisplay) { return (_jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4, lg: 6, xl: 4 }, { children: _jsx(ItemCardComponent, { showItemTitle: "Consulter cette offre", item: jobOfferToDisplay }, void 0) }), uuidV4())); }), jobOffers.length === 0 && !isLoading && (_jsx(Grid, __assign({ item: true, xs: 12, textAlign: "center" }, { children: _jsx(Typography, { children: "Aucune offre" }, void 0) }), void 0))] }), void 0) }), void 0) }), void 0) }), void 0), _jsx(Grid, __assign({ item: true, xs: 12, lg: 4, order: { xs: 1, lg: 2 } }, { children: _jsx(ItemsCard, { itemsTitle: "Offres d'emploi ARPAVIE", recentTitle: "Offres r\u00E9centes", itemsText: itemsText, recentItems: jobOffers.slice(0, 4), tags: tags, onClickTag: filterTags, selectedTags: [queryParams.tag], isTitle: true }, void 0) }), void 0)] }), void 0) }), void 0));
};
export default JobOffersTemplate;
