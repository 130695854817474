var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import LazyLoad from "react-lazy-load";
import { getFilterStyle } from "cms/utils/imageFiltersUtil";
import { Box } from "@mui/material";
export var ImageContainer = function (props) {
    var ratio = props.ratio, children = props.children;
    if (typeof ratio !== "number" && Number.isNaN(parseFloat(ratio))) {
        return _jsx(_Fragment, { children: children }, void 0);
    }
    return (_jsx(Box, __assign({ sx: {
            pt: ratio + "%",
            position: "relative",
            flex: "1 1 0px",
            "@media print": {
                display: "none"
            }
        } }, { children: _jsx(Box, __assign({ sx: {
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%"
            } }, { children: children }), void 0) }), void 0));
};
var Image = function (props) {
    var file = props.file, title = props.title, alt = props.alt, position = props.position, disableLazy = props.disableLazy, filters = props.filters, style = props.style, others = __rest(props, ["file", "title", "alt", "position", "disableLazy", "filters", "style"]);
    var url = (file || {}).url;
    var img = (_jsx(Box, __assign({ component: "img", src: url, alt: alt, title: title, sx: {
            objectFit: "cover",
            height: "100%",
            width: "100%",
            objectPosition: position || "initial"
        }, style: __assign({ filter: getFilterStyle(filters) }, style) }, others), void 0));
    if (disableLazy) {
        return img;
    }
    return (_jsx(LazyLoad, __assign({ height: "100%", throttle: 100 }, { children: img }), void 0));
};
export default Image;
