var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
var ExpansionPanel = function (props) {
    var _a = props.summary, summary = _a === void 0 ? "" : _a, _b = props.details, details = _b === void 0 ? "" : _b, _c = props.expanded, expanded = _c === void 0 ? false : _c, onChange = props.onChange, others = __rest(props, ["summary", "details", "expanded", "onChange"]);
    var uniqueId = useState(Math.floor(Math.random() * 100000))[0];
    var _d = useState(expanded), isExpanded = _d[0], setIsExpanded = _d[1];
    useEffect(function () {
        if (expanded !== undefined) {
            setIsExpanded(expanded);
        }
    }, [expanded]);
    var handleChangePanel = function () {
        setIsExpanded(!isExpanded);
    };
    return (_jsxs(Accordion, __assign({ square: true, onChange: onChange ? onChange : handleChangePanel, expanded: isExpanded }, others, { children: [_jsx(AccordionSummary, __assign({ expandIcon: _jsx(ExpandMoreIcon, {}, void 0), "aria-controls": uniqueId + "-content", id: uniqueId + "-header" }, { children: _jsx(Typography, __assign({ variant: "onglet" }, { children: summary }), void 0) }), void 0), _jsx(AccordionDetails, { children: _jsx(Typography, __assign({ sx: { flex: "1" } }, { children: details }), void 0) }, void 0)] }), void 0));
};
export default ExpansionPanel;
