var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Tooltip, Typography } from "@mui/material";
import Icon from "cms/editableComponents/Icon";
var FeatureItem = function (props) {
    var feature = props.feature, _a = props.bgcolor, bgcolor = _a === void 0 ? "transparent" : _a, _b = props.color, color = _b === void 0 ? "colors.cyan_fonce" : _b, _c = props.borderStyle, borderStyle = _c === void 0 ? "solid" : _c, _d = props.borderWidth, borderWidth = _d === void 0 ? "2px" : _d, borderColor = props.borderColor, _e = props.alignItems, alignItems = _e === void 0 ? "center" : _e;
    var name = feature.name, icon = feature.icon, description = feature.description;
    return (_jsxs(Grid, __assign({ container: true, direction: "column", alignItems: alignItems }, { children: [_jsx(Grid, __assign({ item: true, sx: {
                    bgcolor: bgcolor,
                    borderWidth: borderWidth,
                    borderStyle: borderStyle,
                    borderColor: borderColor || color,
                    color: color,
                    height: 58,
                    width: 58,
                    borderRadius: "50%",
                    lineHeight: "58px",
                    verticalAlign: "middle",
                    textAlign: "center",
                    fontSize: 30
                } }, { children: _jsx(Icon, { icon: icon || "concierge-bell" }, void 0) }), void 0), name && (_jsx(Grid, __assign({ item: true }, { children: _jsx(Tooltip, __assign({ title: description }, { children: _jsxs(Typography, __assign({ sx: {
                            textAlign: "center",
                            mt: 1,
                            color: "colors.noir",
                            textDecoration: "underline",
                            display: "inline-block",
                            wordBreak: "break-word",
                            fontWeight: 600,
                            "& > i": {
                                color: "colors.bleu",
                                pl: 1
                            }
                        } }, { children: [name, description && _jsx(Icon, { icon: "info-circle", type: "fas" }, void 0)] }), void 0) }), void 0) }), void 0))] }), void 0));
};
export default FeatureItem;
