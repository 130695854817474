var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormHelperText, FormLabel, Grid, OutlinedInput, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
var FormInput = function (props) {
    var name = props.name, label = props.label, value = props.value, required = props.required, error = props.error, formHelperText = props.formHelperText, onChange = props.onChange, maxLength = props.maxLength;
    var characterCount = (value && value.length) || 0;
    return (_jsx(Grid, __assign({ item: true, xs: 12, md: name === "message" ? 12 : 6, container: true, direction: "column", alignItems: "flex-start" }, { children: _jsxs(FormControl, __assign({ error: error, sx: { width: "100%" } }, { children: [_jsx(Typography, __assign({ variant: "label", component: FormLabel, sx: { mb: "6px" }, required: required }, { children: label }), void 0), _jsx(OutlinedInput, { id: name, name: name, placeholder: label, value: value, onChange: onChange, multiline: name === "message", rows: 6, endAdornment: error && _jsx(ErrorIcon, { sx: { color: "colors.rouge_clair" } }, void 0), inputProps: { maxLength: maxLength }, sx: { position: "relative" } }, void 0), maxLength && (_jsx(Typography, __assign({ variant: "label_mini", sx: { position: "absolute", bottom: error ? 0 : -20, right: 0 } }, { children: "Caract\u00E8res : " + characterCount + "/" + maxLength }), void 0)), error && _jsx(FormHelperText, { children: formHelperText }, void 0)] }), void 0) }), void 0));
};
export default FormInput;
