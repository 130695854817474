import React from "react";
import { makeStyles } from "@mui/styles";

export default Component => {
  const useStyles = makeStyles(() => ({
    svgicon: {
      display: "inline-block",
      textAlign: "center",
      height: "1em",
      width: "1em",
      "&> svg": {
        verticalAlign: "middle"
      }
    }
  }));

  const SVGIconComponent = props => {
    const classes = useStyles();
    return (
      <div className={classes.svgicon}>
        <Component {...props} />
      </div>
    );
  };

  return SVGIconComponent;
};
