import React from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import Container from "cms/editableComponents/Container";

const useStyles = makeStyles({
  container: {
    border: "1px solid #999",
    padding: "16px",
    textAlign: "center"
  }
});

const ContainerPreview = () => {
  const c = useStyles();
  return (
    <div>
      <Box mb={3}>
        <Container justifyContent="flex-start" width="50">
          <div className={c.container}>Conteneur 50% aligné à gauche</div>
        </Container>
      </Box>
      <Box mb={3}>
        <Container className={c.container} justifyContent="center" width="30">
          <div className={c.container}>Conteneur 30% aligné au centre</div>
        </Container>
      </Box>
      <Box mb={3}>
        <Container className={c.container} justifyContent="flex-end" width="60">
          <div className={c.container}>Conteneur 60% aligné à droite</div>
        </Container>
      </Box>
    </div>
  );
};

export default ContainerPreview;
