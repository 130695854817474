import { EstablishmentFinancialAid } from "types/establishment/establishment-financial-aid.enum";
export var getFinancialAidLabel = function (benefit) {
    switch (benefit) {
        case EstablishmentFinancialAid.AL:
            return "Conventionné AL";
        case EstablishmentFinancialAid.APL:
            return "Conventionné APL";
        case EstablishmentFinancialAid.ASD:
            return "Habilité à l'aide sociale";
        case EstablishmentFinancialAid.PARTIAL_ASD:
            return "Partiellement habilité à l'aide sociale";
        default:
            return "";
    }
};
