var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Glider from "react-glider";
import { Box, Typography } from "@mui/material";
import CTANavigationLeftIcon from "components/CTANavigationLeftIcon";
import CTANavigationRight from "components/CTANavigationRight";
import ItemCardComponent from "components/ItemCard";
var ArticlesCarousel = function (_a) {
    var articles = _a.articles, _b = _a.title, title = _b === void 0 ? "D’autres articles pourraient vous intéresser" : _b;
    React.useEffect(function () {
        if (window) {
            // eslint-disable-next-line global-require
            require("glider-js/glider.min.css");
        }
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ variant: "h2", sx: { color: "colors.noir", mb: 2, mt: { xs: 7, md: 13.5 } } }, { children: title }), void 0), _jsx(Box, __assign({ px: 3 }, { children: Array.isArray(articles) && articles.length > 0 && (_jsx(Glider, __assign({ skipTrack: true, draggable: true, hasArrows: true, slidesToScroll: 1, slidesToShow: "auto", dragVelocity: 1.5, itemWidth: 400, exactWidth: true, iconLeft: _jsx(CTANavigationLeftIcon, { sx: { color: "colors.bleu_fonce" } }, void 0), iconRight: _jsx(CTANavigationRight, { sx: { color: "colors.bleu_fonce" } }, void 0) }, { children: _jsx("div", __assign({ className: "grider-track" }, { children: articles.map(function (article) { return (_jsx(Box, __assign({ mx: 3.8125, mb: { xs: 7, mb: 11 } }, { children: _jsx(ItemCardComponent, { showItemTitle: "Lire cet article", item: article, imagesAreInGallery: true }, void 0) }), article.id)); }) }), void 0) }), void 0)) }), void 0)] }, void 0));
};
export default ArticlesCarousel;
