var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMutation, useQuery } from "react-query";
import AdminPageVersionsService from "cms/back-office/services/AdminPageVersionsService";
import { stringToPath } from "cms/utils/urlUtils";
import { GenericService } from "services/generic.service";
import establishmentTemplate from "templates/establishment/establishment";
import { resetArpavieSiteCache } from "services/arpavie-site.service";
export var entityName = "establishments";
var EstablishmentService = /** @class */ (function (_super) {
    __extends(EstablishmentService, _super);
    function EstablishmentService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EstablishmentService;
}(GenericService));
var service = new EstablishmentService(entityName);
export var useEstablishment = function (establishmentId, params, options) {
    if (params === void 0) { params = {}; }
    if (options === void 0) { options = {}; }
    return useQuery([entityName, establishmentId], function () { return service.findOne(establishmentId, params); }, options);
};
export var useEstablishmentList = function (params, options) {
    if (params === void 0) { params = {
        params: {
            per_page: 200
        }
    }; }
    if (options === void 0) { options = {}; }
    return useQuery([entityName], function () { return service.findMany(params); }, options);
};
export var useEstablishmentCreate = function (options) {
    if (options === void 0) { options = {}; }
    return useMutation(function (establishment) {
        return service.create(establishment).then(function (response) {
            resetArpavieSiteCache();
            return response;
        });
    }, options);
};
export var useEstablishmentUpdate = function (config) {
    if (config === void 0) { config = {}; }
    return useMutation(function (establishment) {
        return service.update(establishment).then(function (response) {
            resetArpavieSiteCache();
            return response;
        });
    }, config);
};
export var useEstablishmentDelete = function (config) {
    if (config === void 0) { config = {}; }
    return useMutation(function (establishment) {
        return service.delete(establishment.id).then(function (response) {
            resetArpavieSiteCache();
            return response;
        });
    }, config);
};
export var createInitalPageVersion = function (establishment) {
    var cmsPageId = establishment.cmsPageId, name = establishment.name, id = establishment.id;
    var version = {
        page: { id: cmsPageId },
        path: stringToPath(name),
        template: establishmentTemplate.key,
        title: name,
        contents: __spreadArray(__spreadArray([], establishmentTemplate.initialContents.filter(function (content) { return content.key !== "establishmentId"; }), true), [
            {
                key: "establishmentId",
                value: id
            }
        ], false)
    };
    return AdminPageVersionsService.create(version);
};
export default service;
