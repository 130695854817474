import React from "react";
import PropTypes from "prop-types";
import MuiSnackbar from "@mui/material/Snackbar";
import { Alert } from "@docaposte-agility/da-design-system";

export default function Snackbar(props) {
  const { open, onClose, message, severity, duration, anchorOrigin } = props;

  const handleClose = React.useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      onClose(event, reason);
    },
    [onClose]
  );

  return (
    <MuiSnackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={duration}
      message={
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      }
    />
  );
}

Snackbar.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  severity: PropTypes.oneOf(["error", "info", "success", "warning"]),
  duration: PropTypes.number,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(["top", "bottom"]).isRequired,
    horizontal: PropTypes.oneOf(["left", "center", "right"]).isRequired
  })
};

Snackbar.defaultProps = {
  open: false,
  message: "",
  severity: "success",
  duration: 3000,
  anchorOrigin: undefined
};
