var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Glider from "react-glider";
import { Box, Typography } from "@mui/material";
import CTANavigationLeftIcon from "components/CTANavigationLeftIcon";
import CTANavigationRight from "components/CTANavigationRight";
import EstablishmentCard from "components/EstablishmentCard";
import { useEstablishmentList } from "services/establishments.service";
import { useEnhanceListWithPageVersions } from "services/pageversion.service";
var EstablishmentsCarousel = function (_a) {
    var _b = _a.currentEstablishmentsIds, currentEstablishmentsIds = _b === void 0 ? [] : _b, _c = _a.imageRatio, imageRatio = _c === void 0 ? 90 : _c, _d = _a.title, title = _d === void 0 ? "D’autres résidences pourraient vous convenir" : _d;
    React.useEffect(function () {
        if (window) {
            // eslint-disable-next-line global-require
            require("glider-js/glider.min.css");
        }
    }, []);
    var _e = React.useState(null), container = _e[0], setContainer = _e[1];
    var containerRef = React.useCallback(function (node) {
        if (node !== null) {
            setContainer(node);
        }
    }, []);
    var itemWidth = container === null || container === void 0 ? void 0 : container.clientWidth;
    var establishmentsPagination = useEstablishmentList().data;
    var _f = (establishmentsPagination || {}).data, establishmentsResults = _f === void 0 ? [] : _f;
    var establishmentsHighlighted = React.useMemo(function () { return (establishmentsResults && establishmentsResults.filter(function (e) { return e.isHighlighted; })) || []; }, [establishmentsResults]);
    var _g = useEnhanceListWithPageVersions(establishmentsHighlighted.filter(function (e) { return !currentEstablishmentsIds.includes(e.id); })).data, establishments = _g === void 0 ? [] : _g;
    var establishmentsForListing = establishments.slice(0, 8);
    if (!(establishments === null || establishments === void 0 ? void 0 : establishments.length)) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ variant: "h2", sx: { mb: 2, mt: { xs: 10, lg: 23.5 } } }, { children: title }), void 0), _jsx(Box, __assign({ px: 3, ref: containerRef }, { children: Array.isArray(establishmentsForListing) && establishmentsForListing.length > 0 && (_jsx(Glider, __assign({ skipTrack: true, draggable: true, hasArrows: true, slidesToScroll: 1, slidesToShow: "auto", dragVelocity: 1.5, itemWidth: Math.min(400, itemWidth ? itemWidth - 50 : Infinity), exactWidth: true, iconLeft: _jsx(CTANavigationLeftIcon, { sx: { color: "colors.bleu_fonce" } }, void 0), iconRight: _jsx(CTANavigationRight, { sx: { color: "colors.bleu_fonce" } }, void 0) }, { children: _jsx("div", __assign({ className: "grider-track" }, { children: establishmentsForListing.map(function (establishment) { return (_jsx(Box, __assign({ mx: 2, mb: 2 }, { children: _jsx(EstablishmentCard, { establishment: establishment, imageRatio: imageRatio }, void 0) }), establishment.id)); }) }), void 0) }), void 0)) }), void 0)] }, void 0));
};
export default EstablishmentsCarousel;
