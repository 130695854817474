import axios from "axios";
import SnackbarUtil from "da-ged-web/utils/SnackbarUtil";
import axiosCatchError from "./AxiosCatchErrorImplement";
import gedMiddlewareEndpoint from "./gedMiddlewareEndpoint";

const i18n = { t: m => m };

export default class FileService {
  static createFile = ({ file, nodeId } = {}, options = {}) => {
    const formData = new FormData();
    formData.append("file", file);
    return axios
      .post(`${gedMiddlewareEndpoint}${nodeId ? `/folders/${nodeId}` : ""}/files`, formData, options)
      .catch(error => {
        const customError = axiosCatchError(error);
        let message = "";

        if (customError.error === "file.mimetype.not.allowed") {
          message = "Erreur, le format du fichier n'est pas pris en charge";
        } else {
          message = "Erreur lors du téléversement du fichier";
        }

        throw new Error(message);
      });
  };

  static getPreview = nodeId => {
    return axios
      .get(`${gedMiddlewareEndpoint}/files/${nodeId}/preview`, {
        responseType: "blob" // Force to receive data in a Blob Format
      })
      .catch(error => axiosCatchError(error));
  };

  static deleteFile = nodeId => {
    return axios.delete(`${gedMiddlewareEndpoint}/files/${nodeId}`).catch(error => axiosCatchError(error));
  };

  static trulyDeleteFile = nodeId => {
    return axios.delete(`${gedMiddlewareEndpoint}/trash/files/${nodeId}`).catch(error => axiosCatchError(error));
  };

  static renameFile = ({ fileId, name } = {}) => {
    return axios
      .patch(`${gedMiddlewareEndpoint}/files/${fileId}`, {
        name
      })
      .then(response => {
        if (response) {
          SnackbarUtil.setSnackbar({
            status: "success",
            message: i18n.t("Node renamed")
          });
        }
      })
      .catch(error => {
        const customError = axiosCatchError(error);

        if (customError.error === "name.already.exist") {
          throw new Error(i18n.t("File exist"));
        } else {
          SnackbarUtil.setSnackbar({
            status: "error",
            message: i18n.t("Node renamed error")
          });
          throw new Error();
        }
      });
  };

  static downloadFile = fileId => {
    return axios
      .get(`${gedMiddlewareEndpoint}/files/${fileId}?alt=media`)
      .then(archiveUrl => {
        return axios
          .get(`${gedMiddlewareEndpoint}${archiveUrl.data.downloadUrl}`, {
            responseType: "blob"
          })
          .then(response => {
            return response.data;
          })
          .catch(error => axiosCatchError(error));
      })
      .catch(error => axiosCatchError(error));
  };

  static moveFile = ({ fileId, parent, message } = {}) => {
    return axios
      .patch(`${gedMiddlewareEndpoint}/files/${fileId}`, {
        parentId: parent?.id || null
      })
      .then(response => {
        if (response) {
          SnackbarUtil.setSnackbar({
            status: "success",
            message
          });
        }
      })
      .catch(error => {
        const moveError = axiosCatchError(error);
        if (moveError && moveError.error === "folder.not.found") {
          throw new Error(i18n.t("Folder not found"));
        } else if (moveError && moveError.error === "name.already.exist") {
          throw new Error(i18n.t("File isn't move"));
        } else {
          SnackbarUtil.setSnackbar({
            status: "error",
            message: i18n.t("File operation impossible")
          });
          throw new Error();
        }
      });
  };

  static duplicateFile = ({ fileId, fileName } = {}) => {
    return axios
      .post(`${gedMiddlewareEndpoint}/files/${fileId}/copy`, {
        name: i18n.t("Copy of") + fileName
      })
      .catch(error => axiosCatchError(error));
  };

  static restoreFile = nodeId => {
    return new Promise(async resolve => {
      try {
        await axios.patch(`${gedMiddlewareEndpoint}/trash/files/${nodeId}`, {
          deleted: false
        });
        resolve(true);
      } catch (error) {
        resolve(false);
      }
    });
  };

  static publish = ({ file, pageVersion }) => {
    const { id: fileId } = file;
    const { id: pageVersionId, page = {} } = pageVersion || {};
    const { pageId = page.id } = pageVersion || {};

    let publicPath = "/tmp";
    if (pageVersionId && pageId) {
      publicPath = `/${pageId}/${pageVersionId}`;
    }
    return axios
      .post(`${gedMiddlewareEndpoint}/files/${fileId}/publish`, {
        publicPath
      })
      .catch(error => axiosCatchError(error));
  };
}
