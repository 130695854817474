var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Paper, Typography, Link } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import ItemTags from "components/ItemTags";
import MultilineEllipsis from "components/MultilineEllipsis";
var ItemsCard = function (_a) {
    var itemsTitle = _a.itemsTitle, recentTitle = _a.recentTitle, itemsText = _a.itemsText, recentItems = _a.recentItems, _b = _a.tags, tags = _b === void 0 ? [] : _b, onClickTag = _a.onClickTag, _c = _a.selectedTags, selectedTags = _c === void 0 ? [] : _c, _d = _a.isTitle, isTitle = _d === void 0 ? false : _d;
    React.useEffect(function () {
        if (window) {
            // eslint-disable-next-line global-require
            require("simplebar/dist/simplebar.min.css");
        }
    }, []);
    var handleClickTag = function (tag) {
        if (onClickTag) {
            onClickTag(tag);
        }
    };
    return (_jsxs(Box, __assign({ sx: { mt: { xs: 7, md: 0 } } }, { children: [_jsxs(Paper, __assign({ square: true, sx: { borderRadius: 1, mb: 2.5, px: 2, py: 4 } }, { children: [_jsx(Typography, __assign({ component: isTitle ? "h1" : "h2", variant: "h2", sx: { mb: 1 } }, { children: itemsTitle }), void 0), _jsx(Typography, __assign({ variant: "subtitle1" }, { children: itemsText }), void 0)] }), void 0), _jsxs(Paper, __assign({ square: true, sx: { borderRadius: 1, px: 2, py: 4 } }, { children: [_jsx(Box, __assign({ sx: { mb: 0.5 } }, { children: _jsx(Typography, __assign({ variant: "onglet" }, { children: recentTitle }), void 0) }), void 0), recentItems === null || recentItems === void 0 ? void 0 : recentItems.map(function (item) { return (_jsx(Box, __assign({ sx: { mt: 1.625 } }, { children: _jsx(Link, __assign({ component: HashLink, to: item.fullPath }, { children: _jsx(MultilineEllipsis, __assign({ limit: 1, title: item.title }, { children: item.title }), void 0) }), void 0) }), item.id)); })] }), void 0), tags.length > 0 && (_jsxs(Paper, __assign({ square: true, sx: { borderRadius: 1, px: 2, py: 4, mt: 2.5 } }, { children: [_jsx(Typography, __assign({ variant: "onglet" }, { children: "Sujets" }), void 0), _jsx(Box, __assign({ sx: { mt: 1 } }, { children: tags.length > 0 ? (_jsx(ItemTags, { tags: tags, onClickTag: handleClickTag, selectedTags: selectedTags }, void 0)) : (_jsx(Typography, __assign({ variant: "subtitle1" }, { children: "Aucun sujet" }), void 0)) }), void 0)] }), void 0))] }), void 0));
};
export default ItemsCard;
