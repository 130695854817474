var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useState } from "react";
import useTheme from "@mui/material/styles/useTheme";
import { Button, Typography, Grid, Paper, Box } from "@mui/material";
import Icon from "cms/editableComponents/Icon";
import { HashLink } from "react-router-hash-link";
import axios from "axios";
import { Templates } from "types/templates.enum";
import SiteContext from "cms/components/SiteContext";
import { useQuery } from "react-query";
var CustomCard = function (_a) {
    var _b;
    var _c, _d, _e, _f, _g;
    var _h = _a.id, id = _h === void 0 ? "" : _h, _j = _a.type, type = _j === void 0 ? "medicalSupport" : _j, _k = _a.title, title = _k === void 0 ? "" : _k, others = __rest(_a, ["id", "type", "title"]);
    var theme = useTheme();
    var site = useContext(SiteContext);
    var cardDescriptions = {
        medicalSupport: (_jsxs("p", { children: ["Vous avez besoin ", _jsx("strong", { children: "d\u2019une assistance m\u00E9dicalis\u00E9e 24/24" }, void 0), ". Il vous faut donc un h\u00E9bergement dans une de nos r\u00E9sidences de type maison de retraite m\u00E9dicalis\u00E9e, EHPAD."] }, void 0)),
        needAssist: (_jsxs("p", { children: ["Vous avez besoin ", _jsx("strong", { children: "d\u2019une assistance non m\u00E9dicalis\u00E9e 24/24" }, void 0), ". Il vous faut donc un h\u00E9bergement dans une de nos r\u00E9sidences de type maison de retraite autonomie."] }, void 0)),
        stayHome: (_jsxs("p", { children: ["Vous avez besoin ", _jsx("strong", { children: "d\u2019un accompagnement chez vous" }, void 0), ". Il vous faut donc un service d\u2019accompagnement pour vous permettre de rester \u00E0 votre domicile."] }, void 0))
    };
    var _l = useState("/"), arpavieDomPathName = _l[0], setArpavieDomPathName = _l[1];
    var _m = useQuery(["arpavie_dom_page", site === null || site === void 0 ? void 0 : site.id], function () {
        return axios.get("/findPagesByTemplates", {
            params: {
                templates: Templates.ARPAVIE_DOM,
                siteId: site === null || site === void 0 ? void 0 : site.id,
                sortBy: "publishedDate",
                sortOrder: "DESC"
            }
        });
    }), dataArpavieDomPage = _m.data, loading = _m.isFetching;
    React.useEffect(function () {
        var _a, _b, _c;
        if (!loading) {
            var newPathname = (_c = (_b = (_a = dataArpavieDomPage === null || dataArpavieDomPage === void 0 ? void 0 : dataArpavieDomPage.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.find(function (elt) { return elt.fullPath; })) === null || _c === void 0 ? void 0 : _c.fullPath;
            if (newPathname) {
                setArpavieDomPathName(newPathname);
            }
        }
    }, [dataArpavieDomPage, loading]);
    return (_jsx(Paper, __assign({ square: true, sx: {
            height: "100%",
            position: "relative",
            borderRadius: 1
        } }, others, { children: _jsxs(Grid, __assign({ container: true, direction: "column", justifyContent: "space-between", sx: {
                height: "100%",
                transition: "background-color ease-in 250ms",
                borderRadius: 1,
                px: { xs: 3, lg: 4, xl: 6.75 },
                pt: 3.5,
                pb: 2.5,
                textAlign: "center",
                ".custom-card-description, .custom-card-button": {
                    display: { lg: "none" }
                },
                ".custom-card-icon": {
                    display: { xs: "none", lg: "flex" }
                },
                bgcolor: "colors.blanc",
                "&:hover": {
                    lg: {
                        position: "absolute",
                        height: "auto",
                        top: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: (_c = theme.palette.colors) === null || _c === void 0 ? void 0 : _c.recherche_clair_2,
                        justifyContent: "flex-start",
                        ".custom-card-description, .custom-card-button": {
                            display: "block"
                        },
                        ".custom-card-icon": {
                            display: "none"
                        }
                    }
                }
            } }, { children: [_jsxs(Grid, __assign({ item: true }, { children: [_jsx(Typography, __assign({ variant: "h3", sx: (_b = {
                                    color: "colors.recherche_fonce",
                                    mb: 2
                                },
                                _b[theme.breakpoints.down("lg")] = {
                                    fontSize: "1.428rem"
                                },
                                _b) }, { children: title }), void 0), _jsx(Box, __assign({ sx: {
                                fontSize: { lg: "1.429rem" },
                                lineHeight: "1.741rem",
                                mb: 2
                            }, className: "custom-card-description" }, { children: cardDescriptions[type] }), void 0)] }), void 0), _jsx(Grid, __assign({ item: true, sx: {
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "1.429rem"
                    }, className: "custom-card-icon" }, { children: _jsx(Icon, { icon: "eye", background: (_d = theme.palette.colors) === null || _d === void 0 ? void 0 : _d.recherche, color: (_e = theme.palette.colors) === null || _e === void 0 ? void 0 : _e.blanc }, void 0) }), void 0), _jsx(Grid, __assign({ item: true, className: "custom-card-button" }, { children: _jsx(Button, __assign({ variant: "cta", to: type === "stayHome" ? arpavieDomPathName : "/recherche-detaillee?" + type + "=1", component: HashLink, endIcon: _jsx(Icon, { icon: "search", background: (_f = theme.palette.colors) === null || _f === void 0 ? void 0 : _f.blanc, color: (_g = theme.palette.colors) === null || _g === void 0 ? void 0 : _g.noir }, void 0) }, { children: type === "stayHome" ? "Quels sont vos besoins ?" : "Trouver ma résidence" }), void 0) }), void 0)] }), void 0) }), id));
};
export default CustomCard;
