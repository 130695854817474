var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { Grid, Slider, Tooltip } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import getEnvironmentPromise from "cms/back-office/services/getEnvironment";
import Label from "components/Label";
export var summaryTitle = "Prix";
var SearchSlider = function (props) {
    var form = props.form, setForm = props.setForm, filter = props.filter;
    var budgetMin = form.budgetMin, budgetMax = form.budgetMax;
    var _a = React.useState(["0", "5000"]), budgetRange = _a[0], setBudgetRange = _a[1];
    React.useEffect(function () {
        getEnvironmentPromise().then(function (_a) {
            var search_budget = _a.search_budget;
            if (search_budget) {
                setBudgetRange(search_budget.split(","));
            }
        });
    }, []);
    var handleChangePrices = useCallback(function (e, newPrices) {
        setForm(__assign(__assign({}, form), { budgetMin: newPrices[0], budgetMax: newPrices[1] }));
    }, [form, setForm]);
    React.useEffect(function () {
        if (budgetMax === "0") {
            handleChangePrices(null, budgetRange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var marks = [
        {
            value: Number(budgetRange[0]),
            label: Number(budgetRange[0]) + " \u20AC"
        },
        {
            value: Number(budgetRange[1]),
            label: Number(budgetRange[1]) + " \u20AC"
        }
    ];
    return (_jsxs(Grid, __assign({ container: true, alignItems: "center" }, { children: [_jsx(Slider, { "aria-label": "Budget", name: "budget", value: [Number(budgetMin), Number(budgetMax)], min: Number(budgetRange[0]), max: Number(budgetRange[1]), marks: marks, step: 100, onChange: handleChangePrices, valueLabelDisplay: budgetMax !== "0" ? "on" : "off", valueLabelFormat: function (val) { return _jsxs(Label, { children: [val, " \u20AC"] }, void 0); }, sx: {
                    mt: "42px",
                    mr: { xs: "40px", lg: "25px", xl: "40px" },
                    maxWidth: { xs: "230px", sm: "300px" },
                    ml: filter ? "30px" : 0
                } }, void 0), _jsx(Tooltip, __assign({ title: "Annuler la saisie", placement: "top", arrow: true }, { children: _jsx(HighlightOffIcon, { sx: {
                        color: "colors.cyan_fonce",
                        fontSize: "2rem",
                        cursor: "pointer"
                    }, onClick: function () { return handleChangePrices(null, [0, 0]); } }, void 0) }), void 0)] }), void 0));
};
export default SearchSlider;
