var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import Box from "@mui/material/Box";
import ExpansionPanel from "cms/editableComponents/ExpansionPanel";
var Accordion = function (props) {
    var _a = props.panels, panels = _a === void 0 ? [] : _a;
    var newPanels = Array.isArray(panels) ? panels : [panels];
    var _b = useState(-1), expanded = _b[0], setExpanded = _b[1];
    var handleChange = function (index) {
        setExpanded(index === expanded ? -1 : index);
    };
    return (_jsx(_Fragment, { children: newPanels.map(function (panel, index) { return (_jsx(Box, __assign({ sx: { mb: index === expanded ? 4 : 2 } }, { children: _jsx(ExpansionPanel, __assign({}, panel, { expanded: index === expanded, onChange: function () { return handleChange(index); } }), void 0) }), panel.id)); }) }, void 0));
};
export default Accordion;
