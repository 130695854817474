import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import makeOverridableStyles from "cms/utils/makeOverridableStyles";

const useStyles = props =>
  makeOverridableStyles("Icon", () => ({
    root: {
      color: props.color || undefined,
      ...((props.background && {
        backgroundColor: props.background,
        width: "2em",
        height: "2em",
        borderRadius: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }) ||
        {})
    }
  }))();

const Icon = props => {
  const { icon, type, className, color, background, ...others } = props;

  const classes = useStyles({ color, background });

  return <i {...others} className={classnames(className, classes.root, `${type} fa-${icon}`)} />;
};

Icon.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(["fas", "far", "fal", "fad", "fab"]),
  className: PropTypes.string,
  color: PropTypes.string,
  background: PropTypes.string
};

Icon.defaultProps = {
  icon: "",
  title: "",
  type: "fal",
  className: null,
  color: undefined,
  background: undefined
};

export default Icon;
