var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
var SearchProximityElements = function (props) {
    var form = props.form, setForm = props.setForm, searchEnvironmentFeatures = props.searchEnvironmentFeatures;
    var proximityElements = form.proximityElements;
    var handleSelectProximityElements = function (e) {
        var value = e.target.value;
        var newValue = proximityElements.includes(value)
            ? proximityElements.filter(function (type) { return type !== value; })
            : __spreadArray(__spreadArray([], proximityElements, true), [value], false);
        setForm(__assign(__assign({}, form), { proximityElements: newValue }));
    };
    return (_jsx(Grid, __assign({ container: true, direction: "column" }, { children: searchEnvironmentFeatures.map(function (feature) { return (_jsx(FormControlLabel, { control: _jsx(Checkbox, { value: feature.id, checked: proximityElements.includes(feature.id), onChange: handleSelectProximityElements }, void 0), label: _jsx(Typography, __assign({ variant: "donnee" }, { children: feature.name }), void 0) }, feature.id)); }) }), void 0));
};
export default SearchProximityElements;
