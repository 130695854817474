import ResultSearchEstablishment from "./ResultSearchEstablishment";
import Search from "./Search/Search";

export default [
  {
    path: "/recherche-detaillee",
    component: Search
  },
  {
    path: "/residence",
    component: ResultSearchEstablishment,
    exact: true
  }
];
