var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImageContainer } from "cms/editableComponents/Image";
import { Paper, Typography, Box } from "@mui/material";
var SpeechCard = function (_a) {
    var _b = _a.id, id = _b === void 0 ? "" : _b, _c = _a.title, title = _c === void 0 ? "" : _c, _d = _a.speech, speech = _d === void 0 ? "" : _d, _e = _a.photo, photo = _e === void 0 ? null : _e, _f = _a.name, name = _f === void 0 ? "" : _f, _g = _a.position, position = _g === void 0 ? "" : _g, others = __rest(_a, ["id", "title", "speech", "photo", "name", "position"]);
    return (_jsxs(Paper, __assign({ square: true, sx: {
            borderRadius: 1,
            px: 3.5,
            pt: 2.5,
            pb: 5.75
        } }, { children: [_jsx(Typography, __assign({ component: "h2", variant: "h3", sx: { mb: 3.5 } }, { children: title }), void 0), _jsx(Typography, __assign({ variant: "telephone" }, { children: speech }), void 0), _jsx(Box, __assign({ sx: { mb: 2.375, display: "flex", justifyContent: "center" } }, { children: _jsx(Box, __assign({ sx: {
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                        overflow: "hidden"
                    } }, { children: _jsx(ImageContainer, __assign({ ratio: "" }, { children: photo }), void 0) }), void 0) }), void 0), _jsxs(Box, __assign({ sx: { textAlign: "center" } }, { children: [_jsx(Typography, __assign({ variant: "subtitle1" }, { children: name }), void 0), _jsx(Box, { children: position }, void 0)] }), void 0)] }), void 0));
};
export default SpeechCard;
