var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import Helmet from "react-helmet";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { HashLink } from "react-router-hash-link";
import Link from "@mui/material/Link";
var NotFound = function () {
    var trackPageView = useMatomo().trackPageView;
    var title = "Page non trouvée";
    var description = "La page que vous recherchez n’a pas été trouvée.";
    useEffect(function () {
        trackPageView({ documentTitle: title });
    }, [trackPageView]);
    return (_jsxs(_Fragment, { children: [_jsxs(Helmet, { children: [_jsx("title", { children: title }, void 0), _jsx("meta", { name: "description", content: description }, void 0), _jsx("meta", { property: "og:title", content: title }, void 0), _jsx("meta", { property: "og:description", content: description }, void 0), _jsx("meta", { property: "og:type", content: "website" }, void 0)] }, void 0), _jsx(Container, { children: _jsxs(Grid, __assign({ container: true, direction: "column", justifyContent: "space-evenly", alignItems: "center", sx: {
                        minHeight: { xs: "250px", md: "400px" },
                        marginTop: { xs: 0, md: 21, lg: 0 }
                    } }, { children: [_jsx(Typography, __assign({ variant: "h2", sx: { textAlign: "center" } }, { children: "La page que vous demandez n'existe pas." }), void 0), _jsx(Link, __assign({ component: HashLink, to: "/", sx: {
                                textDecoration: "underline",
                                color: "colors.cyan",
                                mt: "20px"
                            } }, { children: _jsx(Typography, __assign({ variant: "subtitle1" }, { children: "Revenir \u00E0 la page d'accueil" }), void 0) }), void 0)] }), void 0) }, void 0)] }, void 0));
};
export default NotFound;
