var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useTheme from "@mui/material/styles/useTheme";
import Icon from "cms/editableComponents/Icon";
import Text from "cms/editableComponents/Text";
import { Box } from "@mui/material";
var PathOption = function (_a) {
    var _b;
    var _c = _a.id, id = _c === void 0 ? "" : _c, _d = _a.transportType, transportType = _d === void 0 ? "car" : _d, _e = _a.description, description = _e === void 0 ? "" : _e, others = __rest(_a, ["id", "transportType", "description"]);
    var theme = useTheme();
    return (_jsxs(Box, __assign({ sx: { display: "flex" } }, others, { children: [_jsx(Box, __assign({ sx: { mt: 2, mr: 2.75 } }, { children: _jsx(Icon, { icon: transportType, color: (_b = theme.palette.colors) === null || _b === void 0 ? void 0 : _b.cyan_fonce }, void 0) }), void 0), _jsx(Box, __assign({ sx: { width: "100%" } }, { children: _jsx(Text, { children: description }, void 0) }), void 0)] }), id));
};
export default PathOption;
