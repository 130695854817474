var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import uuidv4 from "uuid/v4";
var InfosBlock = function (props) {
    var accordions = props.accordions;
    var _a = React.useState(""), currentExpanded = _a[0], setCurrentExpanded = _a[1];
    var handleChangePanel = function (title) { return function (event, isExpanded) {
        setCurrentExpanded(isExpanded ? title : "");
    }; };
    var alwaysOpen = accordions.length === 1;
    return (_jsx(_Fragment, { children: accordions.map(function (accordionInfo) {
            var title = accordionInfo.title, text = accordionInfo.text;
            var isExpanded = currentExpanded === title;
            var id = uuidv4().toString();
            return (_jsxs(Accordion, __assign({ expanded: alwaysOpen || isExpanded, disabled: alwaysOpen, onChange: handleChangePanel(title) }, { children: [_jsx(AccordionSummary, __assign({ expandIcon: !alwaysOpen && _jsx(ExpandMoreIcon, {}, void 0), "aria-controls": id + "-content", id: id + "-header" }, { children: _jsx(Typography, __assign({ variant: "onglet" }, { children: title }), void 0) }), void 0), _jsx(AccordionDetails, { children: _jsx(Typography, __assign({ sx: { flex: "1" } }, { children: text }), void 0) }, void 0)] }), title));
        }) }, void 0));
};
export default InfosBlock;
