import React, { useContext, useState, useEffect, Fragment, useCallback } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { HashLink } from "react-router-hash-link";
import {
  Box,
  Button,
  ClickAwayListener,
  Container,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Link as MuiLink,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import SearchIcon from "@mui/icons-material/Search";
import SiteContext from "cms/components/SiteContext";
import Link from "cms/editableComponents/Link";
import Icon from "cms/editableComponents/Icon";
import PageVersionContext from "cms/components/PageVersionContext";
import SectionService from "cms/services/SectionService";
import generateTemplatePropsFromContents from "cms/utils/templatePropsUtils";
import { getQueryParams, createQueryParams } from "cms/utils/urlUtils";
import { getEstablishmentSearchParamsFromQueries } from "utils/establishmentSearchUtils";
import CustomPopper from "components/CustomPopper";
import SearchAutocompleteCity from "components/SearchAutocompleteCity";
import SearchRadius from "components/SearchRadius";
import SearchCTA from "components/SearchCTA";
import Contact from "components/Contact";
import { Templates } from "types/templates.enum";
import NewsLetter from "components/NewsLetter";

const menuItemActiveStyle = {
  fontWeight: "bold"
};

const menuItemStyle = {
  display: "flex",
  alignItems: "center",
  zIndex: 1,
  whiteSpace: "nowrap",
  textTransform: "uppercase",
  fontSize: { xs: "1.14rem", lg: "1.286rem" },
  lineHeight: "1.571rem",
  fontWeight: 600,
  color: "primary.main",
  position: "relative",
  cursor: "pointer",
  my: { xs: 2, lg: 0 },
  "&:hover": menuItemActiveStyle
};

const expandIconStyle = {
  marginLeft: 8,
  fontSize: "1.14rem",
  display: "flex",
  alignItems: "center",
  fontWeight: 600
};

const useStyles = makeStyles(theme => ({
  barsIcon: {
    fontWeight: 400,
    marginRight: theme.spacing(1)
  }
}));

const Navigation = ({ scrollableElement, bo }) => {
  const site = useContext(SiteContext);
  const { currentPageVersion } = useContext(PageVersionContext);
  const { template } = currentPageVersion || {};
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const dividerMobile = useMediaQuery(theme.breakpoints.down("md"));

  const alwaysDisplayAdvancedSearch = location.pathname === "/residence";

  const [menuMobileActive, setMenuMobileActive] = useState(false);
  const [displayMobileMenu, setDisplayMobileMenu] = useState(false);
  const [expandedMenuItem, setExpandedMenuItem] = useState({});
  const [displayAdvancedSearch, setDisplayAdvancedSearch] = useState(
    !isMobile && (template === Templates.HOME || alwaysDisplayAdvancedSearch)
  );
  const [openLocation, setOpenLocation] = useState(false);
  const [openRadius, setOpenRadius] = useState(false);
  const [hasScroll, setHasScroll] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openNewsLetter, setOpenNewsLetter] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles({ displayMobileMenu });

  const { settings: siteSettings } = site || {};
  const { logoHeader = null, newsletterDisplayModalDelay = 10 } = siteSettings || {};

  const queryParams = getQueryParams(location.search);

  const form = getEstablishmentSearchParamsFromQueries(queryParams);

  const isSubscribedToNewsletter =
    (typeof localStorage !== "undefined" && localStorage.getItem("isSubscribedToNewsletter")) || "false";

  const handleChangeForm = newForm => {
    history.push({
      pathname: location.pathname,
      search: `?${createQueryParams({
        ...queryParams,
        ...Object.entries(newForm).reduce((acc, [key, value]) => {
          if (typeof value === "boolean") return { ...acc, [key]: value ? "1" : "0" };
          return { ...acc, [key]: value };
        }, {})
      })}`
    });
  };

  useEffect(() => {
    if (!isMobile && menuMobileActive) setMenuMobileActive(false);
  }, [isMobile, menuMobileActive]);

  useEffect(() => {
    if (!bo && isSubscribedToNewsletter === "false") {
      setTimeout(() => {
        setOpenNewsLetter(true);
      }, parseInt(newsletterDisplayModalDelay) * 1000);
    }
  }, [isSubscribedToNewsletter, newsletterDisplayModalDelay, bo]);

  const handleToggleAdvancedSearch = useCallback(() => {
    if (scrollableElement) {
      if (
        ((scrollableElement.scrollTop === 0 && template === Templates.HOME) || alwaysDisplayAdvancedSearch) &&
        !isMobile
      ) {
        setDisplayAdvancedSearch(true);
      }
      if (
        ((scrollableElement.scrollTop !== 0 || template !== Templates.HOME) && !alwaysDisplayAdvancedSearch) ||
        isMobile
      ) {
        setDisplayAdvancedSearch(false);
        setOpenLocation(false);
        setOpenRadius(false);
      }
    }
  }, [alwaysDisplayAdvancedSearch, template, isMobile, scrollableElement]);

  const handleScroll = useCallback(() => {
    if (scrollableElement && scrollableElement.scrollTop > 50) {
      setHasScroll(true);
    } else {
      setHasScroll(false);
    }
  }, [scrollableElement]);

  useEffect(() => {
    if (scrollableElement && !alwaysDisplayAdvancedSearch) {
      scrollableElement.addEventListener("scroll", handleToggleAdvancedSearch);
      scrollableElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleToggleAdvancedSearch);
        scrollableElement.addEventListener("scroll", handleScroll);
      }
    };
  }, [handleToggleAdvancedSearch, handleScroll, alwaysDisplayAdvancedSearch, scrollableElement]);

  useEffect(() => {
    if (scrollableElement) {
      scrollableElement.scrollTo(0, 0);
      handleToggleAdvancedSearch();
    }
  }, [handleToggleAdvancedSearch, location.pathname, scrollableElement]);

  const isOpen = menuItem => menuItem.title === expandedMenuItem.title;

  const { data: menu } = useQuery("menu", () => SectionService.getMenu(site.id));

  const menuContents = (menu && generateTemplatePropsFromContents(menu.contents)) || {};
  let menuItems = menuContents.menus || [];

  if (!Array.isArray(menuItems)) {
    menuItems = [menuItems];
  }

  const isActive = menuItem => {
    const { link } = menuItem || {};
    const { page } = link || {};
    const { path } = page || {};
    if (currentPageVersion && path) {
      if (path === "/") {
        return currentPageVersion.path === path;
      }
      if (currentPageVersion.fullPath) {
        return currentPageVersion.fullPath.startsWith(path);
      }
    }
    return false;
  };

  const handleSubMenuClick = menuItem => {
    setExpandedMenuItem(isOpen(menuItem) ? {} : menuItem);
  };

  const closeAllSubMenus = () => {
    setExpandedMenuItem({});
  };

  const handleMenuClick = () => {
    setMenuMobileActive(false);
    setDisplayMobileMenu(false);
    closeAllSubMenus();
  };

  const closeSubMenu = item => () => {
    if (isOpen(item)) {
      setExpandedMenuItem({});
    }
  };

  const handleClearCity = () => {
    handleChangeForm({ ...form, city: "", lat: "", lng: "" });
  };

  const renderContactButton = () => (
    <Button
      sx={{ height: hasScroll ? 44 : 60, borderRadius: 30, mr: { lg: 3.5 } }}
      variant="outlined"
      color="secondary"
      aria-label="Nous contacter"
      onClick={() => setOpenModal(true)}
    >
      <Box sx={{ display: { lg: "none" } }}>Nous contacter</Box>
      <Box sx={{ display: { xs: "none", lg: "block" } }}>
        <Icon icon="envelope" />
      </Box>
    </Button>
  );

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleCloseNewsLetter = () => {
    setOpenNewsLetter(false);
  };

  const renderMenuGroup = menuItem => {
    const { id, title: itemTitle } = menuItem;
    let { subMenus } = menuItem;

    if (!subMenus || (Array.isArray(subMenus) && !subMenus.length)) {
      return null;
    }

    if (!Array.isArray(subMenus)) {
      subMenus = [subMenus];
    }

    const onKeyPress = (e, menuItem) => {
      if (e.target.tagName === "DIV" && (e.which === 13 || e.which === 32)) {
        e.preventDefault();
        handleSubMenuClick(menuItem);
      }
    };

    return (
      <Box
        sx={{
          ...menuItemStyle,
          display: { xs: "inline-block", lg: "flex" },
          position: "relative",
          cursor: "pointer",
          flexDirection: { lg: "row" }
        }}
        onClick={() => handleSubMenuClick(menuItem)}
        tabIndex="0"
        role="button"
        aria-pressed={isOpen(menuItem)}
        onKeyDown={e => onKeyPress(e, menuItem)}
      >
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "&:focus": {
                outline: "none"
              }
            }}
          >
            {itemTitle}
            {isOpen(menuItem) ? (
              <Icon icon="caret-down" color={theme.palette.secondary.main} title="Fermer" style={expandIconStyle} />
            ) : (
              <Icon icon="caret-up" color={theme.palette.secondary.main} title="Ouvrir" style={expandIconStyle} />
            )}
          </Box>
          {isOpen(menuItem) && (
            <ClickAwayListener key={id} onClickAway={closeSubMenu(menuItem)} touchEvent={false}>
              <Box
                sx={{
                  [theme.breakpoints.up("lg")]: {
                    mt: 1,
                    position: "absolute",
                    top: 22,
                    left: 0,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px 10px",
                    border: `1px solid ${theme.palette.secondary.main}`,
                    "& a": {
                      color: theme.palette.colors.bleu_fonce
                    }
                  },
                  [theme.breakpoints.down("sm")]: {
                    "& a:last-child": {
                      pb: 0
                    }
                  }
                }}
              >
                {subMenus.map(subMenuItem => (
                  <Link
                    {...subMenuItem.link}
                    style={{ display: "block" }}
                    key={subMenuItem.id}
                    onClick={handleMenuClick}
                    sx={{
                      ...menuItemStyle,
                      px: { xs: 0.5, lg: 2 },
                      py: { xs: 0.5, lg: 1.25 },
                      marginLeft: 0,
                      textAlign: { xs: "center", lg: "left" },
                      textTransform: "none",
                      fontSize: { xs: "1rem", lg: "1.14rem" }
                    }}
                    id={`submenu-item-${subMenuItem.id}`}
                  >
                    {subMenuItem.title}
                  </Link>
                ))}
              </Box>
            </ClickAwayListener>
          )}
        </>
      </Box>
    );
  };

  const renderMenuItems = type => {
    return menuItems.map(menuItem => (
      <Fragment key={menuItem.id}>
        <>
          {!menuItem.subMenus && menuItem.link ? (
            <Link
              {...menuItem.link}
              variant="subtitle2"
              onClick={() => setMenuMobileActive(false)}
              id={`${type}-${menuItem.id}`}
              sx={{
                ...menuItemStyle,
                ...(isActive(menuItem) ? menuItemActiveStyle : {})
              }}
            >
              {menuItem.title}
            </Link>
          ) : (
            renderMenuGroup(menuItem)
          )}
        </>
        <Divider
          sx={{
            display: {
              xs: "none",
              lg: menuItems[menuItems.length - 1].id !== menuItem.id ? "block" : "none"
            },
            mx: 1.875,
            my: 0
          }}
          orientation="vertical"
          flexItem
        />
      </Fragment>
    ));
  };

  const renderPhone = () => (
    <Grid container>
      <Grid
        item
        xs="auto"
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: { xs: "1.82rem", lg: "2.357rem" },
          pr: 1.5
        }}
      >
        <Icon icon="phone-volume" type="fas" color={theme.palette.colors.cyan} />
      </Grid>
      <Grid item xs="auto" sx={{ maxWidth: "100%", fontSize: "2.1rem" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <MuiLink
            href="tel:0805620040"
            sx={{
              display: { lg: "none" },
              mb: { xs: 0, lg: 1 },
              fontSize: "1.5rem",
              lineHeight: "1.8rem"
            }}
          >
            0805 620 040
          </MuiLink>
          <Tooltip title="Du lundi au vendredi de 9h00 à 17h30">
            <Typography
              variant="telephone"
              sx={{
                display: { xs: "none", lg: "block" },
                color: "colors.noir",
                mb: { xs: 0, lg: 1 }
              }}
            >
              0805 620 040
            </Typography>
          </Tooltip>
        </Box>
        <Typography
          sx={{
            display: "flex",
            flexDirection: "column",
            fontSize: { xs: "0.714rem", md: "0.857rem" },
            lineHeight: "1.071rem",
            textAlign: "left",
            fontWeight: 500,
            color: "colors.gris_2"
          }}
        >
          <Box component="span">NUMÉRO VERT (GRATUIT)</Box>
          <Box component="span" sx={{ display: { lg: "none" } }}>
            Du lundi au vendredi de 9h00 à 17h30
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );

  const handleCloseLocation = React.useCallback(() => {
    setOpenLocation(false);
  }, []);

  const handleCloseRadius = React.useCallback(() => {
    setOpenRadius(false);
  }, []);

  return (
    <header>
      <Box
        sx={{
          position: "relative",
          boxShadow: "0px 2px 4px 2px rgba(0, 0, 0, 0.15)",
          zIndex: 51,
          bgcolor: "colors.blanc",
          lg: {
            m: 0,
            backgroundColor: "colors.gris_bg"
          }
        }}
      >
        <Container disableGutters>
          <Box
            sx={{
              display: "flex",
              px: { xs: 2, md: 4 },
              pt: hasScroll ? 0 : 2,
              pb: { xs: 2, md: displayAdvancedSearch ? 6.5 : hasScroll ? 0 : 3.5 }
            }}
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={6} sm="auto">
                <MuiLink component={HashLink} to="/" underline="none" aria-label="Accueil">
                  <Grid container item xs="auto" alignItems="center">
                    <Box
                      sx={{
                        display: logoHeader ? "block" : "none",
                        height: 75,
                        mr: { xs: 0, lg: 2.5 },
                        "& > * > img": {
                          height: "100%",
                          width: "auto"
                        }
                      }}
                    >
                      {logoHeader}
                    </Box>
                    <Box
                      sx={{
                        display: { xs: "none", xl: "flex" },
                        marginTop: "27px",
                        flexDirection: "column"
                      }}
                    >
                      <Typography variant="slogan">Vous accompagner,</Typography>
                      <Typography variant="slogan">aujourd'hui & demain</Typography>
                    </Box>
                  </Grid>
                </MuiLink>
              </Grid>
              <Grid
                item
                xs="auto"
                sx={{
                  display: { xs: "none", lg: "block" },
                  position: "relative"
                }}
              >
                <nav>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: 1.6,
                      mb: 1.8
                    }}
                  >
                    {renderMenuItems("desktop")}
                  </Box>
                </nav>
                <Box
                  sx={{
                    "@keyframes buttonOpacityEffect": {
                      "0%": {
                        opacity: 0
                      },
                      "99%": {
                        opacity: 0
                      },
                      "100%": {
                        opacity: 1
                      }
                    },
                    display: "flex",
                    justifyContent: "center",
                    mt: hasScroll ? 0 : 3.6,
                    mb: 1.8,
                    position: displayAdvancedSearch ? "absolute" : "relative",
                    opacity: displayAdvancedSearch ? 0 : 1,
                    animation: displayAdvancedSearch ? "none" : "buttonOpacityEffect 300ms"
                  }}
                >
                  <Grid item xs="auto">
                    <Button
                      variant="cta"
                      size={hasScroll ? "medium" : "large"}
                      onClick={e => {
                        e.stopPropagation();
                        closeAllSubMenus();
                        setDisplayAdvancedSearch(true);
                      }}
                      endIcon={
                        <SearchIcon
                          sx={{
                            fontSize: "2rem",
                            bgcolor: "colors.blanc",
                            color: "colors.recherche",
                            border: "1px solid",
                            borderColor: "colors.blanc",
                            borderRadius: 20,
                            ml: 2.5
                          }}
                        />
                      }
                    >
                      Trouver une résidence
                    </Button>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Grid container>
                  <Grid
                    item
                    xs="auto"
                    sx={{
                      alignItems: "center",
                      display: { xs: "none", sm: "flex", lg: "none", xl: "flex" }
                    }}
                  >
                    {renderPhone()}
                  </Grid>
                  <Grid
                    item
                    xs="auto"
                    sx={{
                      display: { xs: "none", lg: "flex" },
                      mt: { xs: 4, md: 0 },
                      ml: { md: 3.8 }
                    }}
                  >
                    {renderContactButton()}
                    <Button
                      color="secondary"
                      variant="contained"
                      aria-label="Menu utilisateur"
                      sx={{
                        display: "none", // TODO : v02
                        height: hasScroll ? 44 : 60,
                        width: 80,
                        color: "colors.bleu_fonce",
                        p: 2
                      }}
                    >
                      <Box sx={{ fontWeight: 400, mr: 1 }}>
                        <Icon icon="bars" className={classes.barsIcon} />
                      </Box>
                      <Icon icon="user" type="fas" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs="auto" sx={{ display: { xs: "flex", lg: "none" } }}>
                <Box
                  sx={{
                    color: theme.palette.primary.main,
                    fontSize: "1.4rem",
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    "& > *": {
                      cursor: "pointer",
                      "&:not(:last-child)": {
                        marginRight: theme.spacing(2)
                      }
                    }
                  }}
                >
                  {!displayAdvancedSearch && (
                    <SearchIcon
                      onClick={e => {
                        e.stopPropagation();
                        setDisplayAdvancedSearch(true);
                      }}
                      sx={{
                        fontSize: "2rem",
                        position: "absolute",
                        left: "-40px"
                      }}
                    />
                  )}
                  <Icon
                    icon="user"
                    type="fas"
                    style={{
                      display: "none" // TODO : v02
                    }}
                  />
                  <i className="fas fa-bars" onClick={() => setMenuMobileActive(true)} />
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ display: { xs: "block", lg: "none" } }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "fixed",
                  overflow: "hidden",
                  flexDirection: "column",
                  zIndex: 51,
                  backgroundColor: "#F5F5F5",
                  bottom: 0,
                  top: 0,
                  right: 0,
                  left: menuMobileActive ? 0 : "100%",
                  textAlign: "center",
                  padding: theme.spacing(1.25, 1),
                  overflowY: "auto",
                  transition: "all .5s ease-in-out",
                  boxShadow: "0px 0px 5px rgba(0,0,0,.2)"
                }}
              >
                <>
                  <Box
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: "1.4rem",
                      "& > *": {
                        cursor: "pointer"
                      }
                    }}
                  >
                    <i className="fas fa-times" onClick={() => setMenuMobileActive(false)} />
                  </Box>
                  <nav>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column"
                      }}
                    >
                      {renderMenuItems("mobile")}
                    </Box>
                  </nav>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: 1.5,
                      mb: 1.5
                    }}
                  >
                    {renderPhone()}
                  </Box>
                  {renderContactButton()}
                </>
              </Box>
            </Box>
          </Box>
        </Container>
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            bottom: { md: displayAdvancedSearch ? -48 : 0 },
            top: { xs: 130, md: "auto" },
            transform: `scale(${displayAdvancedSearch ? "1" : "0"})`,
            transition: "all ease-in 300ms"
          }}
        >
          <ClickAwayListener onClickAway={handleToggleAdvancedSearch}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                bgcolor: {
                  xs: "colors.blanc",
                  md: "colors.gris_bg",
                  lg: "colors.blanc"
                },
                boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.31)",
                width: "auto",
                borderRadius: "50px",
                padding: { xs: "15px 30px", md: "20px 48px 15px 28px" },
                zIndex: "10"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: { xs: "column", md: "row" }
                }}
              >
                <Box sx={{ position: "relative" }}>
                  <SearchCTA
                    label="Localisation"
                    placeholder="Où recherchez-vous ?"
                    value={form?.city}
                    openModal={event => {
                      setOpenLocation(true);
                      setOpenRadius(false);
                      setAnchorEl(event.currentTarget);
                    }}
                    onClear={() => handleClearCity()}
                  />
                  <CustomPopper
                    onClose={handleCloseLocation}
                    open={openLocation}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    sx={{
                      zIndex: 100,
                      pt: { md: "16px" },
                      width: { xs: 300, md: 450 }
                    }}
                  >
                    <SearchAutocompleteCity form={form} setForm={handleChangeForm} closePopper={handleCloseLocation} />
                  </CustomPopper>
                </Box>
                <Divider orientation={dividerMobile ? "horizontal" : "vertical"} flexItem />
                <Box sx={{ position: "relative" }}>
                  <SearchCTA
                    label="Rayon de recherche"
                    placeholder="Jusqu’où va votre recherche ?"
                    value={form?.radius ? `${form?.radius} km` : ""}
                    openModal={event => {
                      setOpenRadius(true);
                      setOpenLocation(false);
                      setAnchorEl(event.currentTarget);
                    }}
                  />
                  <CustomPopper
                    onClose={handleCloseRadius}
                    open={openRadius}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    sx={{ zIndex: 100, pt: { md: "16px" }, width: 250 }}
                  >
                    <SearchRadius form={form} setForm={handleChangeForm} closePopper={handleCloseRadius} nav />
                  </CustomPopper>
                </Box>
                <Tooltip title={!form.city || !form.radius ? "Veuillez renseigner une localisation" : ""} role="button">
                  <Box>
                    <IconButton disabled={!form.city || !form.radius} disableRipple>
                      <MuiLink
                        component={HashLink}
                        to={`/residence${location.search}`}
                        color="inherit"
                        aria-label="Rechercher"
                      >
                        <SearchIcon
                          sx={{
                            fontSize: "3rem",
                            color: "colors.blanc",
                            bgcolor: "colors.recherche",
                            opacity: !form.city || !form.radius ? 0.6 : 1,
                            px: "19px",
                            ml: "15px",
                            borderRadius: "25px",
                            width: "60px"
                          }}
                        />
                      </MuiLink>
                    </IconButton>
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </ClickAwayListener>
        </Box>
      </Box>
      {openModal && <Contact open={openModal} onClose={handleClose} />}
      {openNewsLetter && (
        <Dialog open={openNewsLetter} onClose={handleCloseNewsLetter} maxWidth="xl" fullWidth>
          <NewsLetter onClose={handleCloseNewsLetter} />
        </Dialog>
      )}
    </header>
  );
};

Navigation.propTypes = {
  scrollableElement: PropTypes.shape({})
};

Navigation.defaultProps = {
  scrollableElement: undefined
};

export default Navigation;
