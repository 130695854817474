import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import makeOverridableStyles from "cms/utils/makeOverridableStyles";
import sanitizeHtml from "sanitize-html";
import { Box } from "@mui/system";

export const componentClassName = "Da-Text";

const useStyles = makeOverridableStyles("Text", theme => ({
  text: {
    color: theme.palette.primary.dark,
    "& h2": {
      fontWeight: 600,
      fontSize: "2rem",
      lineHeight: "2.43rem",
      letterSpacing: "0.6px",
      marginBottom: "1.71rem",
      marginTop: 0
    },
    "& h3": {
      fontSize: "1.57rem",
      fontWeight: 600,
      margin: theme.spacing(1, 0),
      lineHeight: "2.43rem",
      letterSpacing: "0px",
      marginBottom: "1.71rem",
      marginTop: 0
    },
    "& h4": {
      fontSize: "1.15rem",
      fontWeight: 600,
      margin: theme.spacing(1, 0),
      lineHeight: "1.71rem",
      letterSpacing: "0px",
      marginBottom: "1.71rem",
      marginTop: 0
    },
    "& h5": {
      fontSize: "1.42rem",
      lineHeight: "1.85rem",
      fontWeight: 600
    },
    "& a": {
      color: theme.palette.primary.main,
      fontWeight: 600,
      textDecoration: "none"
    },
    "& p": {
      wordBreak: "break-word"
    },
    "& pre": {
      whiteSpace: "pre-wrap"
    },
    "&  ul": {
      listStyle: "none",
      paddingLeft: "16px",
      "& li": {
        textIndent: "-16px",
        "&:before": {
          content: `"\\f111"`,
          fontWeight: "600",
          fontSize: "8px",
          verticalAlign: "middle",
          textIndent: "0",
          width: "16px",
          display: "inline-block",
          fontFamily: `"Font Awesome 5 Pro"`,
          color: theme.palette.secondary.main
        },
        "& ul": {
          marginLeft: "24px",
          "& li": {
            textIndent: "-22px",
            "&:before": {
              content: `"\\f00c"`,
              fontWeight: "400",
              fontSize: "14px",
              width: "22px",
              color: theme.palette.secondary.main
            }
          }
        }
      }
    }
  }
}));

const sanitizerOptions = {
  ...sanitizeHtml.defaults,
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(["h2", "img", "u", "s", "sub", "address", "sup"]),
  allowedAttributes: {
    ...sanitizeHtml.defaults.allowedAttributes,
    "*": ["id", "class", "style", "title"],
    a: ["href", "name", "target"],
    img: ["src", "target", "alt"]
  },
  allowedSchemesByTag: {
    ...sanitizeHtml.defaults.allowedSchemesByTag,
    img: ["https", "data"]
  }
};

const Text = props => {
  const { children, className, ...others } = props;

  const childrenProps = {};
  if (typeof children === "string") {
    childrenProps.dangerouslySetInnerHTML = { __html: sanitizeHtml(decodeURIComponent(children), sanitizerOptions) };
  } else {
    childrenProps.children = children;
  }

  const classes = useStyles(props);

  return <Box className={classnames(className, componentClassName, classes.text)} {...childrenProps} {...others} />;
};

Text.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

Text.defaultProps = {
  children: null,
  className: ""
};

export default Text;
