import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";

export const getTemplateByKey = key =>
  (CmsHooks.getHook(HOOKS.TEMPLATES) || []).find(template => template.key === key) || { Component: () => null };

export const getTemplateComponentByKey = key => getTemplateByKey(key).Component;

export const enhancePageVersionContents = pageVersion => {
  if (!pageVersion) {
    return null;
  }
  const { template: templateKey, contents } = pageVersion;
  const template = getTemplateByKey(templateKey);
  const { initialContents = [] } = template;
  const contentsKeys = [...initialContents, ...contents].reduce(
    (keys, content) => (keys.includes(content.key) ? keys : [...keys, content.key]),
    []
  );
  return {
    ...pageVersion,
    contents: contentsKeys.map(key => [...contents, ...initialContents].find(c => c.key === key))
  };
};
