import React from "react";
import PropTypes from "prop-types";
import videosOrigins from "cms/enums/videosOrigins";

const validURL = str => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

const Video = props => {
  const { src, source, ...others } = props;

  const createSrcByOrigin = () => {
    if (!src) return src;

    switch (source) {
      case videosOrigins.YOUTUBE:
        return src.replace("watch?v=", "embed/").split("&")[0];
      case videosOrigins.DAILYMOTION:
        return src.replace("video", "embed/video").split("?")[0];
      case videosOrigins.VIMEO:
        return src.replace("vimeo.com", "player.vimeo.com/video");
      default:
        break;
    }
    return src;
  };

  const iframeSrc = validURL(createSrcByOrigin()) ? createSrcByOrigin() : "about:blank";

  return (
    <iframe
      height="400"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="video"
      {...others}
      src={iframeSrc}
      style={{ width: "100%", border: "0px" }}
    />
  );
};

Video.propTypes = {
  src: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired
};

export default Video;
