import React from "react";
import PropTypes from "prop-types";
import makeOverridableStyles from "cms/utils/makeOverridableStyles";
import Grid from "@mui/material/Grid";
import Icon from "cms/editableComponents/Icon";

const useStyles = makeOverridableStyles("InfoBlock", theme => ({
  root: {},
  icon: {
    padding: theme.spacing(2),
    background: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    fontSize: "1.7rem",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      display: "none"
    }
  },
  info: {
    background: "#fff",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4)
    }
  }
}));

const InfoBlock = props => {
  const { icon, text } = props;

  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      {icon && (
        <Grid item className={classes.icon}>
          <Icon {...icon} title="Information" />
        </Grid>
      )}
      <Grid item xs className={classes.info}>
        {text}
      </Grid>
    </Grid>
  );
};

InfoBlock.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  text: PropTypes.node
};

InfoBlock.defaultProps = {
  icon: null,
  text: null
};

export default InfoBlock;
