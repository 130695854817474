import React from "react";
import PropTypes from "prop-types";
import makeOverridableStyles from "cms/utils/makeOverridableStyles";
import Link from "cms/editableComponents/Link";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";

const useImageStyles = makeOverridableStyles("ImageBlock", {
  imageBlock: props => ({
    height: "100%",
    width: props.width ? `${props.width}%` : "100%"
  }),
  image: {
    height: "100%",
    width: "100%"
  }
});

const ImageBlock = props => {
  const { width, mobileWidth, justifyContent, link, image } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const classes = useImageStyles({ width: isMobile ? mobileWidth : width });

  return (
    <Box display="flex" justifyContent={justifyContent}>
      <Box className={classes.imageBlock}>
        {link && (link.page || link.url) ? <Link {...link}>{image}</Link> : image}
      </Box>
    </Box>
  );
};

ImageBlock.propTypes = {
  width: PropTypes.string,
  mobileWidth: PropTypes.string,
  justifyContent: PropTypes.oneOf(["flex-start", "center", "flex-end"]).isRequired,
  link: PropTypes.shape(),
  image: PropTypes.shape().isRequired
};

ImageBlock.defaultProps = {
  width: null,
  mobileWidth: null,
  link: null
};

export default ImageBlock;
