import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ImageGallery from "react-image-gallery";

const ImagesGallery = props => {
  const {
    images,
    height,
    infinite,
    showNav,
    showThumbnails,
    thumbnailPosition,
    showFullscreenButton,
    showPlayButton,
    isRTL,
    showBullets,
    showIndex,
    autoPlay,
    slideInterval
  } = props;

  const currentImages = Array.isArray(images.image) ? images.image : [images.image];
  const items = currentImages.map(currentImage => {
    const file = currentImage?.file || {};
    return {
      original: file.url,
      originalAlt: file.alt,
      originalTitle: file.title,
      originalHeight: parseInt(height, 10),
      thumbnail: file.url,
      thumbnailAlt: file.alt
    };
  });

  useEffect(() => {
    if (window) {
      // eslint-disable-next-line global-require
      require("react-image-gallery/styles/css/image-gallery.css");
    }
  }, []);

  return (
    <ImageGallery
      items={items}
      lazyLoad={true}
      infinite={infinite}
      showNav={showNav}
      showThumbnails={showThumbnails}
      thumbnailPosition={thumbnailPosition}
      showFullscreenButton={showFullscreenButton}
      showPlayButton={showPlayButton}
      isRTL={isRTL}
      showBullets={showBullets}
      showIndex={showIndex}
      autoPlay={autoPlay}
      slideInterval={parseInt(slideInterval, 10)}
    />
  );
};

ImagesGallery.propTypes = {
  images: PropTypes.oneOfType(PropTypes.arrayOf(PropTypes.shape()), PropTypes.shape()),
  height: PropTypes.string,
  infinite: PropTypes.bool,
  showNav: PropTypes.bool,
  showThumbnails: PropTypes.bool,
  thumbnailPosition: PropTypes.string,
  showFullscreenButton: PropTypes.bool,
  showPlayButton: PropTypes.bool,
  isRTL: PropTypes.bool,
  showBullets: PropTypes.bool,
  showIndex: PropTypes.bool,
  autoPlay: PropTypes.bool,
  slideInterval: PropTypes.string
};

ImagesGallery.defaultProps = {
  images: null,
  height: "200",
  infinite: true,
  showNav: true,
  showThumbnails: false,
  thumbnailPosition: "bottom",
  showFullscreenButton: false,
  showPlayButton: false,
  isRTL: false,
  showBullets: true,
  showIndex: false,
  autoPlay: true,
  slideInterval: "3000"
};

export default ImagesGallery;
